import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { Typography } from "@mui/material";

import { InputLabel } from "../InputLabel";
import { StyledFormControl, StyledHubIcon } from "./styles";
import HubHookFormSelectGroups from "./HubHookFormSelectGroups";

// for the case where option?.value is false
const getOptionValue = (opt) => (opt?.id !== undefined ? opt?.id : opt);

const DefaultIcon = ({ ...rest }) => (
  <StyledHubIcon
    icon="ChevronDown"
    sx={{
      marginRight: "10px",
      marginTop: "3px",
      cursor: "pointer",
    }}
    {...rest}
  />
);

export const HubHookFormSelect = ({
  name = "",
  required = false,
  rules = {},
  disabled = false,
  onChange = () => {},
  isLoading = false,
  placeholder = "",
  label = "",
  helperText = "",
  options = [],
  disableHelperText = true,
  smallLabel = true,
  RenderComponent = null,
  selectDefault = false,
  infoProps = {},
  defaultValue = "",
  formControlProps = {},
  useDefault = true,
  hidden = false,
  fullWidth = true,
  allowNull = false,
  useSetValue = false,
  groupByKey = null,
  defaultOpenGroup = null,
  multiple = false, // Allows multiple selections
  ...rest
}) => {
  const id = `vp-hook-select-${name}`;
  const defaultSelectValue = useMemo(
    () =>
      multiple
        ? defaultValue || [] // Ensure it's an array for multiple selection
        : defaultValue !== "" && defaultValue !== undefined
        ? defaultValue
        : typeof options[0] === "string"
        ? options[0]
        : options[0]?.id,
    [options, defaultValue, multiple]
  );

  const vpInputProps = isLoading
    ? {
        startAdornment: (
          <InputAdornment position="start">
            <CircularProgress size={24} />
          </InputAdornment>
        ),
      }
    : {};

  const fallbackDefaultValue = selectDefault
    ? multiple
      ? options.map(getOptionValue) || []
      : getOptionValue(options?.[0]) || ""
    : multiple
    ? []
    : "";

  const defaultInputValue = !useDefault
    ? multiple
      ? []
      : ""
    : defaultSelectValue !== undefined && defaultSelectValue !== null
    ? defaultSelectValue
    : fallbackDefaultValue;

  const { setValue, register } = useFormContext();
  const {
    field: {
      onChange: onControllerChange,
      onBlur,
      value,
      name: inputName,
      ref,
    },
    fieldState: { error },
  } = useController({
    name,
    rules: { required, ...rules },
    defaultValue: useDefault ? defaultInputValue : multiple ? [] : "",
    shouldUnregister: true,
  });

  const errorMessage = error?.message;
  const defaultHelperText = disableHelperText ? "" : <span>&nbsp;</span>;
  const helperTextMessage = errorMessage || helperText || defaultHelperText;

  const [open, setOpen] = useState(false);

  const onSelectChange = useCallback(
    (e, overrideValue) => {
      let newValue = overrideValue ?? e.target.value ?? "";

      if (multiple) {
        // Ensure the selected values are stored as an array
        newValue = Array.isArray(newValue) ? newValue : [newValue];
      }

      onControllerChange({ target: { value: newValue } });
      onChange({ target: { value: newValue } });

      if (useSetValue) {
        setValue(name, newValue);
      }

      setOpen(false);
    },
    [multiple, useSetValue, onControllerChange, onChange, setValue, setOpen]
  );

  return (
    <StyledFormControl
      hidden={hidden}
      fullWidth={fullWidth}
      key={options.join("-")}
      variant="outlined"
      {...formControlProps}
    >
      <InputLabel
        label={label}
        required={required}
        id={id}
        smallLabel={smallLabel}
      />
      <Select
        id={id}
        onChange={onSelectChange}
        onBlur={onBlur}
        value={value ?? (multiple ? [] : "")} // Ensure null is treated as "" or [] for multiple
        inputRef={ref}
        name={inputName}
        disabled={disabled}
        size="small"
        error={!!error}
        placeholder={placeholder}
        IconComponent={DefaultIcon}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        multiple={multiple} // Enable multiple selection
        MenuProps={{
          sx: {
            "& .MuiMenu-paper": {
              maxHeight: "400px",
            },
          },
        }}
        {...(useDefault ? { defaultValue: defaultSelectValue } : {})}
        {...vpInputProps}
        {...rest}
      >
        {allowNull && (
          <MenuItem key="null-option" value="">
            <Typography sx={{ fontSize: "1em", color: "hubColors.white" }}>
              None
            </Typography>
          </MenuItem>
        )}
        {options.map((opt) => (
          <MenuItem
            key={`hidden-${getOptionValue(opt)}`}
            value={getOptionValue(opt)}
            sx={{ display: "none" }}
          >
            <Typography sx={{ fontSize: "1em" }}>{opt.label || opt}</Typography>
          </MenuItem>
        ))}
        <HubHookFormSelectGroups
          {...{
            options,
            getOptionValue,
            RenderComponent,
            groupByKey,
            onSelectChange,
            defaultOpenGroup,
          }}
        />
      </Select>
      {helperTextMessage && (
        <FormHelperText error={!!error} id={`${id}-helper-text`}>
          {helperTextMessage}
        </FormHelperText>
      )}
      <input
        {...register(name)}
        type="input"
        style={{ display: "none" }}
        {...(useDefault ? { defaultValue: defaultSelectValue } : {})}
      />
    </StyledFormControl>
  );
};

HubHookFormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  defaultSelectValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
  ]),
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  disableHelperText: PropTypes.bool,
  smallLabel: PropTypes.bool,
  RenderComponent: PropTypes.node,
  selectDefault: PropTypes.bool,
  infoProps: PropTypes.object,
  multiple: PropTypes.bool, // Added prop type validation
};

export default HubHookFormSelect;
