import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledRadioCard = styled(Stack, {
  shouldForwardProp: (prop) => !["selected"].includes(prop),
})(({ theme, selected }) => ({
  padding: "25px",
  borderRadius: "10px",
  gap: "15px",
  alignItems: "center",
  width: "100%",
  height: "100%",
  ...(selected
    ? {
        outline: `1.5px solid ${theme.palette.hubColors.mainFocus}`,
      }
    : {
        outline: `1px dashed ${theme.palette.hubColors.grey}`,
      }),
}));
