import React from "react";

export const Barcode = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.125 0C0.501562 0 0 0.573214 0 1.28571V22.7143C0 23.4268 0.501562 24 1.125 24H1.875C2.49844 24 3 23.4268 3 22.7143V1.28571C3 0.573214 2.49844 0 1.875 0H1.125ZM5.25 0C4.8375 0 4.5 0.385714 4.5 0.857143V23.1429C4.5 23.6143 4.8375 24 5.25 24C5.6625 24 6 23.6143 6 23.1429V0.857143C6 0.385714 5.6625 0 5.25 0ZM8.625 0C8.00156 0 7.5 0.573214 7.5 1.28571V22.7143C7.5 23.4268 8.00156 24 8.625 24H9.375C9.99844 24 10.5 23.4268 10.5 22.7143V1.28571C10.5 0.573214 9.99844 0 9.375 0H8.625ZM13.125 0C12.5016 0 12 0.573214 12 1.28571V22.7143C12 23.4268 12.5016 24 13.125 24H13.875C14.4984 24 15 23.4268 15 22.7143V1.28571C15 0.573214 14.4984 0 13.875 0H13.125ZM21 1.28571V22.7143C21 23.4268 21.5016 24 22.125 24H22.875C23.4984 24 24 23.4268 24 22.7143V1.28571C24 0.573214 23.4984 0 22.875 0H22.125C21.5016 0 21 0.573214 21 1.28571ZM18 0.857143V23.1429C18 23.6143 18.3375 24 18.75 24C19.1625 24 19.5 23.6143 19.5 23.1429V0.857143C19.5 0.385714 19.1625 0 18.75 0C18.3375 0 18 0.385714 18 0.857143Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Barcode;
