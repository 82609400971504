import { styled } from "@mui/material/styles";
import { HubCardStack } from "../HubCardStack";
import { Stack } from "@mui/material";

export const BasicCardStack = styled(HubCardStack)(({ theme }) => ({
  padding: "5px",
  borderRadius: 0,
  borderColor: theme.palette.hubColors.grey,
}));

export const StyledCardStack = styled(BasicCardStack)(({ theme }) => ({
  width: "40%",
  flexGrow: 0,
}));

export const StyledCardRow = styled(BasicCardStack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
}));

export const StyledItemRow = styled(Stack, {
  shouldForwardProp: (prop) => !["header", "topBorder"].includes(prop),
})(({ theme, header, topBorder }) => ({
  display: "grid",
  gridTemplateColumns: "10% 50% 20% 20%",
  padding: "3px 10px",
  alignContent: "center",
  borderColor: theme.palette.hubColors.grey,
  ...(topBorder
    ? {
        borderTop: "1px solid",
      }
    : {
        borderBottom: "1px solid",
      }),
  ...(header
    ? {
        backgroundColor: theme.palette.hubColors.greyLight,
      }
    : {}),
}));

export const StyledLabelStack = styled(Stack, {
  shouldForwardProp: (prop) => !["c1"].includes(prop),
})(({ c1 = "100px" }) => ({
  display: "grid",
  gridTemplateColumns: `${c1} 1fr`,
  alignContent: "end",
  gap: "5px",
}));
