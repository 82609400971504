import PackingSlip from "./PackingSlip.png";
import Serialization from "./Serialization.png";
import WorkOrder from "./WorkOrder.png";

const test =  [
  {
    id: 1,
    label: "Packing Slip",
    src: PackingSlip,
    time: " Mar  25  2025 ",
    user: { hueShift: 458, name: "Ken Gonzalez", email: "ken@ipcfoundry.com" },
  },
  {
    id: 2,
    label: "Barcode Serialization",
    src: Serialization,
    time: " Mar  25  2025 ",
    user: { hueShift: 458, name: "Ken Gonzalez", email: "ken@ipcfoundry.com" },
  },
  {
    id: 3,
    label: "Work Order",
    src: WorkOrder,
    time: " Mar  25  2025 ",
    user: { hueShift: 458, name: "Ken Gonzalez", email: "ken@ipcfoundry.com" },
  },
  {
    id: 4,
    label: "Some Test",
    // src: WorkOrder,
    time: " Mar  25  2025 ",
    user: { hueShift: 458, name: "Ken Gonzalez", email: "ken@ipcfoundry.com" },
  },
];


export const DOCS = [test, test, test].flat();