import React from "react";
import { Stack, Typography, Box } from "@mui/material";

import { EmptyWorkOrder } from "../../svgs";

export const Empty = ({
  label = "No Results",
  subtitle = "Could not find work orders for search",
  size = "200px",
}) => {
  return (
    <Stack gap="10px" justifyContent="center" alignItems="center" m="auto">
      <Box component="img" src={EmptyWorkOrder} width={size} height={size} />
      <Stack justifyContent="center" alignItems="center">
        <Typography variant="boldCaption">{label}</Typography>
        <Typography variant="caption">{subtitle}</Typography>
      </Stack>
    </Stack>
  );
};

export default Empty;
