export const BarcodeDimensions = [
  `2.25" x 1.25"`,
  `2" x 1"`,
  `2" x 0.5"`,
  `1.5" x 1"`,
  `2.833" x 1"`,
  `2.25" x 2.25"`,
]?.map((label, id) => ({ id: id + 1, label }));

export const DataObjects = [
  "Work Orders",
  "Purchase Orders",
  "Customers",
  "Employees",
]?.map((label, id) => ({ id: id + 1, label }));

export const BarcodeFormats = [
  { id: "CODE39", label: "CODE 39" },
  { id: "CODE128", label: "CODE 128" },
  { id: "CODE128A", label: "CODE 128A" },
  { id: "CODE128B", label: "CODE 128B" },
  { id: "CODE128C", label: "CODE 128C" },
  { id: "EAN13", label: "EAN-13" },
  { id: "EAN8", label: "EAN-8" },
  { id: "UPC", label: "UPC (Universal Product Code)" },
  { id: "ITF14", label: "ITF-14" },
  { id: "MSI", label: "MSI (Modified Plessey)" },
  { id: "MSI10", label: "MSI + Mod10 Check Digit" },
  { id: "MSI11", label: "MSI + Mod11 Check Digit" },
  { id: "MSI1010", label: "MSI + Mod10 & Mod10" },
  { id: "MSI1110", label: "MSI + Mod11 & Mod10" },
  { id: "pharmacode", label: "Pharmacode" },
  { id: "codabar", label: "Codabar" },
];
