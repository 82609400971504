import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { Box } from "@mui/material";
import isEqual from "lodash";

export const HubHookFormWrapper = ({
  children,
  onSubmit,
  defaultValues,
  sx = {},
  ref = null,
}) => {
  const form = useForm({
    defaultValues,
    shouldUnregister: false,
    ...(defaultValues ? { defaultValues } : {}),
  });

  const { reset } = form;

  useEffect(() => {
    if (defaultValues && !_.isEqual(form.getValues(), defaultValues)) {
      reset(defaultValues);
    }
  }, [defaultValues, reset, form]);

  return (
    <FormProvider {...form}>
      <Box
        component="form"
        sx={{ width: "100%", ...sx }}
        onSubmit={(e) => {
          e.stopPropagation();

          return form.handleSubmit(onSubmit)(e);
        }}
        ref={ref}
      >
        {children}
      </Box>
    </FormProvider>
  );
};

HubHookFormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default HubHookFormWrapper;
