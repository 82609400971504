export const EMPLOYEE_TYPES = [
  {
    id: 1,
    label: "Full Time",
    icon: "User",
  },
  {
    id: 2,
    label: "Part Time",
    icon: "PieChart",
  },
];

export const EMPLOYEE_TYPES_DICT = {
  FULLTIME: 1,
  PARTTIME: 2,
};
