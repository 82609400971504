import React from "react";

export const Fire = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        d="M9.53393 1.28608C9.95179 0.901141 10.6 0.906415 11.0179 1.29136C12.4964 2.65711 13.8839 4.12833 15.1804 5.72083C15.7696 4.96149 16.4393 4.1336 17.1625 3.45863C17.5857 3.06842 18.2393 3.06842 18.6625 3.46391C20.5161 5.20405 22.0857 7.50316 23.1893 9.68625C24.2768 11.8377 25 14.0366 25 15.5869C25 22.3155 19.6536 28 13 28C6.27143 28 1 22.3102 1 15.5817C1 13.5568 1.95357 11.0836 3.43214 8.63689C4.92679 6.15323 7.0375 3.5641 9.53393 1.28608ZM13.0911 22.9377C14.4464 22.9377 15.6464 22.5686 16.7768 21.8304C19.0321 20.2801 19.6375 17.1794 18.2821 14.7432C18.0411 14.2686 17.425 14.237 17.0768 14.6378L15.7268 16.1828C15.3732 16.5836 14.7357 16.573 14.4036 16.1564C13.5196 15.0491 11.9393 13.0716 11.0393 11.9484C10.7018 11.5266 10.0589 11.5213 9.71607 11.9432C7.90536 14.1843 6.99464 15.5975 6.99464 17.1847C7 20.7968 9.71071 22.9377 13.0911 22.9377Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Fire;
