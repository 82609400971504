import React from "react";

import { HubDialogWrapper } from "../HubDialogWrapper";
import CreateStationContent from "./CreateStationContent";

export const CreateStationDialog = ({
  details,
  isUpdating = false,
  onClose = () => {},
}) => {
  return (
    <HubDialogWrapper
      maxWidth="sm"
      name="create-station"
      customOnClose={onClose}
    >
      <CreateStationContent details={details} isUpdating={isUpdating} />
    </HubDialogWrapper>
  );
};

export default CreateStationDialog;
