import { styled } from "@mui/material/styles";
import { TableRow } from "@mui/material";

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => !["main"].includes(prop),
})(({ theme, main }) => ({
  "& .MuiTableCell-root": {
    ...(main
      ? { backgroundColor: theme.palette.hubColors.mainFocusLightest }
      : {}),
    borderRight: `1px solid ${theme.palette.hubColors.greyLight} !important`,
    padding: "0px",
    ">.MuiTypography-root": {
      marginLeft: "13px",
    },
    ".MuiInputBase-root, input": {
      borderRadius: 0,
      ...(main ? { backgroundColor: "transparent" } : {}),
    },
    fieldset: {
      borderWidth: "0px",
    },
    "&[disabled]": {
      backgroundColor: theme.palette.hubColors.greyLightest,
    },
  },
}));
