import React from "react";
import { Stack } from "@mui/material";
import {
  HubHookFormInput,
  HubHookFormSelect,
  HubHookFormTagInput,
  StationHookFormSelect,
} from "../../components";
import { DEFAULT_FIELDS, DISPLAY_FIELDS, FOUNDRIES } from "../../constants";

const CreateDisplayForm = ({ data = {}, disabled = false }) => {
  return (
    <Stack gap="10px">
      <Stack display="grid" gridTemplateColumns="1fr 300px" gap="10px">
        <HubHookFormInput
          name="title"
          label="Title"
          placeholder="ex. Hand Dipping Station"
          defaultValue={data?.title}
          disabled={disabled}
          required
        />
        <HubHookFormSelect
          name="locationId"
          label="Foundry"
          options={FOUNDRIES}
          defaultValue={data?.locationId}
          disabled={disabled}
          required
        />
      </Stack>
      <StationHookFormSelect
        name="stationIds"
        label="Stations"
        multiple={true}
        defaultValue={data?.stationIds}
        disabled={disabled}
        required
      />
      <HubHookFormTagInput
        name="fields"
        label="Fields"
        options={DISPLAY_FIELDS}
        defaultValue={data?.fields || DEFAULT_FIELDS}
        disabled={disabled}
        required
      />
    </Stack>
  );
};

export default CreateDisplayForm;
