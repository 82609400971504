import React from "react";

import { HubDialogWrapper } from "../HubDialogWrapper";
import CreateDisplayContent from "./CreateDisplayContent";

export const CreateDisplayDialog = () => {
  return (
    <HubDialogWrapper maxWidth="sm" name="create-display">
      <CreateDisplayContent />
    </HubDialogWrapper>
  );
};

export default CreateDisplayDialog;
