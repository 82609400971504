import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import { Popper, Stack } from "@mui/material";

export const StyledFormControl = styled(FormControl)(({ hidden }) => ({
  ...(hidden && {
    display: "none",
  }),
}));

export const StyledInput = styled(Input, {
  shouldForwardProp: (props) => props !== "boldValue",
})(({ boldValue }) => ({
  ...(boldValue && {
    fontWeight: 500,
  }),
}));

export const StyledPopper = styled(Popper)(({ theme }) => ({
  top: "5px !important",
  zIndex: 1500,
  backgroundColor: theme.palette.hubColors.white,
  border: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  boxShadow: "0px 5px 15px rgba(0,0,0,0.1) !important",
  borderRadius: "10px",
}));
