import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { setIsOpenDialog } from "../../redux";
import { useGetStationDisplay } from "../../hooks";
import { StationDisplayTable } from "../../tables";
import { ROLES } from "../../constants";
import { useScannerHelper } from "../../helpers";
import { CreateLaborDialog } from "../../dialogs";

import { useDisplaysContext } from "./DisplayContextProvider";
import { StyledGridBox } from "./styles";

const padding = 15;
const isNotN = (value) => value === null || isNaN(value);

const DisplayPageContent = ({ gridRef, _setIsOpenDialog = () => {} }) => {
  const { data, refetch } = useGetStationDisplay();

  const [workOrder, setWorkOrder] = useState();
  const [dimensions, setDimensions] = useState({ height: 150, width: 200 });

  const { nav, defaultNav, role, layout, setLayout } = useDisplaysContext();

  const tables = useMemo(() => data?.tables || [], [data]);

  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, [search?.toString()]);

  useEffect(() => {
    const updateGridSize = () => {
      if (gridRef.current) {
        const parent = gridRef.current;
        const height = (parent?.offsetHeight - padding * 3) / 2;
        const width = parent?.offsetWidth - padding;

        setDimensions({ height, width });
      }
    };

    updateGridSize();

    window.addEventListener("resize", updateGridSize);

    return () => window.removeEventListener("resize", updateGridSize);
  }, []);

  useEffect(() => {
    const newLayout = tables?.map((table, index) => {
      const { x, y, w, h } = table;

      const useDefault = [x, y, w, h].every((i) => isNotN(i));

      const l = useDefault
        ? {
            x: index % 2 ? 2 : 0,
            y: Math.floor(index / 2),
            w: tables?.length > 1 ? 2 : 4,
            h: tables?.length > 1 ? 1 : 4,
          }
        : { x, y, w, h };

      return {
        i: table?.id?.toString(),
        ...l,
      };
    });

    setLayout(newLayout);
  }, [tables]);

  useEffect(() => {
    const allowNav = ROLES.Generic !== role;
    if (workOrder)
      nav && allowNav
        ? navigate(`/works/${workOrder.workOrder}`)
        : handleSetOpen();
  }, [workOrder]);

  const handleSetOpen = () => {
    _setIsOpenDialog("create-labor-ticket", true);
  };

  const onScan = (barcode) =>
    !workOrder && setWorkOrder({ workOrder: barcode });
  useScannerHelper(onScan, [workOrder]);

  const onLayoutChange = (newLayout) => {
    setLayout(newLayout);
  };

  return (
    <>
      <GridLayout
        className="layout"
        layout={layout}
        cols={4}
        maxRows={2}
        rowHeight={dimensions.height}
        width={dimensions.width}
        isDraggable={defaultNav && nav}
        isResizable={defaultNav && nav}
        onLayoutChange={onLayoutChange}
        margin={[padding, padding]}
        containerPadding={[padding, padding]}
      >
        {tables?.map((table) => (
          <StyledGridBox key={table.id} d={dimensions} p={padding}>
            <StationDisplayTable
              table={table}
              fields={data?.fields}
              setWorkOrder={setWorkOrder}
            />
          </StyledGridBox>
        ))}
      </GridLayout>
      <CreateLaborDialog workOrder={workOrder} setWorkOrder={setWorkOrder} />
    </>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  _setIsOpenDialog: setIsOpenDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(DisplayPageContent);
