import React from "react";
import { Stepper, Step, StepLabel, Stack } from "@mui/material";
import PropTypes from "prop-types";
import {
  StepperActive,
  StepperDormant,
  StepperComplete,
} from "./HubStepperDots";
import { HubIcon } from "../HubIcon";

const size = "20px";
const stepperSize = {
  width: size,
  height: size,
};
const iconProps = {
  size,
  icon: "Check",
  sx: { color: "hubColors.mainFocus" },
};

export const CustomerStepperIcon = ({ active, completed }) => {
  if (active) return <StepperActive {...stepperSize} />;
  else if (completed) return <HubIcon {...iconProps} />;
  else return <StepperDormant {...stepperSize} />;
};

export const HubStepper = ({ activeStep, steps, ...rest }) => {
  return (
    <Stack width="100%">
      <Stepper activeStep={activeStep} width="100%" {...rest}>
        {steps?.map((step, key) => (
          <Step key={key}>
            <StepLabel StepIconComponent={CustomerStepperIcon}>
              {step?.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

HubStepper.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.object),
};

export default HubStepper;
