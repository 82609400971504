import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";

import {
  HubCancelSubmitGroup,
  HubDialogContent,
  HubHookFormWrapper,
} from "../../components";
import { usePostCreateMaterial } from "../../hooks";
import { useDialogContext } from "../HubDialogWrapper";

import CreateMaterialForm from "./CreateMaterialForm";

const CreateMaterialContent = () => {
  const {
    mutate: postCreateMaterial,
    isLoading,
    isSuccess,
  } = usePostCreateMaterial();

  const { onClose } = useDialogContext();

  useEffect(() => {
    isSuccess && onClose();
  }, [isSuccess]);

  const onSubmit = (formData) => {
    const type = Number(formData.type);
    const newMaterial = {
      type,
      name: formData.name,
      weight: parseFloat(formData.weight),
    };

    if (type === 2) {
      newMaterial.category = formData?.category;
      newMaterial.noRemelt = !formData?.remelt;
    } else if (type === 1) {
      newMaterial.locationId = Number(formData?.locationId);
    }

    postCreateMaterial(newMaterial);
  };

  return (
    <HubDialogContent largePadding>
      <HubHookFormWrapper onSubmit={onSubmit}>
        <Stack gap="35px" alignItems="center">
          <Stack alignItems="center">
            <Typography variant="h6">Create a New Material</Typography>
            <Typography variant="caption">
              Use the fields below to create a new material
            </Typography>
          </Stack>
          <CreateMaterialForm />
          <HubCancelSubmitGroup
            disabled={isLoading}
            isLoading={isLoading}
            label="Create Part"
          />
        </Stack>
      </HubHookFormWrapper>
    </HubDialogContent>
  );
};

export default CreateMaterialContent;
