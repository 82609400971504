import React from "react";
import { Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import { getCustomerData } from "../../redux/customerData";
import { FOUNDRIES, INDUSTRIES } from "../../constants/industry";
import {
  HubHookFormInput,
  HubHookFormSelect,
  HubHookFormSwitch,
  HubHookFormTagInput,
} from "../../components";
import { getUserLocation } from "../../redux/auth";

const CustomerDetails = ({ disabled = false, details = {} }) => {
  const foundries = details?.locations?.map((l) => l.id);

  return (
    <Stack gap="15px">
      <Typography variant="boldCaptionMain">Customer Info</Typography>
      <Stack
        direction="row"
        gap="10px"
        alignItems="center"
        justifyContent="center"
      >
        <HubHookFormInput
          name="customerName"
          label="Customer"
          placeholder="IPC Foundry Group"
          defaultValue={details.customer}
          disableHelperText
          disabled={disabled}
          required
        />
        <HubHookFormSelect
          name="industry"
          label="Industry"
          placeholder="ex. Oil"
          options={INDUSTRIES}
          sx={{ flexGrow: "1" }}
          useDefault={!!details.industry}
          defaultValue={details.industry}
          disabled={disabled}
          disableHelperText
          required
        />
      </Stack>
      <HubHookFormTagInput
        name="locations"
        label="Foundries"
        options={FOUNDRIES}
        defaultValue={foundries}
        disabled={disabled}
        disableHelperText
        required
      />
      <HubHookFormSwitch
        name="active"
        label="Active Customer"
        labelPlacement="end"
        defaultValue={details.active}
        disabled={disabled}
      />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  details: getCustomerData(state),
  userLocation: getUserLocation(state),
});

export default connect(mapStateToProps)(CustomerDetails);
