import React from "react";
import { useSearchParams } from "react-router-dom";

import { FOUNDRIES } from "../../constants";
import { StyledSelect } from "./styles";
import { useDisplaysContext } from "../../pages/Displays/DisplayContextProvider";

const DisplayLocation = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { locationId, setLocationId } = useDisplaysContext();

  const property = searchParams.get("location") || locationId;
  const setValue = (value) => {
    setLocationId(value);
    if (value) setSearchParams((prev) => ({ ...prev, location: value }));
  };

  return (
    <StyledSelect
      options={FOUNDRIES}
      defaultValue={property}
      onChange={setValue}
    />
  );
};

export default DisplayLocation;
