import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import { useController, useFormContext } from "react-hook-form";

import { HubHookFormInputWrapper } from "../HubHookFormInputWrapper";

export const HubHookFormDatePicker = ({
  name = "",
  defaultValue = null,
  required = false,
  rules = {},
  disabled = false,
  label = "",
  disableHelperText = true,
  helperText = "",
  monthFirst = true,
  smallLabel = true,
  hidden = false,
  includeTime = false,
  includeDate = true,
  placeholder = "MM/DD/YYYY",
  formProps = {},
  onChange = () => {},
  slots = {},
  slotProps = {},
  useSetValue = false,
  ...rest
}) => {
  const id = `hub-hook-date-picker-${name}`;
  const { setValue } = useFormContext();

  const finalFormat = `${includeDate ? "L" : ""}${
    includeDate && includeTime ? " " : ""
  }${includeTime ? "LT" : ""}`;

  const {
    field: { onChange: onControllerChange, name: inputName, value, ref },
    fieldState: { error },
  } = useController({
    name,
    rules: { required, ...rules },
    defaultValue,
    shouldUnregister: true,
  });

  useEffect(() => {
    if (defaultValue) setValue(name, defaultValue);
  }, [defaultValue, setValue, name, includeTime]);

  const errorMessage = error?.message;
  const helperTextMessage = disableHelperText
    ? null
    : errorMessage || helperText || <span>&nbsp;</span>;

  const handleChange = useCallback(
    (value) => {
      const final = dayjs(value)?.format(finalFormat)?.toString();
      onControllerChange(value);
      onChange(final);
    },
    [finalFormat, useSetValue, onControllerChange, onChange]
  );

  const inputValue = typeof value === "string" ? dayjs(value) : null;

  const finalProps = {
    value: inputValue,
    name: inputName,
    inputRef: ref,
    onChange: handleChange,
    format: `${includeDate ? "L" : ""}${includeDate && includeTime ? " " : ""}${
      includeTime ? "LT" : ""
    }`,
    slots: { ...slots },
    slotProps: {
      textField: { placeholder, error, ...slotProps?.textField },
      ...slotProps,
    },
    disabled,
  };

  const wrapperProps = {
    hidden,
    formControlProps: formProps,
    label,
    required,
    id,
    helperText: helperTextMessage,
    errorMessage: error,
    disableHelperText,
  };

  return (
    <HubHookFormInputWrapper {...wrapperProps}>
      {(includeTime && includeDate) || (!includeTime && !includeDate) ? (
        <DateTimePicker {...finalProps} {...rest} />
      ) : includeTime ? (
        <TimePicker {...finalProps} {...rest} />
      ) : (
        <DatePicker {...finalProps} {...rest} />
      )}
    </HubHookFormInputWrapper>
  );
};

HubHookFormDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disableHelperText: PropTypes.bool,
  helperText: PropTypes.string,
  monthFirst: PropTypes.bool,
  smallLabel: PropTypes.bool,
  hidden: PropTypes.bool,
  includeTime: PropTypes.bool, // propTypes for the new prop
};

export default HubHookFormDatePicker;
