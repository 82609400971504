import { Box, Stack, Typography } from "@mui/material";
import React from "react";

import { HubButton, HubIcon, UserDetailIcon } from "../../components";
import HubCarousel from "../../components/HubCarousel/HubCarousel";

import { DOCS } from "./helper";
import {
  StyledBox,
  StyledCard,
  StyledCardMedia,
  StyledCardStack,
  StyleDocStack,
} from "./styles";
import { HoverInfo } from "../../components/inputs/HubButton/HoverInfo";

export const DocumentsTable = () => {
  return (
    <Stack gap="5px">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="boldCaption">Document Templates</Typography>
        <HubButton
          label="Create New Document"
          variant="contained"
          size="xsmall"
          page="/barcodes/new"
          disabled
        />
      </Stack>
      <StyleDocStack>
        {DOCS?.map(({ label, user, time, src }) => (
          <StyledCard>
            <StyledCardStack onClick={() => {}}>
              <Stack
                direction="row"
                alignItems="center"
                gap="5px"
                padding="0px 1px"
              >
                <HubIcon icon="FileSolid" size={12} />
                <Typography
                  variant="caption"
                  fontWeight={500}
                  textAlign="center"
                >
                  {label}
                </Typography>
              </Stack>

              <StyledBox>
                <StyledCardMedia component="img" src={src} />
              </StyledBox>

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                padding="0px 1px"
              >
                <Stack direction="row" alignItems="center" padding="0px 1px">
                  <HoverInfo hoverTitle={user.name} hoverSubtitle={user.email}>
                    <UserDetailIcon
                      user={user}
                      includeEmail={false}
                      includeName={false}
                      size={14}
                    />
                  </HoverInfo>
                  <Typography variant="caption">Edited - {time}</Typography>
                </Stack>
                <HubIcon icon="MoreVertical" size={12} />
              </Stack>
            </StyledCardStack>
          </StyledCard>
        ))}
      </StyleDocStack>
    </Stack>
  );
};

export default DocumentsTable;
