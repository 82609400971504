import React from "react";

import { HubTableHeader, InputLabel } from "../../components";

const required = true;

const headCells = [
  {
    id: "s-customer",
    label: "Customer",
    required,
  },
  {
    id: "s-boxes",
    label: "Parts Per Boxes",
  },
  {
    id: "s-notes",
    label: "Notes",
  },
];

export const PartShippingHeader = () => {
  const ITEMS = headCells.map((cell) => ({
    id: cell.id,
    label: (
      <InputLabel
        label={cell.label}
        required={cell.required}
        marginLeft="5px"
        marginBottom="0px"
      />
    ),
  }));

  return (
    <HubTableHeader items={ITEMS} includeCheckbox={false} includeEnd={false} />
  );
};

export default PartShippingHeader;
