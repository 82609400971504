import React from "react";
import { FormHelperText } from "@mui/material";

import { InputLabel } from "../InputLabel";
import { StyledFormControl } from "./styles";

export const HubHookFormInputWrapper = ({
  hidden = false,
  fullWidth = true,
  formControlProps = {},
  label,
  required = true,
  id,
  smallLabel = true,
  children,
  helperText,
  errorMessage,
  disableHelperText = false,
}) => {
  return (
    <StyledFormControl
      hidden={hidden}
      fullWidth={fullWidth}
      variant="outlined"
      {...formControlProps}
    >
      <InputLabel
        label={label}
        required={required}
        id={id}
        smallLabel={smallLabel}
      />
      {children}
      {helperText && (
        <FormHelperText error={!!errorMessage}>
          {errorMessage ||
            helperText ||
            (disableHelperText ? "" : <span>&nbsp;</span>)}
        </FormHelperText>
      )}
    </StyledFormControl>
  );
};

export default HubHookFormInputWrapper;
