import React from "react";

import { HubContextMenu } from "../../components";
import { usePutStationDisplayLayout } from "../../hooks";
import { useDisplaysContext } from "../../pages/Displays/DisplayContextProvider";

const MENU_ITEMS = [
  {
    id: "edit",
    label: "Edit",
    icon: "Edit",
  },
  {
    id: "layout",
    label: "Update Table Layout",
    icon: "Layout",
  },
  {
    id: "delete",
    label: "Delete",
    icon: "Trash2",
    color: "redError",
  },
];

const DisplayContextMenu = ({
  id,
  setOpenEdit = () => {},
  setDeletingId = () => {},
  ...rest
}) => {
  const { layout } = useDisplaysContext();
  const { mutate: putStationDisplayLayout } = usePutStationDisplayLayout();

  const onSelect = (item) => {
    switch (item?.id) {
      case "edit":
        setOpenEdit(id);
        break;
      case "delete":
        setDeletingId(id);
        break;
      case "layout":
        const payload = {
          id,
          payload: layout?.map(({ i, x, y, h, w }) => ({
            i: Number(i),
            x,
            y,
            h,
            w,
          })),
        };
        putStationDisplayLayout(payload);
      default:
        break;
    }
  };

  return (
    <HubContextMenu
      id={"display"}
      loading={false}
      menuItems={MENU_ITEMS}
      onSelect={onSelect}
      IconButtonProps={{ sx: { padding: 0 } }}
      HubIconProps={{ size: 14 }}
      {...rest}
    />
  );
};

export default DisplayContextMenu;
