import React from "react";
import { useFormContext } from "react-hook-form";
import { Stack, Typography } from "@mui/material";

import {
  HubHookFormInput,
  HubHookFormTimePicker,
  StationHookFormSelect,
} from "../../../components";
import { StyledHubCardStack } from "../styles";
import { PartShippingTable } from "../../PartShippingTable";

const gap = "10px";

const PartShipping = () => {
  const { getValues } = useFormContext();
  const details = getValues("shipping");

  return (
    <StyledHubCardStack gap="25px">
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">Shipping Details</Typography>
        <Stack direction="row" gap={gap}>
          <StationHookFormSelect
            name="shipping.stationId"
            label="Station"
            defaultValue={details?.stationId}
            department={8}
          />
          <HubHookFormTimePicker
            name="shipping.leadTime"
            label="Lead Time"
            placeholder="Ex. 12"
            defaultValue={details?.leadTime}
            onlyDays
            required
          />
          <HubHookFormTimePicker
            name="shipping.cycleTime"
            label="Cycle Time"
            placeholder="Ex. 0.24"
            defaultValue={details?.cycleTime}
            required
          />
        </Stack>
        <HubHookFormInput
          name="shipping.notes"
          label="Special Instructions"
          placeholder="Shipping notes..."
          defaultValue={details?.notes}
          rows={5}
          multiline
        />
        {details?.id && (
          <HubHookFormInput
            name="shipping.id"
            defaultValue={details?.id}
            hidden
          />
        )}
      </Stack>
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">
          Customer Shipping Details
        </Typography>
        <PartShippingTable fields={details?.customers} />
      </Stack>
    </StyledHubCardStack>
  );
};

export default PartShipping;
