import React from "react";
import { Stack } from "@mui/material";

import { StyledSkeleton } from "../../pages/PartDetails/styles";

const RoutingTableSkeleton = () => {
  return (
    <Stack gap="2px">
      <StyledSkeleton variant="rounded" height={30.5} />
      {Array.from(new Array(7)).map((_, rowIndex) => (
        <StyledSkeleton key={rowIndex} variant="rounded" height={33.33} />
      ))}
    </Stack>
  );
};

export default RoutingTableSkeleton;
