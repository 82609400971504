import React from "react";

export const Hand = () => {
  return (
    <svg
      width="28"
      height="30"
      viewBox="0 0 35 40"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        d="M7.65186 5.25V17.5226L5.90355 15.832L5.90296 15.8314C4.80156 14.7686 3.05694 14.8122 2.0064 15.9281C0.957909 17.0418 1.00905 18.7948 2.09992 19.8544L2.10113 19.8556L7.72007 25.2887C7.72018 25.2888 7.72029 25.2889 7.7204 25.289C7.72044 25.2891 7.72049 25.2891 7.72053 25.2892C10.0143 27.5107 13.0656 28.75 16.241 28.75H17.2009C22.4856 28.75 26.75 24.4016 26.75 19.0625V8.5C26.75 7.19804 25.7103 6.125 24.4002 6.125C23.0901 6.125 22.0503 7.19804 22.0503 8.5V14.1875C22.0503 14.2062 22.0434 14.2225 22.0309 14.2352C22.0186 14.2477 22.0079 14.25 22.0004 14.25C21.9929 14.25 21.9823 14.2477 21.9699 14.2352C21.9575 14.2225 21.9505 14.2062 21.9505 14.1875V5.25C21.9505 3.94804 20.9108 2.875 19.6007 2.875C18.2906 2.875 17.2508 3.94804 17.2508 5.25V14.1875C17.2508 14.2062 17.2439 14.2225 17.2314 14.2352C17.2191 14.2477 17.2084 14.25 17.2009 14.25C17.1934 14.25 17.1828 14.2477 17.1704 14.2352C17.158 14.2225 17.151 14.2062 17.151 14.1875V3.625C17.151 2.32304 16.1113 1.25 14.8012 1.25C13.4911 1.25 12.4514 2.32304 12.4514 3.625V14.1875C12.4514 14.2062 12.4444 14.2225 12.4319 14.2352C12.4196 14.2477 12.4089 14.25 12.4014 14.25C12.3939 14.25 12.3833 14.2477 12.3709 14.2352C12.3585 14.2225 12.3515 14.2062 12.3515 14.1875V5.25C12.3515 3.94804 11.3118 2.875 10.0017 2.875C8.69158 2.875 7.65186 3.94804 7.65186 5.25Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Hand;
