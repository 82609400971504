import { styled } from "@mui/material/styles";
import { Drawer } from "@mui/material";

export const StyledDrawer = styled(Drawer)({
  "& .MuiDrawer-paper": {
    width: "100%",
    maxWidth: "550px",
    // borderRadius: "0px 25px 25px 0",
    overflow: "hidden",
  },
});
