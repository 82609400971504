import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";

import { useHubSnackBar } from "../../../hooks";
import { RoutingTable } from "../../../tables";
import { FOUNDRIES } from "../../../constants";
import { HubTabs } from "../../../components";

import PartMainDetails from "./PartMainDetails";
import { StyledHubCardStack } from "../styles";

const gap = "15px";

const PartRouting = ({}) => {
  const [value, setValue] = useState(FOUNDRIES[0].id);

  const { formState } = useFormContext();
  const { errors } = formState;
  const hasErrors = !!Object.keys(errors).length;

  const { addSnackbarError } = useHubSnackBar();

  useEffect(() => {
    hasErrors &&
      addSnackbarError({
        title: "Action Required",
        message:
          "Some required fields are missing or invalid. In order to submit, please resolve these errors.",
      });
  }, [hasErrors]);

  return (
    <StyledHubCardStack>
      <PartMainDetails />
      <Stack gap={gap} height="100%" overflow="hidden">
        <Typography variant="boldCaptionMain">Part Routing</Typography>
        <HubTabs tabs={FOUNDRIES} value={value} setValue={setValue} />
        <RoutingTable locationId={value} />
      </Stack>
    </StyledHubCardStack>
  );
};

export default PartRouting;
