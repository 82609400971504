import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import {
  HubCardStack,
  HubCircularProgress,
  HubHookFormSelect,
  HubHookFormWrapper,
  HubTableTemplate,
  PageContent,
} from "../../components";
import { getUserLocation } from "../../redux";
import { useGetAllLaborData } from "../../hooks";
import { DEPARTMENTS } from "../../constants";

const sortable = true;
const items = [
  {
    id: "workOrder",
    label: "Work Order",
    sortable,
  },
  {
    id: "partName",
    label: "Part Name",
    // sortable,
  },
  {
    id: "employee",
    label: "Employee",
    sortable,
  },
  {
    id: "completed",
    label: "Completed",
  },
  {
    id: "scrapped",
    label: "Scrapped",
  },
  {
    id: "startDate",
    label: "Start Date",
  },
];

const keys = [
  {
    value: "workOrder",
    variant: "blueBold",
    navigate: true,
    route: "works",
    id: "workOrder",
  },
  {
    value: "part.partName",
    variant: "blueBold",
    navigate: true,
    route: "parts",
    id: "partId",
  },
  {
    value: "employee",
  },
  {
    value: "completed",
    defaultValue: 0,
  },
  {
    value: "scrapped",
    defaultValue: 0,
  },
  {
    value: "startDate",
    isDateTime: true,
  },
];

const Tickets = ({ defaultLocation = 1 }) => {
  const { data, isLoading, refetch } = useGetAllLaborData({ defaultLocation });

  const navigate = useNavigate();
  const location = useLocation();

  // Refetch on URL search change
  useEffect(() => {
    refetch();
  }, [location?.search?.toString()]);

  const onRowClick = (e, item) => {
    if (item?.id) {
      e.preventDefault();
      e.stopPropagation();
      navigate(`/labor/${item.id}`);
    }
  };

  const headerProps = { items };
  const bodyProps = { items: data?.rows, keys, onRowClick };
  const props = { headerProps, bodyProps, meta: data?.meta };

  const query = new URLSearchParams(location.search);
  const dep = query.get("department");

  const setValue = (id) => {
    localStorage && localStorage.setItem("Ticket-Department", id);
    query.set("department", id);

    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  useEffect(() => {
    const defaultId = localStorage
      ? localStorage.getItem("Ticket-Department")
      : dep;
    setValue(defaultId || 1);
  }, [dep, DEPARTMENTS]);

  return (
    <PageContent>
      {isLoading ? (
        <HubCircularProgress centerScreen />
      ) : (
        <HubCardStack gap="15px" maxWidth="1000px" width="100%" mx="auto">
          <HubHookFormWrapper>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="boldCaption">Open Labor Tickets</Typography>
              <HubHookFormSelect
                name="department"
                defaultValue={dep}
                options={DEPARTMENTS}
                onChange={(e) => setValue(e.target.value)}
                sx={{ width: "200px" }}
                fullWidth={false}
              />
            </Stack>
          </HubHookFormWrapper>
          <HubTableTemplate id="DisplayTable" {...props} />
        </HubCardStack>
      )}
    </PageContent>
  );
};

const mapStateToProps = (state) => ({
  defaultLocation: getUserLocation(state),
});
const mapDispatchToProps = {};

export const TicketsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Tickets);
export default TicketsPage;
