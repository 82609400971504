import React from "react";
import { Stack } from "@mui/material";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { getCustomerDataParts } from "../../redux/customerData";
import OrderTableSkeleton from "../../tables/OrderLineItemsTable/OrderTableSkeleton";
import { CustomerOrdersTable } from "../../tables";
import { CreatePurchaseOrder, RepeatWorkOrderDialog } from "../../dialogs";
import { useGetCustomerOrderData } from "../../hooks";

import CustomerOrdersBar from "./CustomerOrdersBar";
import CustomerOrdersEmpty from "./CustomerOrdersEmpty";

const name = "purchase-order-create";

export const CustomerOrdersUnwrapped = ({ hasParts = false }) => {
  const { id } = useParams();
  const { data, isLoading } = useGetCustomerOrderData(id);
  const count = data?.meta?.count;

  const createProps = !hasParts
    ? {
        disabled: true,
        hoverTitle: "Customer Has No Parts",
        hoverSubtitle: "Cannot create order for customer without parts",
      }
    : {};

  if (isLoading) return <OrderTableSkeleton />;
  return (
    <>
      {count ? (
        <Stack gap="25px">
          <CustomerOrdersBar
            createProps={createProps}
            name={name}
            count={count}
          />
          <CustomerOrdersTable />
        </Stack>
      ) : (
        <CustomerOrdersEmpty createProps={createProps} />
      )}
      <CreatePurchaseOrder />
      <RepeatWorkOrderDialog />
    </>
  );
};

const mapStateToProps = (state) => ({
  hasParts: getCustomerDataParts(state),
});

export const CustomerOrders = connect(mapStateToProps)(CustomerOrdersUnwrapped);
