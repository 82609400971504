import React, { useEffect, useState } from "react";
import { Checkbox, TableCell } from "@mui/material";
import { connect } from "react-redux";
import { useFormContext } from "react-hook-form";

import { adjustPartRoutings, getPartRoutings } from "../../redux/routing";

import RoutingSubPage from "./RoutingSubPage";
import { StyledTableRow, StyledTypography } from "./styles";

const RoutingTableRow = ({
  route = {},
  routings = [],
  expandAll = false,
  _adjustPartRoutings = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const onClick = () => isSelected && setOpen(!open);
  const isSelected = routings?.includes(route.id);

  const { formState } = useFormContext();
  const { errors } = formState;
  const routeErrors = errors[route.key];
  const hasError = isSelected && routeErrors;
  const count = Object.keys(routeErrors || {})?.length || 0;

  useEffect(() => {
    setOpen(expandAll);
  }, [expandAll]);

  return (
    <>
      <StyledTableRow isSelected={isSelected} onClick={onClick}>
        <TableCell padding="checkbox">
          <Checkbox
            onChange={() => _adjustPartRoutings(route.id)}
            checked={isSelected}
            sx={{ padding: 0 }}
          />
        </TableCell>
        <TableCell sx={{ fontWeight: 500 }}>{route.label}</TableCell>
        <TableCell>
          {hasError && (
            <StyledTypography>Missing Fields: {count}</StyledTypography>
          )}
        </TableCell>
      </StyledTableRow>
      {isSelected && <RoutingSubPage isOpen={open} id={route.key} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  routings: getPartRoutings(state),
});
const mapDispatchToProps = {
  _adjustPartRoutings: adjustPartRoutings,
};
export default connect(mapStateToProps, mapDispatchToProps)(RoutingTableRow);
