import React from "react";
import { Stack, Typography } from "@mui/material";

import { HubButton, HubCardStack } from "../../components";
import { WorkOrderRoutingTableV2 } from "../../tables";
import { useFieldArray } from "react-hook-form";

export const WorkOrderProd = ({ id }) => {
  const fieldArrayOptions = useFieldArray({
    name: "routes",
  });
  const { append, fields, remove, move } = fieldArrayOptions;

  const handleAdd = (defaultValues = {}) => {
    const lastField = fields.length > 0 ? fields[fields.length - 1] : null;
    const lastPlannedEndDate = lastField?.plannedEndDate || null;

    append({
      routeId: null,
      stationId: null,
      materialId: null,
      plannedStartDate: lastPlannedEndDate,
      plannedEndDate: lastPlannedEndDate,
      completed: 0,
      scrapped: 0,
      startDate: null,
      endDate: null,
      leadTime: 0,
      order: fields.length,
      ...defaultValues,
    });
  };

  const props = { handleAdd, remove, move, fields };

  return (
    <Stack
      padding="15px"
      overflow="auto"
      sx={{
        "&::-webkit-scrollbar": { display: "none !important" },
      }}
    >
      <HubCardStack padding="20px" gap="15px">
        <Stack
          direction="row"
          justifyContent="space-between"
          gap="25px"
          alignItems="center"
        >
          <Typography variant="boldCaption">Work Order Routing</Typography>
          <HubButton
            label="Add Route"
            size="xsmall"
            variant="contained"
            onClick={handleAdd}
          />
        </Stack>
        <WorkOrderRoutingTableV2 key={id} {...props} />
      </HubCardStack>
    </Stack>
  );
};

export default WorkOrderProd;
