import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { HubHookFormInput } from "../../components";

export const StyledTitleStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: "25px",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  borderBottom: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
}));

export const StyledEditorStack = styled(Stack)(({ theme }) => ({
  height: "calc(100vh - 102px)",
  flexDirection: "row",
  overflow: "hidden",
}));

export const StyledInput = styled(HubHookFormInput)({
  width: 250,
  padding: "5px 10px",
  borderRadius: 6,
});
