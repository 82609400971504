import { CompaniesPage } from "../Companies";
import { DocumentsPage } from "../DocumentsPage";
import { OrdersPage } from "../Orders";
import { PartsPage } from "../PartsPage";
import { WorkOrdersPage } from "../WorkOrdersPage";

const includeLocationParam = true;

export const tabs = [
  {
    id: "1",
    label: "Customers",
    icon: "User",
    TabElement: CompaniesPage,
    defaultSearchParams: { active: true },
    includeLocationParam,
  },
  {
    id: "2",
    label: "Parts",
    icon: "Settings",
    TabElement: PartsPage,
  },
  {
    id: "3",
    label: "Orders",
    icon: "Archive",
    TabElement: OrdersPage,
  },
  {
    id: "4",
    label: "Work Orders",
    icon: "FileText",
    TabElement: WorkOrdersPage,
    includeLocationParam,
  },
  {
    id: "5",
    label: "Documents",
    icon: "File",
    TabElement: DocumentsPage,
  },
];
