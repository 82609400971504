import React from "react";
import { Checkbox, Chip } from "@mui/material";

import { getDollarValue } from "../../helpers/currency";
import { getDateData } from "../../helpers/dateHelper";
import UserChip from "./UserChip";

export const handleEvent = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

export const handleClick = (e, key, item) => {
  handleEvent(e);
  key.onClick(item);
};

const getNestedValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

export const processValue = (item, key) => {
  const value = getNestedValue(item, key.value) || key?.defaultValue;
  const CustomComponent = key?.CustomComponent;

  if (CustomComponent && React.isValidElement(<CustomComponent />))
    return <CustomComponent item={item} />;
  if (key.isUser)
    return (
      <UserChip contact={value} {...(key?.userProps ? key?.userProps : {})} />
    );
  if (key.isChip)
    return <Chip label={value} {...(key?.chipProps ? key?.chipProps : {})} />;
  if (key.isCheckbox)
    return <Checkbox checked={value} sx={{ padding: 0 }} disabled />;
  if (!value && typeof value !== "number") return value;
  if (key.isCurrency) return getDollarValue(value);
  if (key.isDate)
    return getDateData({
      startDate: value,
      includeYear: true,
    });
  if (key.isDateTime) {
    return getDateData({
      startDate: value,
      includeYear: true,
      includeTime: true,
    });
  }

  return value;
};
