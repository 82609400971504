import React, { useEffect, useState } from "react";
import { TableCell, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { HubHookFormInput } from "../../components";
import { onUpdateDateHelper } from "./helper";
import { StyledHubHookFormDatePicker, StyledTableRow } from "./styles";

import LineItemContextMenu from "./LineItemContextMenu";
import OrderLineItemChip from "./OrderLineItemChip";
import { getDateData } from "../../helpers/dateHelper";
import { WarningUpdatingWorkOrder } from "../../dialogs";

const OrderLineItemRow = ({
  idx,
  locationId,
  item = {},
  part = {},
  disabled = false,
  deletedValues = [],
  updatedItems = [],
  warnChange = false,
}) => {
  const { setValue, getValues, register } = useFormContext();

  const [deleted, setDeleted] = useState(deletedValues.includes(item.id));
  const navigate = useNavigate();

  const [showWarning, setShowWarning] = useState(false);

  const prefix = `${item.id}`;
  const existing = updatedItems.find((u) => u.id === item.id) || {};
  const rowItem = { ...item, ...existing };

  useEffect(() => {
    if (deleted) {
      const addedDeleted = [...new Set([...deletedValues, item.id])];
      setValue("deletedWorkOrders", addedDeleted);
    }
  }, [deleted]);

  useEffect(() => {
    register(prefix + ".trees", { required: true });
    register(prefix + ".patReq", { required: true });
  }, [register, prefix]);

  useEffect(() => {
    const trees = Math.ceil(
      rowItem.quantity / part?.partsPerTrees[`${locationId}`]
    );
    const patReq = trees * part?.partsPerTrees[`${locationId}`];

    console.log(locationId);

    setValue(prefix + ".trees", trees, { shouldDirty: false });
    setValue(prefix + ".patReq", patReq, { shouldDirty: false });
  }, [part.id, rowItem.quantity]);

  const onFieldUpdate = () => {
    if (warnChange) setShowWarning(true);

    const preItem = getValues(prefix);
    let updatedItem = { ...item, ...preItem };

    const updatedIndex = updatedItems.findIndex((u) => u.id === item.id);
    updatedIndex > -1
      ? (updatedItems[updatedIndex] = updatedItem)
      : updatedItems.push(updatedItem);

    setValue("updatedWorkOrders", updatedItems);
  };

  const onUpdateQuantity = (quantity) => {
    const trees = Math.ceil(quantity / part?.partsPerTrees[`${locationId}`]);
    const patReq = trees * part?.partsPerTrees[`${locationId}`];

    setValue(prefix + ".quantity", patReq);
    setValue(prefix + ".trees", trees);
    setValue(prefix + ".patReq", patReq);
    onFieldUpdate();
  };

  const onUpdateTrees = (trees) => {
    const patReq = trees * part?.partsPerTrees[`${locationId}`];

    setValue(prefix + ".quantity", patReq);
    setValue(prefix + ".trees", trees);
    setValue(prefix + ".patReq", patReq);
    onFieldUpdate();
  };

  const onUpdateDate = (value, isStart = false) => {
    const { startDate, dueDate } = onUpdateDateHelper(
      value,
      isStart,
      part?.totalDays[`${locationId}`]
    );

    setValue(prefix + ".startDate", startDate);
    setValue(prefix + ".endDate", dueDate);
    onFieldUpdate();
  };

  const handleNav = () =>
    rowItem.woNumber && navigate(`/works/${rowItem.woNumber}`);

  if (deleted) return <></>;
  return (
    <>
      <StyledTableRow key={rowItem.id}>
        <TableCell disabled={disabled}>
          <OrderLineItemChip
            state={rowItem?.state}
            label={rowItem?.statusLabel}
          />
        </TableCell>
        <TableCell
          sx={{ minWidth: 100 }}
          variant="blueBold"
          disabled={disabled}
          onClick={handleNav}
        >
          <Typography variant="caption11" color="inherit">
            {rowItem?.woNumber}
          </Typography>
        </TableCell>
        <TableCell sx={{ minWidth: 100 }} disabled={disabled}>
          {disabled ? (
            <Typography variant="caption11">{rowItem?.lotNumber}</Typography>
          ) : (
            <HubHookFormInput
              name={prefix + ".lotNumber"}
              placeholder="UT240000"
              defaultValue={rowItem?.lotNumber}
              onBlur={() => onFieldUpdate()}
              disableHelperText
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ width: 100, minWidth: 100 }} disabled={disabled}>
          {disabled ? (
            <Typography variant="caption11">{rowItem?.trees}</Typography>
          ) : (
            <HubHookFormInput
              name={prefix + ".trees"}
              placeholder="10"
              defaultValue={rowItem?.trees}
              onBlur={(e, value) => onUpdateTrees(value)}
              disableHelperText
              isNumber
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ width: 100, minWidth: 100 }} disabled={disabled}>
          {disabled ? (
            <Typography variant="caption11">{rowItem?.quantity}</Typography>
          ) : (
            <HubHookFormInput
              name={prefix + ".quantity"}
              placeholder="1000"
              defaultValue={rowItem?.quantity}
              onBlur={(e, value) => onUpdateQuantity(value)}
              disableHelperText
              isNumber
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ minWidth: 125 }} disabled={disabled}>
          {disabled ? (
            <Typography variant="caption11">
              {getDateData({
                startDate: rowItem?.startDate,
                includeYear: true,
              })}
            </Typography>
          ) : (
            <StyledHubHookFormDatePicker
              name={prefix + ".startDate"}
              placeholder="MM/DD/YYYY"
              defaultValue={rowItem?.startDate}
              onChange={(value) => onUpdateDate(value, true)}
              disabled={!part?.id}
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ minWidth: 125 }} disabled={disabled}>
          {disabled ? (
            <Typography variant="caption11">
              {getDateData({ startDate: rowItem?.endDate, includeYear: true })}
            </Typography>
          ) : (
            <StyledHubHookFormDatePicker
              name={prefix + ".endDate"}
              placeholder="MM/DD/YYYY"
              defaultValue={rowItem?.endDate}
              onChange={(value) => onUpdateDate(value)}
              disabled={!part?.id}
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ maxWidth: "35px" }}>
          <HubHookFormInput
            name={prefix + ".id"}
            defaultValue={rowItem?.id}
            disableHelperText
            hidden
          />
          <LineItemContextMenu
            id={idx}
            disabled={disabled}
            removeItem={() => setDeleted(true)}
          />
        </TableCell>
      </StyledTableRow>
      <WarningUpdatingWorkOrder
        onClose={() => setShowWarning(false)}
        isOpen={showWarning}
      />
    </>
  );
};

export default React.memo(OrderLineItemRow);
