import React from "react";
import { Stack, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";

import {
  HubHookFormInput,
  HubHookFormSwitch,
  HubHookFormTimePicker,
  StationHookFormSelect,
} from "../../../components";
import { StyledHubCardStack } from "../styles";

const gap = "10px";
const columnGap = "10px";

const PartAutoclave = () => {
  const { getValues } = useFormContext();
  const details = getValues("autoclave");

  return (
    <StyledHubCardStack gap="25px">
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">Dewax Details</Typography>
        <Stack gap="25px">
          <Stack direction="row" gap={columnGap}>
            <StationHookFormSelect
              name="autoclave.stationId"
              label="Station"
              defaultValue={details?.stationId}
              department={3}
            />
            <HubHookFormTimePicker
              name="autoclave.leadTime"
              label="Lead Time"
              placeholder="Ex. 12"
              defaultValue={details?.leadTime}
              onlyDays
              required
            />
            <HubHookFormTimePicker
              name="autoclave.cycleTime"
              label="Cycle Time"
              placeholder="Ex. 0.24"
              defaultValue={details?.cycleTime}
              required
            />
          </Stack>
        </Stack>
        <Stack direction="row" gap={columnGap}>
          <HubHookFormInput
            name="autoclave.treesPerLoad"
            label="Trees / Load"
            defaultValue={details?.treesPerLoad}
            placeholder="Ex. 12"
            isNumber
          />
          <HubHookFormInput
            name="autoclave.dewaxedShellWeight"
            label="Dewaxed Shell Weight"
            defaultValue={details?.dewaxedShellWeight}
            placeholder="Ex. 12"
            isNumber
          />
        </Stack>
      </Stack>
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">Autoclave Notes</Typography>
        <HubHookFormInput
          name={`autoclave.notes`}
          label="Special Instructions"
          defaultValue={details?.notes}
          placeholder="Notes..."
          rows={5}
          multiline
        />
        {details?.id && (
          <HubHookFormInput
            name={`autoclave.id`}
            defaultValue={details?.id}
            hidden
          />
        )}
      </Stack>
    </StyledHubCardStack>
  );
};

export default PartAutoclave;
