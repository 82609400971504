import React from "react";
import { Stack } from "@mui/material";

import { DialogButton, HubBackButton } from "../../components";
import { StyledTitleStack } from "./styles";

import DisplayLocation from "./DisplayLocation";
import WorkerView from "./WorkerView";
import ContextMenu from "./ContextMenu";

export const DisplayPageBar = () => {
  return (
    <StyledTitleStack>
      <Stack direction="row" gap="10px" alignItems="center">
        <HubBackButton />
        <Stack direction="row" gap="10px" alignItems="center">
          <DisplayLocation />
        </Stack>
      </Stack>

      <Stack direction="row" gap="10px" p="0 15px" alignItems="center">
        <WorkerView />

        <DialogButton
          name="create-display"
          label="Create Display"
          variant="contained"
          size="xsmall"
        />
        <ContextMenu />
      </Stack>
    </StyledTitleStack>
  );
};

export default DisplayPageBar;
