import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";

export const StyledAccordion = styled(Accordion, {
  shouldForwardProp: (prop) =>
    !["isOpen", "borderLess", "disableMargin"].includes(prop),
})(({ theme, isOpen, borderLess = false, disableMargin = false }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  "& .MuiAccordionSummary-root": {
    flexDirection: "row",
    gap: "5px",
    ...(borderLess
      ? {}
      : {
          border: "1px solid",
          borderColor: theme.palette.hubColors.greyLight,
          borderRadius: "7px",
          padding: "15px 25px 15px 15px",
        }),

    marginBottom: isOpen && !disableMargin ? "15px" : "0px",
    minHeight: "min-content",
    transition: "margin-bottom 80ms",
    transitionDelay: isOpen ? "0s" : "260ms",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
  "& .MuiAccordionDetails-root ": {
    ...(borderLess ? {} : { padding: "5px 0" }),
  },
  //
}));
