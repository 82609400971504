import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";

const border = "1px solid";
export const StyledSideBar = styled(Stack, {
  shouldForwardProp: (prop) => !["position", "maxWidth"].includes(prop),
})(({ theme, position, maxWidth = "350px" }) => ({
  alignItems: "center",
  maxWidth,
  width: "100%",
  backgroundColor: theme.palette.hubColors.white,
  overflowY: "auto",
  "::-webkit-scrollbar": {
    display: "none",
  },
  padding: "15px",
  ...(position == "left"
    ? {
        borderRight: border,
        marginRight: "auto",
      }
    : { borderLeft: border, marginLeft: "auto" }),
  borderColor: theme.palette.hubColors.greyLight,
}));
