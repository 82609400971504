import React from "react";
import { Chip, Stack, Typography } from "@mui/material";

import { HubTableTemplate } from "../../components";
import StationsContextMenu from "./StationsContextMenu";
import StationWorkOrdersTable from "./StationWorkOrdersTable";

const items = [
  {
    id: "label",
    label: "Name",
  },
  {
    id: "count",
    label: "Active Work Orders",
  },
  {
    id: "details",
    label: "Details",
  },
  {
    id: "archived",
    label: "",
    icon: "Trash",
  },
];

const keys = [
  {
    value: "label",
    sx: {
      width: "250px",
    },
  },
  {
    value: "count",
    defaultValue: 0,
    sx: {
      width: "100px",
    },
  },
  {
    value: "details",
  },
  {
    value: "archived",
    isCheckbox: true,
  },
];

export const StationsTable = ({ data, setUpdatingStation = () => {} }) => {
  const headerProps = { items, includeEnd: true };
  const bodyProps = {
    items: data,
    keys,
    ContextMenu: StationsContextMenu,
    ContextMenuProps: { setUpdatingStation },
    RenderElement: StationWorkOrdersTable,
    includeEnd: true,
  };
  const props = { headerProps, bodyProps };

  return (
    <Stack gap="10px" overflow="hidden">
      <HubTableTemplate id="StationsTable" {...props} />
    </Stack>
  );
};

export default StationsTable;
