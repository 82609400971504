import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { BarcodeStickers } from "../../components";

export const StyledStack = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: `repeat(3, 1fr) 25px`,
  alignItems: "center",
  borderBottom: "1px solid",
  borderRight: "1px solid",
  borderLeft: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
  ":hover": {
    backgroundColor: theme.palette.hubColors.greyLightest,
  },
  ".MuiInputBase-root": {
    // borderRight: "1px solid",
    // borderColor: theme.palette.hubColors.greyLight,
  },
  ".MuiInputBase-root, input": {
    borderRadius: 0,
    backgroundColor: "transparent !important",
  },
  fieldset: {
    // border: 0,
    // borderRight: "0.5px solid",
    // borderColor: theme.palette.hubColors.greyLight,
    borderWidth: 0.5,
  },
}));

export const StyledCell = styled(Stack)(({ theme }) => ({
  height: "100%",
  width: "100%",
  borderRight: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledBarcode = styled(BarcodeStickers)({
  padding: "0px !important",
  border: "none !important",
  height: "58px !important",
  margin: "0 auto",
});
