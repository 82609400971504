import React from "react";

import { HubContextMenu } from "../../components";

const MENU_ITEMS = [
  {
    id: "update",
    label: "Update Station",
    icon: "Plus",
  },
  {
    id: "delete",
    label: "Delete",
    icon: "Trash2",
    color: "redError",
  },
];

export const StationsContextMenu = ({
  item,
  setUpdatingStation = () => {},
  ...rest
}) => {
  const onSelect = (i) => {
    switch (i?.id) {
      case "update":
        setUpdatingStation(item);
        break;
      case "delete":
        console.log(item.id);
      default:
        break;
    }
  };

  return (
    <HubContextMenu
      id={"stations"}
      loading={false}
      menuItems={MENU_ITEMS}
      onSelect={onSelect}
      IconButtonProps={{ sx: { padding: "5px" } }}
      HubIconProps={{ size: 14 }}
      {...rest}
    />
  );
};

export default StationsContextMenu;
