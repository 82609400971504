import React from "react";
import { Stack } from "@mui/material";

import { StyledStack } from "./styles";
import {
  HubHookFormDatePicker,
  HubMaterialHookSelect,
  StationHookFormSelect,
} from "../../components";
import { useFormContext } from "react-hook-form";

const WorkOrderRouteAddRow = ({ handleAdd = () => {} }) => {
  const prefix = "addrow";

  const { getValues, setValue } = useFormContext();

  const value = {
    stationId: null,
    materialId: null,
    completed: 0,
    scrapped: 0,
    plannedStartDate: null,
    plannedEndDate: null,
    startDate: null,
    endDate: null,
  };

  const boxSx = {
    height: "100%",
    width: "100%",
    borderRight: "1px solid",
    borderColor: "hubColors.greyLight",
    alignItems: "center",
    justifyContent: "center",
  };

  const onFieldUpdate = (field) => {
    const value = getValues(`${prefix}.${field}`);
    handleAdd({ [field]: value });
    setValue(prefix, {});
  };

  return (
    <StyledStack sx={{ backgroundColor: "hubColors.mainFocusLighter2" }}>
      <Stack sx={boxSx} direction="row" gap="2px"></Stack>
      <Stack sx={boxSx}></Stack>
      <Stack sx={boxSx}></Stack>
      <StationHookFormSelect
        name={`${prefix}.stationId`}
        onChange={() => onFieldUpdate("stationId")}
        groupByKey="department.label"
        value={value.stationId}
        useDefault={false}
        required={false}
        hideLabel
      />
      <HubMaterialHookSelect
        name={`${prefix}.materialId`}
        useDefault={false}
        required={false}
        onChange={() => onFieldUpdate("materialId")}
        value={value.materialId}
        // useSetValue
        hideLabel
      />
      <Stack sx={boxSx}></Stack>
      <Stack sx={boxSx}></Stack>
      <HubHookFormDatePicker
        name={`${prefix}.plannedStartDate`}
        value={value.plannedStartDate}
        disabled
        // useSetValue
      />
      <HubHookFormDatePicker
        name={`${prefix}.plannedEndDate`}
        value={value.plannedEndDate}
        disabled
        // useSetValue
      />
      <HubHookFormDatePicker
        name={`${prefix}.startDate`}
        value={value.startDate}
        disabled
        // useSetValue
      />
      <HubHookFormDatePicker
        name={`${prefix}.endDate`}
        value={value.endDate}
        disabled
        // useSetValue
      />
    </StyledStack>
  );
};

export default WorkOrderRouteAddRow;
