import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";

import {
  HubCancelSubmitGroup,
  HubDialogContent,
  HubHookFormWrapper,
} from "../../components";
import { usePostCreateStationDisplay } from "../../hooks";
import { useDialogContext } from "../HubDialogWrapper";

import CreateDisplayForm from "./CreateDisplayForm";

const CreateDisplayContent = () => {
  const { mutate, isLoading, isSuccess, data } = usePostCreateStationDisplay();

  const { onClose = () => {} } = useDialogContext();

  useEffect(() => {
    if (isSuccess) onClose();
  }, [isSuccess, data]);

  const onSubmit = (formData) => {
    const payload = {
      title: formData.title,
      locationId: formData.locationId,
      stationIds: formData.stationIds.map((s) => s.id),
      fields: formData.fields.map((f) => f.id),
    };
    // console.log(payload);
    mutate(payload);
  };

  return (
    <HubDialogContent largePadding>
      <HubHookFormWrapper onSubmit={onSubmit}>
        <Stack gap="35px">
          <Stack gap="10px" alignItems="center">
            <Typography variant="h6">Create New Display</Typography>
            <Typography
              variant="caption"
              sx={{ textAlign: "center", maxWidth: "450px" }}
            >
              You are about to create a new display. Please name it and adjust
              settings before creating.
            </Typography>
          </Stack>
          <CreateDisplayForm />
          <HubCancelSubmitGroup
            disabled={isLoading}
            isLoading={isLoading}
            label="Create Display"
          />
        </Stack>
      </HubHookFormWrapper>
    </HubDialogContent>
  );
};

export default CreateDisplayContent;
