import React from "react";
import { Stack, Typography } from "@mui/material";

import { DialogButton } from "../../components";

const OrganizationStationsBar = () => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap="25px"
    >
      <Stack>
        <Typography variant="boldCaption">Departments</Typography>
        <Typography variant="caption">
          Add, Edit, Remove Details from the Departments List Below
        </Typography>
      </Stack>
      <DialogButton
        name="create-station"
        label="Create Station"
        variant="contained"
        size="xsmall"
        sx={{ height: "fit-content" }}
      />
    </Stack>
  );
};

export default OrganizationStationsBar;
