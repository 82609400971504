import { useQuery } from "react-query";
import api from "../../utils/api";

export const ADDRESSES = "/address";

const getCustomerAddresses = ({ queryKey }) =>
  api.get(`${ADDRESSES}/customer/${queryKey[2]}`);
export const useGetCustomerAddresses = (id) =>
  useQuery([ADDRESSES, "customers", id], getCustomerAddresses, {
    enabled: !!id,
  });
