export const getCachedItem = (name, checkExpired = true) => {
  if (typeof localStorage === "undefined") return null;

  const data = localStorage.getItem(name);
  const setTime = localStorage.getItem(`${name}-setTime`);
  const expirationTime = localStorage.getItem(`${name}-expirationTime`);

  if (!data || !setTime || !expirationTime) return null;

  if (checkExpired) {
    const now = Date.now();
    if (now - parseInt(setTime, 10) > parseInt(expirationTime, 10)) {
      localStorage.removeItem(name);
      localStorage.removeItem(`${name}-setTime`);
      localStorage.removeItem(`${name}-expirationTime`);
      return null;
    }
  }

  try {
    return JSON.parse(data);
  } catch (error) {
    console.error(`Error parsing cached item "${name}":`, error);
    return null;
  }
};

// Default Expirition = 1hr
export const setCachedItem = (name, value, expirationMs = 3600000) => {
  if (typeof localStorage === "undefined") return;

  const now = Date.now();

  localStorage.setItem(name, JSON.stringify(value));
  localStorage.setItem(`${name}-setTime`, now.toString());
  localStorage.setItem(`${name}-expirationTime`, expirationMs.toString());
};

export const clearCachedItem = (name) => {
  localStorage.removeItem(name);
  localStorage.removeItem(`${name}-setTime`);
  localStorage.removeItem(`${name}-expirationTime`);
};
