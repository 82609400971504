import qs from "qs-lite";
import { useQuery } from "react-query";
import api from "../../utils/api";

const DISPLAY = "/display";

const getDisplayDashboard = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${DISPLAY}?${queryString}`);
};

export const useGetDisplayDashboard = ({ labor = false, defaultLocation }) => {
  const query = new URLSearchParams(window.location.search);

  const department = query.get("department") || 1;
  const location = query.get("location") || defaultLocation;

  const orderBy = query.get("orderBy") || "startDate";
  const sortOrder = query.get("sortOrder") || "asc";
  const limit = query.get("limit") || 100;
  const offset = query.get("offset") || 0;

  const queryProps = {
    department,
    limit,
    offset,
    orderBy,
    sortOrder,
    location,
    ...(labor ? { labor } : {}),
  };

  return useQuery([DISPLAY, { ...queryProps }], getDisplayDashboard, {
    retry: false,
    enabled: !!location,
    keepPreviousData: true,
    refetchInterval: 60000,
  });
};
