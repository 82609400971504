import { getDataObject, renderTemplate } from "../BarcodeEditorSideBar/helper";

const labelToCamelCase = (label) => {
  return label
    .split(" ")
    .map((word, index) => {
      const lower = word.toLowerCase();
      if (index === 0) return lower;
      return lower.charAt(0).toUpperCase() + lower.slice(1);
    })
    .join("");
};

export const getBarcodeLabels = ({
  fields = [],
  delimiter = "-",
  dataObject = 1,
  serialize = false,
  topTemplates = {},
  bottomTemplates = {},
  qty = 10,
}) => {
  const data = getDataObject(dataObject);
  const join = delimiter === "Space" ? " " : delimiter;
  const topValues = Object.values(topTemplates);
  const bottomValues = Object.values(bottomTemplates);

  const base = fields
    ?.map(({ field = "", format, padding = 0 }) => {
      const key = labelToCamelCase(field);
      const value = `${data[key]}` || "";

      switch (format) {
        case 2:
          return value?.replace(/\D/g, "").padStart(padding, "0");
        case 3:
          return value?.replace(/\D/g, "");
        default:
          return value;
      }
    })
    ?.join(join);

  if (serialize) {
    return Array.from({ length: qty }, (_, idx) => {
      const index = idx + 1;
      const barcode = `${base}${join}${index}`;
      const top = topValues.map((template) =>
        renderTemplate({ template, id: dataObject, index })
      );
      const bottom = bottomValues.map((template) =>
        renderTemplate({ template, id: dataObject, index })
      );
      return { barcode, top, bottom };
    });
  }

  const top = topValues.map((template) =>
    renderTemplate({ template, id: dataObject })
  );
  const bottom = bottomValues.map((template) =>
    renderTemplate({ template, id: dataObject })
  );
  return [{ barcode: base, top, bottom }];
};
