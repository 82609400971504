import React from "react";

import { PageContent } from "../../components";
import { BarcodesCarousel, DocumentsTable } from "../../containers";

export const DocumentsPage = () => {
  return (
    <PageContent gap="25px" smallPadding white>
      <BarcodesCarousel />
      <DocumentsTable />
    </PageContent>
  );
};
