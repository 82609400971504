import React from "react";
import { TableCell } from "@mui/material";

import { SHIPPING } from "../../constants";
import {
  HubHookCustomersSelect,
  HubHookFormCustomerAddress,
  HubHookFormInput,
  HubHookFormSelect,
} from "../../components";
import { StyledTableRow } from "./styles";

const PartShippingRow = ({ idx = 0, details = {} }) => {
  const preset = `shipping.customers[${idx}]`;

  return (
    <StyledTableRow>
      <TableCell sx={{ width: "200px" }}>
        <HubHookCustomersSelect
          name={`${preset}.customerId`}
          key={`${preset}.customerId`}
          defaultValue={details?.customerId}
          label={false}
          isInput={false}
        />
      </TableCell>
      <TableCell sx={{ width: "100px" }}>
        <HubHookFormInput
          name={`${preset}.boxes`}
          key={`${preset}.boxes`}
          defaultValue={details?.boxes}
          isNumber
        />
      </TableCell>
      <TableCell>
        <HubHookFormInput
          name={`${preset}.notes`}
          key={`${preset}.notes`}
          defaultValue={details?.notes}
          placeholder="Palletization  Instructions..."
        />
      </TableCell>
    </StyledTableRow>
  );
};

export default PartShippingRow;
