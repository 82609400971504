import React from "react";
import { connect } from "react-redux";
import { Stack, Typography } from "@mui/material";

import { SHIPPING } from "../../constants";
import { getCustomerData } from "../../redux";
import {
  HubHookFormCustomerAddress,
  HubHookFormInput,
  HubHookFormSelect,
} from "../../components";

const CustomerDefaults = ({ details = {}, disabled = false }) => {
  return (
    <Stack gap="15px">
      <Typography variant="boldCaptionMain">Shipping Defaults</Typography>
      <Stack gap="10px" sx={{ flexDirection: { sm: "column", md: "row" } }}>
        <HubHookFormInput
          name="defaultFreight"
          label="Freight"
          placeholder="X"
          defaultValue={details?.defaultFreight}
          disableHelperText
          required={false}
          disabled={disabled}
        />
        <HubHookFormSelect
          name="defaultShipVia"
          label="Ship Via"
          options={SHIPPING}
          defaultValue={details?.defaultShipVia}
          disableHelperText
          required={false}
          disabled={disabled}
        />
      </Stack>
      <HubHookFormCustomerAddress
        name="defaultShipTo"
        label="Ship To"
        customerId={details?.id}
        defaultValue={details?.defaultShipTo}
        disableHelperText
        required={false}
        disabled={disabled}
      />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  details: getCustomerData(state),
});

export default connect(mapStateToProps)(CustomerDefaults);
