import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { HubSelect } from "../../components";

export const StyledTitleStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: "25px",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  borderBottom: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
}));

export const StyledSelect = styled(HubSelect)({
  width: 125,
  padding: "5px 10px",
  borderRadius: 6,
  svg: {
    width: 12,
    height: 12,
    margin: "5px 5px 0px 0px",
  },
});
