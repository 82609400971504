import React, { useMemo, useState } from "react";

import { HubHookFormWrapper } from "../../components";
import WorkOrderMain from "./WorkOrderMain";
import WorkOrderBar from "./WorkOrderBar";
import WorkOrderNav from "./WorkOrderNav";

import { StyleDisplayStack } from "./styles";
import { getDefaultValues, tabs, updateMapper } from "./helper";

const formSx = { height: "100%", overflow: "hidden" };

const WorkOrderContent = ({ id, data, setPayload, handleCloseWorkOrder }) => {
  const [value, setValue] = useState(1);

  const defaultValues = useMemo(() => getDefaultValues(data), [data]);
  const DisplayTab = useMemo(
    () => tabs.find((t) => t.id === value)?.TabElement || <></>,
    [value]
  );

  const onFormSubmit = (formData) => {
    const updatePayload = updateMapper(formData, data, defaultValues.routes, id);
    setPayload(updatePayload);
  };

  return (
    <HubHookFormWrapper
      onSubmit={onFormSubmit}
      sx={formSx}
      defaultValues={defaultValues}
    >
      <WorkOrderBar
        workOrder={data?.workOrder}
        onCloseWorkOrder={handleCloseWorkOrder}
        status={data?.status}
        state={data?.state}
      />
      <StyleDisplayStack>
        <WorkOrderNav value={value} setValue={setValue} />
        <DisplayTab key={id} id={id} rows={defaultValues.routes} />
        <WorkOrderMain />
      </StyleDisplayStack>
    </HubHookFormWrapper>
  );
};

export default WorkOrderContent;
