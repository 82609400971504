import React from "react";
import { Stack } from "@mui/material";

import {
  HubHookFormInput,
  HubHookFormSelect,
  HubHookFormSwitch,
} from "../../components";
import { DEPARTMENTS } from "../../constants";

const CreateStationForm = ({ details = {} }) => {
  return (
    <Stack gap="15px" width="100%">
      <Stack direction="row" gap="15px">
        <HubHookFormSelect
          name="departmentId"
          label="Department"
          options={DEPARTMENTS}
          defaultValue={details?.departmentId}
          required
        />
        <HubHookFormInput
          name="label"
          label="Name"
          placeholder="Name"
          defaultValue={details?.label}
          required
        />
      </Stack>
      <HubHookFormInput
        name="details"
        label="Details"
        defaultValue={details?.details}
        placeholder="Details..."
        rows={5}
        multiline
      />
      {details?.label && (
        <HubHookFormSwitch
          name="archived"
          label="Archived"
          labelPlacement="end"
          defaultValue={details?.archived}
          isCheckbox
        />
      )}
    </Stack>
  );
};

export default CreateStationForm;
