import React, { useState } from "react";
import { Stack } from "@mui/material";
import { HubButton } from "../../components";

import CreateEmployeeSteps from "./CreateEmployeeSteps";
import CreateEmployeeHire from "./CreateEmployeeHire";
import CreateEmployeePersonal from "./CreateEmployeePersonal";
import { useDialogContext } from "../HubDialogWrapper";
import { StyledBox } from "./styles";

const sx = { minWidth: "120px", padding: "5px" };
const CreateEmployeeFormSteps = ({ isLoading }) => {
  const [step, setStep] = useState(0);
  const { onClose = () => {} } = useDialogContext();

  const handleNext = (e) => {
    e.stopPropagation();
    e.preventDefault();
    return setStep((prev) => Math.min(prev + 1, 1));
  };
  const handleBack = () => setStep((prev) => Math.max(prev - 1, 0));

  return (
    <Stack gap="25px" width="100%" position="relative">
      <CreateEmployeeSteps value={step} />
      <Stack width="100%" position="relative" height="255px" overflow="hidden">
        <StyledBox active={step === 0} m={-1}>
          <CreateEmployeeHire /> {/* Part One */}
        </StyledBox>
        <StyledBox active={step === 1} m={1}>
          <CreateEmployeePersonal /> {/* Part Two */}
        </StyledBox>
      </Stack>
      <Stack direction="row" justifyContent="center" gap="10px">
        <HubButton
          variant="contained"
          color="grey"
          label={step === 1 ? "Back" : "Cancel"}
          size="small"
          onClick={step === 0 ? onClose : handleBack}
          sx={sx}
          disabled={isLoading}
        />
        <HubButton
          label={step === 0 ? "Next" : "Create Employee"}
          {...(step === 0 ? { onClick: handleNext } : { type: "submit" })}
          variant="contained"
          size="small"
          sx={sx}
          disabled={isLoading}
        />
      </Stack>
    </Stack>
  );
};

export default CreateEmployeeFormSteps;
