import React from "react";
import { Box } from "@mui/material";

import { StyledTab, StyleTabContainer } from "./styles";

export const HubTabs = ({
  tabs = [],
  value = "",
  setValue = () => {},
  borderless = false,
  sx,
  ...rest
}) => {
  const basicProps = { height: "35px" };
  const boxProps = borderless
    ? basicProps
    : { borderBottom: 1, borderColor: "hubColors.greyLight", ...basicProps };

  const handleChange = (e, newValue) => {
    e.preventDefault();
    e.stopPropagation();
    setValue(tabs[newValue].id);
  };

  return (
    <Box sx={{ ...boxProps, ...sx }} {...rest}>
      <StyleTabContainer
        value={tabs.findIndex(({ id }) => id === value)}
        onChange={handleChange}
        aria-label="hub tab panels"
      >
        {tabs?.map(({ id, label }) => {
          return <StyledTab label={label} id={id} active={id === value} />;
        })}
      </StyleTabContainer>
    </Box>
  );
};

export default HubTabs;
