import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";

import {
  useGetOrderEditData,
  usePutOrderData,
  useGetWorkOrderByOrderId,
  useDeleteOrderById,
} from "../../hooks";
import { HubHookFormWrapper, PageContent } from "../../components";
import {
  formOrderMapper,
  getOrderDefaultData,
  newItemsMapper,
  updatedMapper,
  updateWorkOrderMapper,
} from "./helper";

import OrderDetails from "./OrderDetails";
import OrderList from "./OrderList";
import { StyledPageStack } from "./styles";
import OrdersBar from "./OrdersBar";
import { setIsOpenDialog } from "../../redux";
import { AddWorkOrderDialog, WarningDeleteDialog } from "../../dialogs";

const OrderPage = ({ _setIsOpenDialog = () => {} }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useGetOrderEditData(id);
  const { mutate: deleteOrderById, isSuccess: isDeleteSuccess } =
    useDeleteOrderById();
  const {
    mutate: putOrderData,
    isLoading: isUpdateLoading,
    isSuccess,
  } = usePutOrderData();

  const [line, setLine] = useState();
  const [deletingId, setDeletingId] = useState(false);

  const handleDeleteClose = () => setDeletingId(false);
  const handleDeleteSubmit = () => deletingId && deleteOrderById(deletingId);

  useEffect(() => {
    refetch();
  }, [id]);

  useEffect(() => {
    isSuccess && window.location.reload();
  }, [isSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) navigate(`/customers/${data?.customer?.id}`);
  }, [isDeleteSuccess]);

  useEffect(() => {
    line && _setIsOpenDialog("add-work-order", true);
  }, [line]);

  const onSubmit = (formData) => {
    const orderId = parseInt(id);
    const payload = {
      id: orderId,
      customerId: data?.customer?.id,
      poNumber: formData.poNumber,
      overShipping: formData.overShipping,
      shipVia: formData.shipVia,
      shipTo: formData.shipTo,
      freight: formData.freight,
      location: formData.location,

      deletedLineItems: formData?.deletedLineItems || [],
      newLineItems: newItemsMapper(formData?.newLineItems || []),
      updatedLineItems: updatedMapper(formData?.updatedLineItems || []),

      deletedWorkOrders: formData.deletedWorkOrders || [],
      updatedWorkOrders: updateWorkOrderMapper(
        formData.updatedWorkOrders || []
      ),
    };
    putOrderData(payload);
  };

  return (
    <PageContent isLoading={isLoading} disablePadding hideOverflow>
      <OrdersBar id={id} setDeletingId={setDeletingId} />
      <HubHookFormWrapper
        onSubmit={onSubmit}
        sx={{ overflow: "hidden" }}
        defaultValues={getOrderDefaultData(data)}
      >
        <StyledPageStack>
          <OrderList setLine={setLine} />
          <OrderDetails
            isLoading={isLoading}
            data={data}
            isUpdateLoading={isUpdateLoading}
          />
        </StyledPageStack>
      </HubHookFormWrapper>
      <AddWorkOrderDialog lineId={line} onClose={() => setLine(null)} />
      <WarningDeleteDialog
        isOpen={!!deletingId}
        onClose={handleDeleteClose}
        onSubmit={handleDeleteSubmit}
        title="You are about to delete this order!"
      />
    </PageContent>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  _setIsOpenDialog: setIsOpenDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
