import PartialCell from "../tables/WaxTable/PartialCell";

const sortable = true;
const isDefault = true;
export const DISPLAY_FIELDS = [
  {
    id: "startDate",
    label: "Start Date",
    sortable,

    isDefault,

    value: "startDate",
    isDate: true,
  },
  {
    id: "partName",
    label: "Part Name",

    isDefault,

    variant: "blueBold",
    value: "partName",
    navigate: true,
    route: "parts",
    routeId: "partId",
  },
  {
    id: "partNumber",
    label: "Part Number",

    variant: "blueBold",
    value: "partNumber",
    navigate: true,
    route: "parts",
    routeId: "partId",
  },
  {
    id: "workOrder",
    label: "WO #",
    sortable,

    isDefault,

    value: "workOrder",
    variant: "blueBold",
  },
  {
    id: "order",
    label: "Order",

    value: "departmentOrder",
    defaultValue: 1,
  },
  {
    id: "material",
    label: "Material",

    value: "material.name",
  },
  {
    id: "lotNumber",
    label: "Lot",

    isDefault,

    value: "lotNumber",
  },
  {
    id: "available",
    label: "Available",

    isDefault,

    value: "available",
    defaultValue: 0,
    CustomComponent: PartialCell,
  },
  {
    id: "remaining",
    label: "Remaining",

    isDefault,

    value: "remaining",
    defaultValue: 0,
  },
  {
    id: "startTime",
    label: "Start Time",

    value: "actualStartDate",
    isDateTime: true,
  },
];

export const DEFAULT_FIELDS = DISPLAY_FIELDS?.filter((f) => f.isDefault)?.map(
  (f) => f.id
);
