import { Stack } from "@mui/material";
import JsBarcode from "jsbarcode";
import { useEffect, useRef } from "react";
import { formatBarcodeValue } from "./helper";

export const Barcode = ({
  value = "No Value",
  format = "CODE128",
  height = 60,
  ...rest
}) => {
  const svgRef = useRef();

  useEffect(() => {
    const formatted = formatBarcodeValue(value || "No Value", format);

    const chars = formatted.length;
    const containerWidth = svgRef.current.parentElement.offsetWidth;
    const barWidth = containerWidth / (chars * 11);

    // const svg = svgRef.current;
    // const rect = svg.querySelector("rect");

    // let width = containerWidth;
    // if (rect) {
    //   const bbox = rect.getBBox(); // logical dimensions
    //   const svgBBox = svg.getBoundingClientRect(); // rendered size

    //   const viewBox = svg.viewBox.baseVal;

    //   // Calculate scale factor between SVG units and actual screen pixels
    //   const scaleX = svgBBox.width / viewBox.width;

    //   // Final rendered width in screen pixels
    //   width = bbox.width * scaleX;
    // }

    // const percentage = width / containerWidth + 1;
    // const ratio = Math.min(percentage, 2);

    // console.log({ ratio, rect });

    JsBarcode(svgRef.current, formatted, {
      format,
      displayValue: true,
      fontSize: 24,
      width: barWidth * 1,
      ...rest,
    });
  }, [value, format, height, rest]);

  let scale = 1;
  if (height >= 75) scale = 0.75;
  if (height >= 100) scale = 0.6;

  return (
    <Stack
      sx={{
        width: "100%",
        height: `${height * scale}px`,
        alignItems: "center",
        svg: { height: `${height}px` },
      }}
    >
      <svg ref={svgRef} />
    </Stack>
  );
};

export default Barcode;
