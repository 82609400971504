import React from "react";

import { HubTableTemplate } from "../../components";
import { DISPLAY_FIELDS } from "../../constants";

import DisplayTableContainer from "./DisplayTableContainer";
import { condition } from "./helper";
import EmptyTable from "./EmptyTable";

export const StationDisplayTable = ({
  table = {},
  fields = [],
  setWorkOrder = () => {},
  DisplayProps = {},
}) => {
  const onClick = ({ workOrder, rid }) => {
    setWorkOrder({ workOrder, rid });
  };

  const filtered = DISPLAY_FIELDS?.filter((f) => fields.includes(f.id));
  const keys = filtered?.map(({ label, sortable, value, ...rest }) =>
    value === "workOrder" ? { ...rest, value, onClick } : { ...rest, value }
  );
  const items = filtered?.map(({ id, label, sortable }) => ({
    id,
    label,
    sortable,
  }));

  const headerProps = { items, includeCheckbox: false, includeEnd: false };
  const bodyProps = { keys, condition };

  return (
    <DisplayTableContainer
      title={table.title}
      count={table.count}
      {...DisplayProps}
    >
      {table?.count > 0 ? (
        <HubTableTemplate
          id={`${table.title}DisplayTable`}
          headerProps={headerProps}
          bodyProps={{ ...bodyProps, items: table.rows }}
        />
      ) : (
        <EmptyTable title={table.title} />
      )}
    </DisplayTableContainer>
  );
};

export default StationDisplayTable;
