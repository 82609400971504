import { styled } from "@mui/material/styles";
import { HubCardStack } from "../../components";
import { Box, CardMedia, Stack } from "@mui/material";

export const StyledCard = styled(Stack)({
  padding: "4px",
  cursor: "pointer",
  position: "relative",
  boxShadow: "none",
});

export const StyledCardStack = styled(HubCardStack)(({ theme }) => ({
  gap: "10px",
  padding: "10px",
  backgroundColor: theme.palette.hubColors.greyLightest,
  borderRadius: "5px",
  transition: "all 0.1s ease-in-out",
  "&:hover": {
    backgroundColor: theme.palette.hubColors.greyLighter,
  },
}));

export const StyleDocStack = styled(Stack)({
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gap: "2px",
});

export const StyledBox = styled(Box)({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height: "200px",
});

export const StyledCardMedia = styled(Box)({
  width: "100%",
  height: "auto",
});
