import React from "react";
import { Stack, Typography } from "@mui/material";

import { HubCardStack } from "../HubCardStack";
import DataRateChange from "./DataRateChange";

export const DataRateContainer = ({
  label,
  value,
  rate,
  hideRate = false,
  rateProps = {},
  ...rest
}) => (
  <HubCardStack {...rest}>
    <Typography variant="body2" sx={{ color: "hubColors.mainFocus" }}>
      {label}
    </Typography>
    <Stack direction="row" gap="10px" alignItems="center">
      <Typography variant="h3" sx={{ fontWeight: 800, fontSize: 22 }}>
        {value}
      </Typography>
    </Stack>
    {!hideRate && <DataRateChange rate={rate} {...rateProps} />}
  </HubCardStack>
);
