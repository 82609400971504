import { FOUNDRIES } from "../../constants";

export const formOrderMapper = (
  id,
  fd = {},
  customer = {},
  parts = [],
  num = 1
) => {
  const items = [];

  const location =
    fd?.location || FOUNDRIES?.find((f) => customer?.location === f?.label)?.id;

  while (num >= 0) {
    const partId = fd[`part${num}`];
    const price = fd[`price${num}`];
    const qtyReq = fd[`qty${num}`];

    const part = parts.find((p) => p.id === partId);

    const margin = price
      ? (price * part?.margin) / part?.lastSentPrice
      : part?.margin;

    const trees = Math.ceil(qtyReq / part?.partsPerTree);
    const patReq = trees * part?.partsPerTree;

    const itemId = fd[`id${num}`];

    items.push({
      id: itemId ? parseInt(itemId) : null,
      lineItem: fd[`line${num}`],
      partId,
      price,
      margin,
      qtyReq,
      trees,
      patReq,
      woNumber: fd[`workOrder${num}`],
      lotNum: fd[`lotNumber${num}`],
      startDate: new Date(fd[`start${num}`]).toISOString(),
      dueDate: new Date(fd[`due${num}`]).toISOString(),
    });
    num--;
  }

  const payload = {
    id,
    companyId: customer?.id,
    location,
    customerPO: fd?.orderNumber,
    overShippingAllowed: fd?.overShipping,
    shipVia: fd?.shipVia,
    shipTo: fd?.shipTo,
    freight: fd?.freight,
    items,
  };

  return payload;
};

export const itemMapper = (i) => ({
  customerLine: i?.customerLine, // string
  partId: i?.partId, // integer
  quantity: i?.quantity, // integer > 0
  price: i?.price, // decimal
  margin: i?.margin, // decimal
  locationId: i?.locationId, // integer
  dueDate: new Date(i?.dueDate).toISOString(), // date
});

export const updatedMapper = (items = []) =>
  items?.map((i) => ({
    id: i?.id,
    ...itemMapper(i),
  }));

export const newItemsMapper = (newItems) =>
  newItems?.filter((i) => !!i.partId)?.map(itemMapper);

export const updateWorkOrderMapper = (works = []) =>
  works?.map((work) => ({
    id: work.id,
    lotNumber: work.lotNumber,
    quantity: work.quantity,
    trees: work.trees,
    startDate: new Date(work.startDate).toISOString(),
    endDate: new Date(work.endDate).toISOString(),
  }));


export const getOrderDefaultData = (data) => ({
  poNumber: data?.poNumber,
  location: data?.location,
  overShipping: data?.overShipping,
  freight: data?.freight,
  shipVia: data?.shipVia,
  shipTo: data?.shipTo,
  lineItems: data?.lineItems?.map(itemMapper)
})