import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export const condition = (item, isLast) => {
  const label = `& .MuiTableCell-root:${
    isLast ? "last-of-type" : "first-of-type"
  }`;
  const isBehind = dayjs(item.startDate).add(1, "day") < dayjs();
  const behindLabel = `& .MuiTableCell-root${
    isBehind ? ":not(:first-of-type)" : ""
  }`;

  return {
    sx: {
      "&.MuiTableRow-root": {
        ...(isBehind
          ? {
              "& .MuiTableCell-root:first-of-type": {
                backgroundColor: "rgba(239, 55, 55, 0.9)",
                color: "hubColors.fontWhite",
              },
            }
          : {}),
        ...(item?.isStarted
          ? {
              backgroundColor: "rgba(223, 158, 68, 0.1)",
            }
          : {}),
      },
      ...(isBehind
        ? {
            "&.MuiTableRow-root:hover": {
              [label]: {
                backgroundColor: "rgba(239, 55, 55, 1) !important",
                color: "hubColors.fontWhite",
              },
            },
          }
        : {}),
    },
  };
};
