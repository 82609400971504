import React from "react";

import { HubButton, HubTableHeader, InputLabel } from "../../components";
import { StyledStack } from "./styles";
import { TableCell, TableHead, TableRow } from "@mui/material";

const headCells = [
  {
    id: "expand",
    label: "Expand All",
  },
  {
    id: "routing",
    label: "Routing",
    required: true,
  },
];

export const RoutingTableHeader = ({
  expandAll = false,
  setExpandAll = () => {},
}) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell colSpan={3} sx={{ padding: "0px !important" }}>
          <StyledStack>
            <InputLabel label="Routing" required disableMargin />
            <HubButton
              variant="text"
              color="neutral"
              label={expandAll ? "Collapse All" : "Expand All"}
              size="xsmall"
              onClick={() => setExpandAll(!expandAll)}
            />
          </StyledStack>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default RoutingTableHeader;
