import React from "react";
import { UserDetailIcon } from '../';
import { stringToAlphabetSum } from "../../helpers/userImageColor";

const UserChip = ({ contact, ...rest }) => {
  if (!contact?.name) return <></>;

  const { name = "", email = "" } = contact;
  return name || email ? (
    <UserDetailIcon
      user={{
        name,
        email,
        hueShift: stringToAlphabetSum(
          email && name ? email + name : name || email
        ),
      }}
      AvatarInitialProps={{ sx: { fontSize: 10 } }}
      includeEmail={email}
      includeName={name}
      size={25}
      variant="rounded"
      {...rest}
    />
  ) : (
    <></>
  );
};

export default React.memo(UserChip);
