export const StepperActive = ({ fill, ...rest }) => {
    return (
      <svg
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <circle
          cx="16"
          cy="16.5"
          r="15"
          stroke={fill || "rgb(223, 158, 68)"}
          strokeWidth="2"
        />
        <circle cx="16" cy="16.5" r="5" fill={fill || "rgb(223, 158, 68)"} />
      </svg>
    );
  };
  export const StepperDormant = ({ fill, ...rest }) => {
    return (
      <svg
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <circle
          cx="16"
          cy="16.5"
          r="15"
          stroke={fill || "#C0C0C0"}
          strokeWidth="2"
        />
      </svg>
    );
  };
  export const StepperComplete = ({ fill, ...rest }) => {
    return (
      <svg
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <circle cx="16" cy="16.5" r="16" fill={fill || "rgb(27, 131, 253)"} />
        <path
          d="M10.1666 17.3333L13.5 20.6666L21.8333 12.3333"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };