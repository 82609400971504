import React from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import {
  addCustomerAddress,
  getCustomerDataAddresses,
  removeCustomerAddress,
} from "../../redux/customerData";
import { HubAccordian, HubButton, HubIcon } from "../../components";

import CustomerDetails from "./CustomerDetails";
import CustomerAddress from "./CustomerAddress";
import CustomerDefaults from "./CustomerDefaults";

const getUseLabel = ({
  street = "",
  city = "",
  state = "",
  country = "",
  zip = "",
}) => street && city && state && country && zip;

const AccordianLabel = ({ address, useLabel, onDelete = () => {} }) => {
  const addressString = `${address?.street}, ${address?.city} ${address?.state}, ${address?.country} ${address?.zip}`;

  const type =
    address.billing && address.shipping
      ? "Main"
      : address.billing
      ? "Billing"
      : address.shipping
      ? "Shipping"
      : "";

  return (
    <Stack
      direction="row"
      gap="15px"
      alignItems="center"
      justifyContent="space-between"
      flexGrow={1}
    >
      <Stack direction="row" gap="15px" alignItems="center">
        <HubIcon
          icon="MapPin"
          size={14}
          sx={{ color: "hubColors.mainFocus" }}
        />
        <Stack>
          <Typography variant="boldCaption">{type} Address</Typography>
          <Typography variant="caption">
            {useLabel ? addressString : "Add New Address"}
          </Typography>
        </Stack>
      </Stack>
      <HubIcon icon="Trash" size={12} onClick={onDelete} />
    </Stack>
  );
};

const CustomerFormContainer = ({
  isLoading = false,
  addresses = [],
  _add = () => {},
  _remove = () => {},
}) => {
  const handleRemove = (e, i) => {
    e.preventDefault();
    e.stopPropagation();
    _remove(i);
  };

  const filteredAddress = addresses?.filter((a) => !a?.deleted);

  return (
    <Stack gap="25px" padding="35px">
      <CustomerDefaults disabled={isLoading} />
      <Divider orientation="horizontal" flexItem />
      <CustomerDetails disabled={isLoading} />
      <Divider orientation="horizontal" flexItem />
      <Stack gap="15px">
        {filteredAddress?.map((address, i) => {
          const useLabel = getUseLabel(address);
          const label = (
            <AccordianLabel
              address={address}
              useLabel={useLabel}
              onDelete={(e) => handleRemove(e, i)}
            />
          );

          return (
            <HubAccordian label={label} defaultOpen={!useLabel}>
              <CustomerAddress idx={i} address={address} disabled={isLoading} />
            </HubAccordian>
          );
        })}
      </Stack>
      <HubButton
        variant="text"
        size="small"
        label="Add Address"
        sx={{ ml: "auto" }}
        onClick={_add}
      />
      <HubButton
        variant="contained"
        size="small"
        type="submit"
        label="Update Customer Profile"
        isLoading={isLoading}
        disabled={isLoading}
      />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  addresses: getCustomerDataAddresses(state),
});

const mapDispatchToProps = {
  _add: addCustomerAddress,
  _remove: removeCustomerAddress,
};

export const CustomerDetailsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerFormContainer);
