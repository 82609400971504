import React, { useEffect } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { usePostCreatePart } from "../../hooks";

import {
  HubCancelSubmitGroup,
  HubDialogContent,
  HubHookFormWrapper,
} from "../../components";
import { getCustomerData } from "../../redux/customerData";

import { CreateCustomerPartFrom } from "./CreateCustomerPartFrom";
import CreateCustomerPartTemplate from "./CreateCustomerPartTemplate";
import { useDialogContext } from "../HubDialogWrapper";

const CreateCustomerPartContent = ({
  requestCustomer = false,
  customer = {},
}) => {
  const navigate = useNavigate();
  const {
    mutate: postCreatePart,
    isLoading,
    isSuccess,
    data,
  } = usePostCreatePart();

  const { onClose = () => {} } = useDialogContext();

  useEffect(() => {
    if (isSuccess && data?.id) {
      onClose();
      navigate(`/parts/${data?.id}`);
    }
  }, [isSuccess]);

  const onSubmit = (formData) => {
    const payload = {
      customerId: requestCustomer ? formData.customerId : customer.id,
      partNumber: formData.partNumber,
      partName: formData.partName,
      templateId: formData.templateId,
    };
    postCreatePart(payload);
  };
  return (
    <HubDialogContent largePadding>
      <HubHookFormWrapper onSubmit={onSubmit}>
        <Stack gap="20px" alignItems="center">
          <Stack alignItems="center">
            <Typography variant="h6">Create a New Part</Typography>
            <Typography variant="caption">
              Create new part from scratch or from a template
            </Typography>
          </Stack>
          <CreateCustomerPartFrom requestCustomer={requestCustomer} />
          <Divider orientation="horizontal" flexItem />
          <CreateCustomerPartTemplate />
          <HubCancelSubmitGroup
            disabled={isLoading}
            isLoading={isLoading}
            label="Create Part"
          />
        </Stack>
      </HubHookFormWrapper>
    </HubDialogContent>
  );
};

const mapStateToProps = (state) => ({
  customer: getCustomerData(state),
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCustomerPartContent);
