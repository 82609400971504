import qs from "qs-lite";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../utils/api";
import { useHubSnackBar } from "../useSnackBar";

const WORKS = "/works";
const ORDERS = "/orders";

const getWorkOrders = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${WORKS}/admin?${queryString}`);
};
export const useGetWorkOrders = () => {
  const query = new URLSearchParams(window.location.search);

  const location = query.get("location");

  const orderBy = query.get("orderBy") || "startDate";
  const sortOrder = query.get("sortOrder") || "desc";
  const limit = query.get("limit") || 100;
  const offset = query.get("offset") || 0;

  const queryProps = {
    location,
    limit,
    offset,
    orderBy,
    sortOrder,
  };

  return useQuery([WORKS, { ...queryProps }], getWorkOrders, {
    retry: false,
  });
};

const getWorkOrderRouting = ({ queryKey }) => {
  const [path] = queryKey;
  return api.get(path);
};
export const useGetWorkOrderRouting = (workOrder) => {
  return useQuery([`${WORKS}/routing/${workOrder}`], getWorkOrderRouting, {
    retry: false,
    enabled: !!workOrder,
  });
};

const getWorkOrderByOrderId = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${WORKS}/order/${queryKey[2]}?${queryString}`);
};
export const useGetWorkOrderByOrderId = (id) => {
  const query = new URLSearchParams(window.location.search);

  const orderBy = query.get("orderBy") || "workOrder";
  const sortOrder = query.get("sortOrder") || "desc";
  const limit = query.get("limit") || 25;
  const offset = query.get("offset") || 0;

  const queryProps = {
    limit,
    offset,
    orderBy,
    sortOrder,
  };

  return useQuery([WORKS, queryProps, id], getWorkOrderByOrderId, {
    retry: false,
    enabled: !!id,
  });
};

const getWorkOrderLaborData = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${WORKS}/labor?${queryString}`);
};
export const useSearchWorkOrders = () => {
  const query = new URLSearchParams(window.location.search);

  const location = 1;
  const department = query.get("department");
  const work = query.get("work");

  const orderBy = query.get("orderBy") || "plannedStartDate";
  const sortOrder = query.get("sortOrder") || "asc";
  const limit = query.get("limit") || 100;
  const offset = query.get("offset") || 0;

  const queryProps = {
    location,
    limit,
    offset,
    orderBy,
    sortOrder,
    ...(department ? { department } : {}),
    ...(work ? { work } : {}),
  };

  return useQuery([WORKS, { ...queryProps }], getWorkOrderLaborData, {
    retry: false,
  });
};

export const getWorkOrderData = async ({ queryKey }) => {
  const [path] = queryKey;
  return api.get(path);
};

export const useGetWorkOrderData = (id) => {
  return useQuery([`${WORKS}/${id}`], getWorkOrderData, {
    enabled: !!id,
    staleTime: 300000, // Five Minutes
  });
};

const putAdminUpdateWorkOrder = (payload) => {
  const id = payload.id;
  delete payload.id;
  return api.put(`${WORKS}/${id}`, payload);
};
export const usePutAdminUpdateWorkOrder = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(putAdminUpdateWorkOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === WORKS,
        exact: false,
      });
      addSnackbarSuccess({ message: "Successfully Updated Work Order" });
    },
  });
};

const putAdminCloseWorkOrder = (id) => api.put(`${WORKS}/close/${id}`);
export const usePutAdminCloseWorkOrder = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(putAdminCloseWorkOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === WORKS,
        exact: false,
      });
      addSnackbarSuccess({ message: "Successfully Closed Work Order" });
    },
  });
};

///batch-create/line/:id
const postWorkOrderBatchesForLine = (data) =>
  api.post(`${WORKS}/batch-create/line/${data.lineId}`, data);
export const usePostWorkOrderBatchesForLine = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(postWorkOrderBatchesForLine, {
    onSuccess: () => {
      queryClient.invalidateQueries(`${ORDERS}/lines/works`);
      addSnackbarSuccess({ message: "Successfully Created Work Orders" });
    },
  });
};
