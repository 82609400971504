import React, { useEffect } from "react";
import { Stack, Box } from "@mui/material";

import { useDisplaysContext } from "../../pages/Displays/DisplayContextProvider";
import CreateDisplayForm from "../CreateDisplayDialog/CreateDisplayForm";

import { HubButton, HubHookFormWrapper, HubToolbar } from "../../components";
import {
  useGetStationDisplayData,
  usePutStationDisplayData,
} from "../../hooks";

import { StyledDrawer } from "./styles";

const EditDisplayDialog = () => {
  const { openEdit: id, setOpenEdit } = useDisplaysContext();

  const { data, isLoading } = useGetStationDisplayData(id);
  const { mutate, isSuccess } = usePutStationDisplayData(id);

  const onClose = () => setOpenEdit(null);

  useEffect(() => {
    isSuccess && onClose();
  }, [isSuccess]);

  const onSubmit = (formData) => {
    const payload = {
      id,
      title: formData.title,
      locationId: formData.locationId,
      stationIds: formData.stationIds.map((s) => s.id),
      fields: formData.fields.map((f) => f.id),
    };
    mutate(payload);
  };

  return (
    <StyledDrawer anchor="left" open={!!id} onClose={onClose}>
      <Stack overflow="hidden">
        <HubToolbar
          label="Edit Display"
          subtitle="Update Display Stations, Fields, Title, etc."
          variant="transparent"
          onClose={onClose}
        />
        <Box
          sx={{
            height: "100%",
            overflowY: "auto",
            padding: "0 0 25px 0",
            "&::-webkit-scrollbar": { display: "none !important" },
          }}
        >
          <HubHookFormWrapper onSubmit={onSubmit}>
            <Stack padding="25px" gap="25px">
              <CreateDisplayForm data={data} disabled={isLoading} />
              <HubButton
                variant="contained"
                size="xsmall"
                type="submit"
                label="Update Display"
                disabled={isLoading}
              />
            </Stack>
          </HubHookFormWrapper>
        </Box>
      </Stack>
    </StyledDrawer>
  );
};

export default EditDisplayDialog;
