import React from "react";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";

import OrderTableSkeleton from "../../tables/OrderLineItemsTableV2/OrderTableSkeleton";
import { useGetCustomerParts } from "../../hooks";
import { CustomerPartsTable } from "../../tables";
import { CreateCustomerPartDialog } from "../../dialogs";

import CustomerPartsBar from "./CustomerPartsBar";
import CustomerPartsEmpty from "./CustomerPartsEmpty";

export const CustomerParts = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetCustomerParts(id);
  const count = data?.length;

  if(isLoading) <OrderTableSkeleton />
  return (
    <>
      {count ? (
        <Stack gap="25px">
          <CustomerPartsBar count={count} />
          <CustomerPartsTable data={data} />
        </Stack>
      ) : (
        <CustomerPartsEmpty />
      )}
      <CreateCustomerPartDialog />
    </>
  );
};

export default CustomerParts;
