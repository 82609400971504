import React, { useState } from "react";
import { Stack } from "@mui/material";

import { PageContent } from "../../components";
import OrganizationNav from "./OrganizationNav";
import { tabs } from "./helper";

export const OrganizationSettings = () => {
  const [value, setValue] = useState(1);
  const ActiveTab = tabs.find((t) => t.id === value)?.TabElement || <></>;

  return (
    <PageContent disablePadding>
      <Stack direction="row" height="100%" overflow="hidden">
        <OrganizationNav {...{ value, setValue }} />
        {ActiveTab}
      </Stack>
    </PageContent>
  );
};

export default OrganizationSettings;
