import React from "react";

export const Shop = () => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        d="M20.208 5.79048L20.2083 5.79097C20.2519 5.86985 20.2825 5.97732 20.2825 6.09673C20.2825 6.26848 20.224 6.39582 20.1663 6.4653C20.1311 6.50774 20.1068 6.51913 20.098 6.52173H1.89883C1.89002 6.51907 1.86596 6.50763 1.83108 6.46553C1.7735 6.39603 1.71429 6.26764 1.71429 6.09285C1.71429 5.98084 1.74324 5.8714 1.79059 5.78341L4.21836 1.40013L4.21836 1.40013L4.21931 1.39842C4.35998 1.14327 4.5352 1.08694 4.62608 1.08694H17.3739C17.4668 1.08694 17.6394 1.14206 17.7807 1.39842L17.7812 1.39931L20.208 5.79048ZM20.0944 6.52235C20.0943 6.52221 20.0955 6.52188 20.0979 6.52177C20.0956 6.52243 20.0944 6.52249 20.0944 6.52235ZM1.90255 6.52238C1.90252 6.52252 1.9013 6.52246 1.89897 6.52177C1.90141 6.52189 1.90258 6.52223 1.90255 6.52238ZM11 15.9565H12V14.9565V9.76086H12.0572V14.9565V18.0543C12.0572 18.3465 11.9582 18.5822 11.8357 18.7298C11.7149 18.8753 11.5966 18.913 11.5143 18.913H4.31429C4.23196 18.913 4.11371 18.8753 3.9929 18.7298C3.87038 18.5822 3.77144 18.3465 3.77144 18.0543V14.9565V9.76086H3.82858V14.9565V15.9565H4.82858H11ZM18.1714 18.6739V9.76086H18.2286V18.6739C18.2286 18.7412 18.2161 18.7958 18.2 18.8371C18.184 18.7958 18.1714 18.7412 18.1714 18.6739Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Shop;
