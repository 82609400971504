import React from "react";
import { Stack } from "@mui/material";

import { PageContent } from "../../components";
import { MaterialsTable, TreesTable } from "../../tables";
import MaterialsBar from "./MaterialsBar";
import { CreateMaterialDialog } from "../../dialogs";

export const TreesContainer = () => {
  return (
    <PageContent gap="10px" smallPadding hideOverflow>
      <MaterialsBar />
      <Stack
        display="grid"
        gridTemplateColumns="1fr 2fr"
        gap="15px"
        height="100%"
        overflow="hidden"
      >
        <TreesTable />
        <MaterialsTable />
      </Stack>
      <CreateMaterialDialog />
    </PageContent>
  );
};

export default TreesContainer;
