const DEPARTMENT_LABELS = [
  "Wax",
  "Dipping",
  "Dewax",
  "Foundry",
  "Finishing",
  "Outside Processing",
  "Inspection",
];

export const DEPARTMENTS = DEPARTMENT_LABELS.map((label, id) => ({
  id: id + 1,
  label,
}));

export const ROUTINGS = [
  {
    id: 1,
    label: "Wax",
    key: "wax",
  },
  {
    id: 2,
    label: "Assembly",
    key: "assembly",
  },
  {
    id: 3,
    label: "Dip",
    key: "dip",
  },
  {
    id: 4,
    label: "Dewax",
    key: "autoclave",
  },
  {
    id: 5,
    label: "Foundry",
    key: "foundry",
  },
  {
    id: 6,
    label: "Post Foundry & Outside Process",
    key: "post",
  },
  {
    id: 7,
    label: "Inspection",
    key: "inspection",
  },
  {
    id: 8,
    label: "Shipping",
    key: "shipping",
  },
];

export const OUTSIDE_ROUTINGS = [
  {
    label: "Charpy Testing",
    id: 1,
  },
  {
    label: "Customs Control",
    id: 2,
  },
  {
    label: "Flange Surface Grinding",
    id: 3,
  },
  {
    label: "Grind",
    id: 4,
  },
  {
    label: "Grind Gate Flush",
    id: 5,
  },
  {
    label: "Heat Treat",
    id: 6,
  },
  {
    label: "Machine",
    id: 7,
  },
  {
    label: "MSI Testing",
    id: 8,
  },
  {
    label: "NDT Testing",
    id: 9,
  },
  {
    label: "Surface Grind",
    id: 10,
  },
  {
    label: "Surface Grinding",
    id: 11,
  },
  {
    label: "Surface Grinding | Diversified",
    id: 12,
  },
  {
    label: "X-Ray",
    id: 13,
  },
];

export const ROUTING_OPTIONS = [
  {
    id: 1,
    label: "Injection",
    guardian_id: 101,
    isOutside: false,
  },
  {
    id: 2,
    label: "Assembly",
    guardian_id: 102,
    isOutside: false,
  },
  {
    id: 3,
    label: "Etching",
    guardian_id: 103,
    isOutside: false,
  },
  {
    id: 4,
    label: "Dipping",
    guardian_id: 201,
    isOutside: false,
  },
  {
    id: 5,
    label: "Dipping | By Hand",
    guardian_id: 202,
    isOutside: false,
  },
  {
    id: 6,
    label: "Autoclave",
    guardian_id: 301,
    isOutside: false,
  },
  {
    id: 7,
    label: "Pouring",
    guardian_id: 401,
    isOutside: false,
  },
  {
    id: 8,
    label: "Cutoff - Robot",
    guardian_id: 501,
    isOutside: false,
  },
  {
    id: 9,
    label: "Cutoff - Chop | Friction",
    guardian_id: 502,
    isOutside: false,
  },
  {
    id: 10,
    label: "Blast - Spinner Hanger",
    guardian_id: 503,
    isOutside: false,
  },
  {
    id: 11,
    label: "Blast - Tumble",
    guardian_id: 504,
    isOutside: false,
  },
  {
    id: 12,
    label: "Welding",
    guardian_id: 505,
    isOutside: false,
  },
  {
    id: 13,
    label: "Grinding",
    guardian_id: 506,
    isOutside: false,
  },
  {
    id: 14,
    label: "Detailing",
    guardian_id: 507,
    isOutside: false,
  },
  {
    id: 15,
    label: "Heat Treat",
    guardian_id: 508,
    isOutside: false,
  },
  {
    id: 16,
    label: "Straightening",
    guardian_id: 509,
    isOutside: false,
  },
  {
    id: 17,
    label: "Blast - Sand Blasting",
    guardian_id: 535,
    isOutside: false,
  },
  {
    id: 18,
    label: "Inspection",
    guardian_id: 701,
    isOutside: false,
  },
  {
    id: 19,
    label: "Drilling & Tapping",
    guardian_id: 225,
    isOutside: false,
  },
  {
    id: 20,
    label: "Charpy Testing",
    guardian_id: "",
    reqPO: true,
    isOutside: true,
  },
  {
    id: 21,
    label: "Customs Control",
    guardian_id: "",
    isOutside: true,
  },
  {
    id: 22,
    label: "Flange Surface Grinding",
    guardian_id: "",
    isOutside: true,
  },
  {
    id: 23,
    label: "Grind",
    guardian_id: "",
    isOutside: true,
  },
  {
    id: 24,
    label: "Grind Gate Flush",
    guardian_id: "",
    isOutside: true,
  },
  {
    id: 25,
    label: "Heat Treat",
    guardian_id: "",
    isOutside: true,
    reqPO: true,
    reqRec: true,
  },
  {
    id: 26,
    label: "Machine",
    guardian_id: "",
    isOutside: true,
    reqPO: true,
    reqRec: true,
  },
  {
    id: 27,
    label: "MSI Testing",
    guardian_id: "",
    reqPO: true,
    isOutside: true,
  },
  {
    id: 28,
    label: "NDT Testing",
    guardian_id: "",
    reqPO: true,
    isOutside: true,
  },
  {
    id: 29,
    label: "Surface Grind",
    guardian_id: "",
    isOutside: true,
  },
  {
    id: 30,
    label: "Surface Grinding",
    guardian_id: "",
    isOutside: true,
  },
  {
    id: 31,
    label: "Surface Grinding | Diversified",
    guardian_id: "",
    isOutside: true,
  },
  {
    id: 32,
    label: "X-Ray",
    guardian_id: "",
    isOutside: true,
  },
];

export const STATION_OPTIONS = [
  {
    id: 1,
    departmentId: 1,
    label: "Disk Injection",
  },
  {
    id: 2,
    departmentId: 1,
    label: "Wax Injection",
  },
  {
    id: 3,
    departmentId: 1,
    label: "Assembly",
  },
  {
    id: 4,
    departmentId: 1,
    label: "Soluble Injection",
  },
  {
    id: 5,
    departmentId: 1,
    label: "Leaching",
  },
  {
    id: 6,
    departmentId: 2,
    label: "Etching",
  },
  {
    id: 7,
    departmentId: 2,
    label: "Dipping | Robot Primary",
  },
  {
    id: 8,
    departmentId: 2,
    label: "Dipping | Robot Secondary",
  },
  {
    id: 9,
    departmentId: 2,
    label: "Dipping | Hand Dipping",
  },
  {
    id: 10,
    departmentId: 3,
    label: "Autoclave",
  },
  {
    id: 11,
    departmentId: 4,
    label: "Foundry",
  },
  {
    id: 12,
    departmentId: 5,
    label: "Cutoff - Robot",
  },
  {
    id: 13,
    departmentId: 5,
    label: "Cutoff",
  },
  {
    id: 14,
    departmentId: 5,
    label: "Cutoff - Friction Saw",
  },
  {
    id: 15,
    departmentId: 5,
    label: "Cleanup",
  },
  {
    id: 16,
    departmentId: 5,
    label: "Reblast",
  },
  {
    id: 17,
    departmentId: 5,
    label: "Blast - Tumble",
  },
  {
    id: 18,
    departmentId: 5,
    label: "Blast - Glass Bead",
  },
  {
    id: 19,
    departmentId: 5,
    label: "Welding",
  },
  {
    id: 20,
    departmentId: 5,
    label: "Gate Removal",
  },
  {
    id: 21,
    departmentId: 5,
    label: "Detailing",
  },
  {
    id: 22,
    departmentId: 5,
    label: "Heat Treat",
  },
  {
    id: 23,
    departmentId: 5,
    label: "Straightening",
  },
  {
    id: 24,
    departmentId: 5,
    label: "Drilling & Tapping",
  },
  {
    id: 25,
    departmentId: 6,
    label: "3rd Party Heat Treat",
  },
  {
    id: 26,
    departmentId: 6,
    label: "3rd Party Surface Grinding",
  },
  {
    id: 27,
    departmentId: 6,
    label: "3rd Party Machining",
  },
  {
    id: 28,
    departmentId: 6,
    label: "3rd Party X-Ray",
  },
  {
    id: 29,
    departmentId: 6,
    label: "3rd Party NDT",
  },
  {
    id: 30,
    departmentId: 7,
    label: "Inspection",
  },
  {
    id: 31,
    departmentId: 6,
    label: "Outside Processing",
  },
];
