import React, { useState } from "react";
import { TableBody, TableRow, TableCell, Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";

import HubTableSubRow from "./HubTableSubRow";
import { HubIcon } from "../HubIcon";
import { handleClick, handleEvent, processValue } from "./helper";

const HubTableBody = ({
  items = [],
  keys = [],
  condition = () => {},
  ContextMenu,
  ContextMenuProps,
  RenderElement,
  includeCheckbox,
  includeChevron,
  onRowClick,
  includeEnd,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleNav = (e, route, id) => {
    handleEvent(e);
    navigate(`/${route}/${id}`);
  };

  const getCellProps = (key, item) => ({
    ...(key?.sx ? { sx: key.sx } : {}),
    ...(key?.variant ? { variant: key.variant } : {}),
    ...(key?.onClick
      ? {
          onClick: (e) => handleClick(e, key, item),
          onMouseDown: (e) => handleClick(e, key, item),
        }
      : {}),
    ...(key?.navigate
      ? {
          onClick: (e) => handleNav(e, key.route, item[key?.routeId || key.id]),
          onMouseDown: (e) =>
            handleNav(e, key.route, item[key?.routeId || key.id]),
        }
      : {}),
    ...(key?.isCheckbox ? { padding: "checkbox" } : {}),
  });

  const handleRowClick = (e, item, idx) => {
    onRowClick && onRowClick(e, item);
    RenderElement && setOpen(open === idx ? null : idx);
  };

  const length =
    keys.length +
    (includeCheckbox ? 1 : 0) +
    (includeChevron ? 1 : 0) +
    (includeEnd ? 1 : 0);

  return (
    <TableBody>
      {items?.map((item, index) => {
        return (
          <>
            <TableRow
              key={index}
              {...condition(item)}
              onClick={(e) => handleRowClick(e, item, index)}
            >
              {includeCheckbox && (
                <TableCell variant="small">
                  <Checkbox sx={{ padding: 0 }} />
                </TableCell>
              )}
              {includeChevron && (
                <TableCell variant="small">
                  <HubIcon
                    icon={open === index ? "ChevronDown" : "ChevronRight"}
                    size="12px"
                  />
                </TableCell>
              )}
              {keys.map((key) => (
                <TableCell {...getCellProps(key, item)}>
                  {processValue(item, key)}
                </TableCell>
              ))}
              {ContextMenu && (
                <TableCell padding="checkbox">
                  <ContextMenu item={item} {...ContextMenuProps} />
                </TableCell>
              )}
            </TableRow>
            {RenderElement && (
              <HubTableSubRow
                isOpen={open === index && open !== null}
                item={item}
                length={length}
                RenderElement={RenderElement}
              />
            )}
          </>
        );
      })}
    </TableBody>
  );
};

export default HubTableBody;
