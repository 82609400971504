import { HubIcon } from "../components";

const props = {
  size: 25,
  sx: { "&.MuiSvgIcon-root > svg": { strokeWidth: "1.3 !important" } },
};

export const MATERIAL_TYPES = [
  {
    id: 1,
    label: "Tree",
    icon: () => <HubIcon icon="GitBranch" {...props} />,
    subtitle: "Used for assembling wax parts before dipping",
    flow: "main",
    styles: { height: "144px" },
  },
  {
    id: 2,
    label: "Material",
    icon: () => <HubIcon icon="Codesandbox" {...props} />,
    subtitle: "Materials for pouring, dipping, etc.",
    flow: "blue",
    styles: { height: "144px" },
  },
];

export const MATERIAL_TYPES_DICT = {
  TREE: 1,
  MATERIAL: 2,
};
