import React from "react";
import { Table, TableContainer } from "@mui/material";

import CustomerPartsHeader from "./CustomerPartsHeader";
import CustomerPartsBody from "./CustomerPartsBody";

export const CustomerPartsTable = ({ data }) => {

  return (
    <TableContainer>
      <Table>
        <CustomerPartsHeader />
        <CustomerPartsBody parts={data} />
      </Table>
    </TableContainer>
  );
};

export default CustomerPartsTable;
