import React from "react";

export const FileSolid = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 3C3 1.34531 4.34531 0 6 0H13.5V6C13.5 6.82969 14.1703 7.5 15 7.5H21V21C21 22.6547 19.6547 24 18 24H6C4.34531 24 3 22.6547 3 21V3ZM21 6H15V0L21 6Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FileSolid;
