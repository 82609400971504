import React from "react";
import { useSearchParams } from "react-router-dom";

import DisplayContextMenu from "./DisplayContextMenu";
import { useDisplaysContext } from "../../pages/Displays/DisplayContextProvider";

export const ContextMenu = () => {
  const [searchParams] = useSearchParams();
  const display = searchParams.get("display");

  const { setOpenEdit, setDeletingId } = useDisplaysContext();

  return (
    <DisplayContextMenu
      id={display}
      setOpenEdit={setOpenEdit}
      setDeletingId={setDeletingId}
    />
  );
};

export default ContextMenu;
