import React, { useMemo } from "react";
import { Stack } from "@mui/material";
import { useFormContext } from "react-hook-form";

import { MATERIAL_TYPES, MATERIAL_TYPES_DICT } from "../../constants/materials";
import {
  CustomRadioGroup,
  HubHookFormInput,
  HubHookFormSelect,
  HubHookFormSwitch,
} from "../../components";
import { FOUNDRIES } from "../../constants";

const categories = [
  "SAND",
  "SLURRY",
  "CUP",
  "MRO",
  "CASTINGS",
  "RECIPE",
  "RAW MATERIAL",
];

const CreateMaterialForm = () => {
  const { watch } = useFormContext();
  const type = watch("type");
  const isMaterial = useMemo(
    () => type == MATERIAL_TYPES_DICT.MATERIAL,
    [type]
  );

  return (
    <>
      <CustomRadioGroup
        name="type"
        options={MATERIAL_TYPES}
        defaultValue={MATERIAL_TYPES_DICT.MATERIAL}
      />
      <Stack gap="15px" width="100%">
        <HubHookFormInput name="name" label="Name" required />
        {isMaterial ? (
          <Stack gap="25px" direction="row" alignItems="center">
            <HubHookFormSelect
              name="category"
              label="Category"
              options={categories}
              defaultValue={categories[6]}
              required
            />
            <HubHookFormSwitch
              isCheckbox
              name="remelt"
              label="Remelt"
              defaultValue={true}
              labelPlacement="end"
              required
              fullWidth={false}
              formControlProps={{ sx: { width: "100px" } }}
            />
          </Stack>
        ) : (
          <Stack gap="5px" direction="row">
            <HubHookFormSelect
              name="locationId"
              label="Foundry"
              options={FOUNDRIES}
              required
            />
          </Stack>
        )}
        <HubHookFormInput
          name="weight"
          label="Weight"
          defaultValue={1}
          isDecimal
        />
      </Stack>
    </>
  );
};

export default CreateMaterialForm;
