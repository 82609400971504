import { useMutation, useQuery, useQueryClient } from "react-query";
import qs from "qs-lite";
import api from "../../utils/api";

import { useHubSnackBar } from "../useSnackBar";

const STATIONDISPLAY = "/stationdisplays";

const getAllStationDisplays = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${STATIONDISPLAY}?${queryString}`);
};
export const useGetAllStationDisplays = (locationId) => {
  return useQuery([STATIONDISPLAY, { locationId }], getAllStationDisplays, {
    enabled: !!locationId,
  });
};

const getStationDisplay = (id) => api.get(`${STATIONDISPLAY}/${id}`);
export const useGetStationDisplay = (displayId) => {
  const query = new URLSearchParams(window.location.search);
  const id = query.get("display") || displayId;

  return useQuery([STATIONDISPLAY, id], () => getStationDisplay(id), {
    enabled: !!id,
    refetchInterval: 60000,
  });
};

const getStationDisplayData = (id) => api.get(`${STATIONDISPLAY}/data/${id}`);
export const useGetStationDisplayData = (id) => {
  return useQuery(
    [STATIONDISPLAY, "data", id],
    () => getStationDisplayData(id),
    {
      enabled: !!id,
    }
  );
};

const postCreateStationDisplay = (payload) => api.post(STATIONDISPLAY, payload);
export const usePostCreateStationDisplay = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(postCreateStationDisplay, {
    onSuccess: () => {
      queryClient.invalidateQueries(STATIONDISPLAY);
      addSnackbarSuccess({ message: "Successfully Created Station Display" });
    },
  });
};

const putStationDisplayData = (data) => {
  const id = data.id;
  delete data.id;
  return api.put(`${STATIONDISPLAY}/${id}`, data);
};
export const usePutStationDisplayData = (id) => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(putStationDisplayData, {
    onSuccess: () => {
      queryClient.invalidateQueries([STATIONDISPLAY, id]);
      addSnackbarSuccess({ message: "Successfully Updated Display" });
    },
  });
};

const putStationDisplayLayout = (data) => {
  const id = data.id;
  delete data.id;
  return api.put(`${STATIONDISPLAY}/layout/${id}`, data);
};
export const usePutStationDisplayLayout = (id) => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(putStationDisplayLayout, {
    onSuccess: () => {
      queryClient.invalidateQueries([STATIONDISPLAY, id]);
      addSnackbarSuccess({ message: "Successfully Updated Display" });
    },
  });
};

const deleteStationDisplay = (id) => api.delete(`${STATIONDISPLAY}/${id}`);
export const useDeleteStationDisplay = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(deleteStationDisplay, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === STATIONDISPLAY,
      });
      addSnackbarSuccess({ message: "Successfully Deleted Display" });
    },
  });
};
