import React, { useState } from "react";
import { Stack } from "@mui/material";

import { useGetStationDepartments } from "../../hooks";
import { DataRateContainer, HubCardStack, PageContent } from "../../components";
import { CreateStationDialog } from "../../dialogs";

import OrganizationDepartment from "./OrganizationDepartment";
import OrganizationStationsBar from "./OrganizationStationsBar";
import { connect } from "react-redux";
import { setIsOpenDialog } from "../../redux";

const OrganizationStationsUnwrapped = ({ _setIsOpenDialog = () => {} }) => {
  const { data, isLoading } = useGetStationDepartments();
  const [updatingStation, setUpdatingStation] = useState();

  const handleSetUpdate = (item) => {
    setUpdatingStation(item);
    _setIsOpenDialog("create-station", true);
  };

  return (
    <PageContent smallPadding isLoading={isLoading} gap="15px">
      <Stack direction="row" gap="10px">
        {data?.stats?.map((s) => (
          <DataRateContainer
            label={s?.label}
            value={s?.count}
            rateProps={{ labelOverride: "Active Work Orders" }}
            padding="15px"
          />
        ))}
      </Stack>
      <HubCardStack gap="25px">
        <OrganizationStationsBar />
        <Stack gap="15px">
          {data?.departments?.map(({ label, details, icon, stations }) => (
            <OrganizationDepartment
              {...{ label, details, stations, icon }}
              setUpdatingStation={handleSetUpdate}
            />
          ))}
        </Stack>
      </HubCardStack>
      <CreateStationDialog
        details={updatingStation}
        isUpdating={!!updatingStation}
        onClose={() => setUpdatingStation(false)}
      />
    </PageContent>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  _setIsOpenDialog: setIsOpenDialog,
};
export const OrganizationStations = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationStationsUnwrapped);
