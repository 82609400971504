import React from "react";

import { HubHookFormSelect } from "../HubHookFormSelect";
import { useGetCustomerAddresses } from "../../hooks";

export const HubHookFormCustomerAddress = ({
  name = "addressId",
  label = "Address",
  defaultValue,
  hideLabel = false,
  disabled = false,
  customerId,
  ...rest
}) => {
  const { data, isLoading } = useGetCustomerAddresses(customerId);
  const options = data || [];

  const defaultInput = defaultValue || options[0]?.id;

  return (
    <HubHookFormSelect
      name={name}
      defaultValue={defaultInput}
      disabled={isLoading || disabled}
      options={options}
      disableHelperText
      {...(!hideLabel ? { label } : {})}
      {...rest}
    />
  );
};

export default HubHookFormCustomerAddress;
