import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  FormHelperText,
  MenuItem,
  Select,
  CircularProgress,
  InputAdornment,
} from "@mui/material";

import { InputLabel } from "../InputLabel";
import { StyledFormControl, StyledHubIcon } from "./styles";

const DefaultIcon = ({ ...rest }) => (
  <StyledHubIcon
    icon="ChevronDown"
    sx={{ marginRight: "10px", marginTop: "3px", cursor: "pointer" }}
    {...rest}
  />
);

export const HubSelect = ({
  name = "",
  disabled = false,
  onChange = () => {},
  isLoading = false,
  placeholder = "",
  label = "",
  helperText = "",
  options = [],
  disableHelperText = true,
  smallLabel = true,
  defaultValue = "",
  formControlProps = {},
  hidden = false,
  fullWidth = true,
  allowNull = false,
  multiple = false,
  ...rest
}) => {
  const id = `hub-select-${name}`;
  const [value, setValue] = useState(defaultValue);
  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   setValue(defaultValue);
  // }, [defaultValue]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    onChange(newValue);
    setOpen(false);
  };

  return (
    <StyledFormControl
      hidden={hidden}
      fullWidth={fullWidth}
      variant="outlined"
      {...formControlProps}
    >
      <InputLabel label={label} id={id} smallLabel={smallLabel} />
      <Select
        id={id}
        onChange={handleChange}
        defaultValue={value}
        disabled={disabled}
        size="small"
        placeholder={placeholder}
        IconComponent={DefaultIcon}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        multiple={multiple}
        MenuProps={{
          sx: {
            "& .MuiMenu-paper": {
              maxHeight: "400px",
            },
          },
        }}
        startAdornment={
          isLoading && (
            <InputAdornment position="start">
              <CircularProgress size={24} />
            </InputAdornment>
          )
        }
        {...rest}
      >
        {allowNull && (
          <MenuItem key="null-option" value="">
            <Typography sx={{ fontSize: "1em" }}>None</Typography>
          </MenuItem>
        )}
        {options.map((opt, index) => (
          <MenuItem key={`option-${index}`} value={opt.id ?? opt}>
            <Typography sx={{ fontSize: "1em" }}>{opt.label || opt}</Typography>
          </MenuItem>
        ))}
      </Select>
      {!disableHelperText && helperText && (
        <FormHelperText id={`${id}-helper-text`}>{helperText}</FormHelperText>
      )}
    </StyledFormControl>
  );
};

HubSelect.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  disableHelperText: PropTypes.bool,
  smallLabel: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  formControlProps: PropTypes.object,
  hidden: PropTypes.bool,
  fullWidth: PropTypes.bool,
  allowNull: PropTypes.bool,
  multiple: PropTypes.bool,
};

export default HubSelect;
