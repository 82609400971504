import PartDipDetails from "./forms/PartDipDetails";
import PartWaxDetails from "./forms/PartWaxDetails";
import PartFoundry from "./forms/PartFoundry";
import PartPostFoundry from "./forms/PartPostFoundry";
import PartGeneric from "./forms/PartGeneric";
import PartAssembly from "./forms/PartAssembly";
import PartAutoclave from "./forms/PartAutoclave";
import PartShipping from "./forms/PartShipping";

export const PART_SECTIONS = {
  wax: <PartWaxDetails />,
  assembly: <PartAssembly />,
  dip: <PartDipDetails />,
  autoclave: <PartAutoclave />,
  foundry: <PartFoundry />,
  post: <PartPostFoundry />,
  inspection: <PartGeneric name="Inspection" />,
  shipping: <PartShipping />,
};
