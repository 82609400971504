import React from "react";
import { Stack, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";

import {
  HubHookFormInput,
  HubHookFormTimePicker,
  HubMaterialHookSelect,
} from "../../../components";
import { StyledHubCardStack } from "../styles";

const gap = "10px";
const columnGap = "10px";

const PartAssembly = () => {
  const { getValues } = useFormContext();
  const details = getValues("assembly");

  return (
    <StyledHubCardStack gap="25px">
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">Assembly Details</Typography>
        <Stack gap="25px">
          <Stack direction="row" gap={columnGap}>
            <HubMaterialHookSelect
              type="trees"
              label="Tree Type"
              name="assembly.treeId"
              defaultValue={details?.treeId}
              useDefault
            />
            <HubHookFormInput
              name="assembly.partsPerTree"
              label="Parts / Tree"
              defaultValue={details?.partsPerTree}
              placeholder="Ex. 12"
              required
              isNumber
            />
            <HubHookFormInput
              name="assembly.treesPerCluster"
              label="Trees / Cluster"
              defaultValue={details?.treesPerCluster}
              placeholder="Ex. 12"
              isNumber
            />
            <HubHookFormInput
              name="assembly.setup"
              label="Setup"
              defaultValue={details?.setup}
              placeholder="Setup..."
            />
          </Stack>
          <Stack direction="row" gap={columnGap}>
            <HubHookFormTimePicker
              name="assembly.leadTime"
              label="Lead Time"
              placeholder="Ex. 12"
              defaultValue={details?.leadTime}
              onlyDays
              required
            />
            <HubHookFormTimePicker
              name="assembly.cycleTime"
              label="Cycle Time"
              placeholder="Ex. 0.24"
              defaultValue={details?.cycleTime}
              required
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">Assembly Notes</Typography>
        <HubHookFormInput
          name="assembly.notes"
          label="Special Instructions"
          defaultValue={details?.notes}
          placeholder="Notes..."
          rows={5}
          multiline
        />
        {details?.id && (
          <HubHookFormInput
            name="assembly.id"
            defaultValue={details?.id}
            hidden
          />
        )}
      </Stack>
    </StyledHubCardStack>
  );
};

export default PartAssembly;
