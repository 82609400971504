import React from "react";
import { Stack, Table, TableContainer } from "@mui/material";

import PartShippingHeader from "./PartShippingHeader";
import PartShippingBody from "./PartShippingBody";

export const PartShippingTable = ({ ...rest }) => {
  return (
    <TableContainer>
      <Table>
        <PartShippingHeader />
        <PartShippingBody {...rest} />
      </Table>
    </TableContainer>
  );
};

export default PartShippingTable;
