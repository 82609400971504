import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { DialogButton, HubButton } from "../../components";
import { EmptyOrders } from "../../svgs";

const name = "purchase-order-create";
const CustomerOrdersEmpty = ({ createProps }) => {
  return (
    <Stack gap="25px" margin="auto" alignItems="center">
      <Box component="img" src={EmptyOrders} sx={{ height: "150px" }} />
      <Stack gap="10px" alignItems="center" textAlign="center">
        <Typography variant="boldCaption">No Orders Found</Typography>
        <Typography variant="caption" width="250px">
          Click "Create Order" button to get started in adding this customer's
          new order
        </Typography>
        <Stack direction="row" gap="10px">
          <HubButton
            variant="outlined"
            size="small"
            color="neutral"
            label="Learn More"
          />
          <DialogButton
            name={name}
            label="Create Order"
            variant="contained"
            size="small"
            {...createProps}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CustomerOrdersEmpty;
