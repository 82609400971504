import React from "react";
import { Chip, Stack, Typography } from "@mui/material";

import { DialogButton } from "../../components";

const CustomerOrdersBar = ({ count = 0, name = "", createProps = {} }) => {
  return (
    <Stack
      justifyContent="space-between"
      alignItems="end"
      direction="row"
      gap="15px"
    >
      <Stack direction="row" gap="10px" alignItems="center">
        <Typography variant="boldCaption">Orders</Typography>
        <Chip label={`${count} Orders`} variant="colorTag" />
      </Stack>
      <DialogButton
        name={name}
        label="Create Order"
        variant="contained"
        size="xsmall"
        {...createProps}
      />
    </Stack>
  );
};

export default CustomerOrdersBar;
