import React, { useCallback, useEffect, useMemo } from "react";

import { useGetStationList } from "../../hooks";
import { getCachedItem, setCachedItem } from "../../helpers";

import { HubHookFormTagInput } from "../HubHookFormTagInput";
import { HubHookFormSelect } from "../HubHookFormSelect";
import { useFormContext } from "react-hook-form";

export const StationHookFormSelect = ({
  name = "stationId",
  label = "Station",
  hideLabel = false,
  required = true,
  disabled = false,
  department,
  status,
  customFilter,
  multiple = false,
  defaultValue,
  ...rest
}) => {
  const cachedData = getCachedItem("stations");
  const { data, isLoading, isSuccess } = useGetStationList(!cachedData);

  const { setValue } = useFormContext();

  const stations = cachedData || data || [];

  useEffect(() => {
    if (data && isSuccess) {
      setCachedItem("stations", data);
      defaultValue && setValue(name, defaultValue);
    }
  }, [data]);

  const filterFunction = useCallback(
    customFilter
      ? (o) => customFilter(o)
      : (o) => {
          const departmentMatch = department
            ? o.department.id === department
            : true;
          const statusMatch = status ? o.status === status : true;
          return departmentMatch && statusMatch;
        },
    [customFilter, department, status]
  );

  const filtered =
    customFilter || department || status
      ? stations.filter(filterFunction)
      : stations;

  const FieldComponent = useMemo(
    () => (multiple ? HubHookFormTagInput : HubHookFormSelect),
    [multiple]
  );

  return (
    <FieldComponent
      name={name}
      options={filtered}
      required={required}
      disabled={disabled || isLoading}
      defaultValue={defaultValue}
      MenuProps={{
        sx: {
          "& .MuiMenu-paper": {
            maxHeight: "400px",
          },
          "& .MuiMenu-list": {
            maxHeight: "254px",
            ".MuiPaper-root": {
              marginTop: "0 !important",
              boxShadow: "none !important",
              border: "0 !important",
              borderRadius: "0px !important",
            },
          },
        },
      }}
      {...(!hideLabel ? { label } : {})}
      {...rest}
    />
  );
};

export default StationHookFormSelect;
