import { getDateData } from "../../helpers/dateHelper";
import { stringToAlphabetSum } from "../../helpers/userImageColor";
import { getBarcodeLabels } from "../BarcodeEditorPreview/helper";

export const getCarouselLabels = (barcodes = []) => {
  return barcodes?.map((b) => {
    const labels = getBarcodeLabels({
      fields: b.barcode,
      delimiter: b.delimiter,
      dataObject: b.object,
      serialize: b.serialize,
      topTemplates: b.top,
      bottomTemplates: b.bottom,
      qty: 1,
    });

    const user = {
      hueShift: stringToAlphabetSum(
        `${b.history[0].user.email}${b.history[0].user.name}`
      ),
      name: b.history[0].user.name,
      email: b.history[0].user.email,
    };

    const time = getDateData({
      startDate: b.history[0].createdAt,
      includeYear: true,
    });

    const props = {
      id: b.id,
      name: b.name,
      user,
      time,
      ...labels[0],
      size: b.size,
      barcodeProps: { format: b.format, displayValue: b.label },
    };
    return props;
  });
};
