import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { HubSelect } from "../../components";

export const StyledTitleStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: "25px",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  borderBottom: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
}));

export const StyledSelect = styled(HubSelect)({
  width: 125,
  padding: "5px 10px",
  borderRadius: 6,
  svg: {
    width: 12,
    height: 12,
    margin: "5px 5px 0px 0px",
  },
});

export const StyledDisplayStack = styled(Stack, {
  shouldForwardProp: (prop) => !["barHidden"].includes(prop),
})(({ theme, barHidden }) => ({
  gap: "25px",
  width: "100%",
  height: `calc(100vh - ${barHidden ? "51px" : "98.5px"})`,
  overflow: "auto",
  "& .react-grid-placeholder": {
    opacity: 1,
    border: "1px dashed",
    borderColor: theme.palette.hubColors.mainFocus,
    backgroundColor: theme.palette.hubColors.mainFocusLightest,
    borderRadius: "2px",
  },
}));

export const StyledContentStack = styled(Stack, {
  shouldForwardProp: (prop) => !["barHidden"].includes(prop),
})(({ barHidden }) => ({
  flexDirection: "row",
  width: "100%",
  height: `calc(100vh - ${barHidden ? "51px" : "98.5px"})`,
  overflow: "hidden",
}));

export const StyledContentGrid = styled(Box, {
  shouldForwardProp: (prop) => !["columns", "tables", "nav"].includes(prop),
})(({ columns = 1, tables = 1, nav = false }) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${columns}, 1fr)`,
  padding: "15px",
  gap: "10px",
  width: "100%",
  height: "100%",
  ...(tables <= 4 ? { maxHeight: `calc(100vh - ${nav ? 99.5 : 51}px)` } : {}),

  ".twoGridTable": {
    width: "100%",
    height: "100%",
    gap: "10px",
    maxHeight: `calc(100vh - ${nav ? 129.5 : 81}px)`,
    ...(tables <= 4 ? { overflow: "hidden" } : {}),
  },
}));

export const StyledGridBox = styled(Box, {
  shouldForwardProp: (prop) => !["d", "p"].includes(prop),
})(({ theme, d, p }) => ({
  maxHeight: d?.height * 2 + p,
  maxWidth: d?.width + p,
  overflow: "hidden",
  backgroundColor: theme.palette.hubColors.white,
}));
