import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { connect } from "react-redux";

import { HubButton, HubCircularProgress } from "../../components";
import { useGetDisplayDashboard } from "../../hooks";
import { StyledDisplayStack } from "./styles";

import { CreateLaborDialog } from "../../dialogs";
import { setIsOpenDialog } from "../../redux";

import { ROLES } from "../../constants";
import { handleFullscreen } from "../../helpers/screen";
import { HubChartLabelGroup } from "../../components/HubCharts/HubChartLabel";
import { getTableDisplay } from "./helper";
import { useScannerHelper } from "../../helpers";

const ChartLabels = [
  {
    color: "hubColors.mainFocusLight",
    label: "Started",
  },
  {
    color: "hubColors.redMain",
    label: "Behind Schedule",
  },
];

const WorkOrderTable = ({
  nav,
  role,
  defaultLocation,
  _setIsOpenDialog = () => {},
}) => {
  const [workOrder, setWorkOrder] = useState();
  const { data, isLoading, isSuccess, refetch } = useGetDisplayDashboard({
    labor: false,
    defaultLocation,
  });
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, [search?.toString()]);

  const handleSetOpen = () => {
    _setIsOpenDialog("create-labor-ticket", true);
  };

  useEffect(() => {
    const allowNav = ROLES.Generic !== role;
    if (workOrder)
      nav && allowNav
        ? navigate(`/works/${workOrder.workOrder}`)
        : handleSetOpen();
  }, [workOrder]);

  // Barcode scanning setup
  const onScan = (barcode) => {
    if (!workOrder) setWorkOrder({ workOrder: barcode });
  };
  useScannerHelper(onScan, [workOrder]);

  const divRef = useRef(null);

  const TableDisplay = getTableDisplay(data?.title);

  return isLoading && isSuccess ? (
    <HubCircularProgress centerScreen />
  ) : (
    <StyledDisplayStack ref={divRef} gap="10px">
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap="20px" alignItems="center">
          <Typography>{data?.title || "Display Data"}</Typography>
          <HubChartLabelGroup
            labels={ChartLabels}
            stackProps={{ direction: "row", gap: "10px" }}
            StackProps={{ gap: "5px" }}
            LabelProps={{ variant: "caption" }}
          />
        </Stack>
        <HubButton
          icon="Maximize"
          onClick={() => handleFullscreen(divRef)}
          variant="text"
          color="neutral"
        />
      </Stack>
      <TableDisplay
        setWorkOrder={setWorkOrder}
        defaultLocation={defaultLocation}
        {...(data?.title === "Foundry" ? { includeMaterial: true } : {})}
      />
      <CreateLaborDialog workOrder={workOrder} setWorkOrder={setWorkOrder} />
    </StyledDisplayStack>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  _setIsOpenDialog: setIsOpenDialog,
};

export const WaxTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkOrderTable);

export default WaxTable;
