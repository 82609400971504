import React from "react";
import { Stack, Switch, Typography } from "@mui/material";

import { useDisplaysContext } from "../../pages/Displays/DisplayContextProvider";

export const WorkerView = () => {
  const { nav, setNav } = useDisplaysContext();

  const handleSwitch = () => setNav(!nav);

  return (
    <Stack direction="row" gap="10px">
      <Typography variant="caption">Worker View:</Typography>
      <Switch checked={!nav} onChange={handleSwitch} />
    </Stack>
  );
};

export default WorkerView;
