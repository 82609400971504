import React from "react";
import { Stack, Typography, Box } from "@mui/material";

import { EmptyDisplay } from "../../svgs";

export const EmptyTable = ({ title }) => {
  return (
    <Stack
      width="100%"
      height="100%"
      border="1px solid"
      borderColor="hubColors.greyLight"
    >
      <Stack gap="10px" justifyContent="center" alignItems="center" m="auto">
        <Box component="img" src={EmptyDisplay} width="100px" height="100px" />
        <Stack justifyContent="center" alignItems="center">
          <Typography variant="boldCaption">No Work Orders</Typography>
          <Typography variant="caption">
            No work orders are currently in <strong>{title}</strong>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EmptyTable;
