import React from "react";

export const Tag = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        d="M10.8294 1.00549H10.83C11.4999 1.00549 12.1391 1.26932 12.6083 1.73838L22.2643 11.3921C23.2452 12.3729 23.2452 13.961 22.2643 14.9418L14.94 22.2644C13.9589 23.2452 12.37 23.2452 11.3889 22.2644L1.73297 12.6106C1.2638 12.1415 1 11.5025 1 10.833V2.63284C1 1.73189 1.73148 1.00015 2.63317 1C2.63326 1 2.63335 1 2.63344 1L10.8294 1.00549ZM8.09315 4.19498C7.57635 3.6783 6.87547 3.38807 6.1447 3.38807C5.41394 3.38807 4.71306 3.6783 4.19626 4.19498C3.67945 4.71166 3.38907 5.41249 3.38907 6.1433C3.38907 6.8741 3.67945 7.57493 4.19626 8.09162C4.71306 8.6083 5.41394 8.89853 6.1447 8.89853C6.87547 8.89853 7.57635 8.6083 8.09315 8.09162C8.60995 7.57493 8.90033 6.8741 8.90033 6.1433C8.90033 5.41249 8.60995 4.71166 8.09315 4.19498Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Tag;
