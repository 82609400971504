import React from "react";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import HubCarousel from "../../components/HubCarousel/HubCarousel";
import { useGetAllBarcodes } from "../../hooks";
import { getCarouselLabels } from "./helper";
import {
  BarcodeStickers,
  HubButton,
  HubIcon,
  UserDetailIcon,
} from "../../components";

import { StyledCard, StyledCardStack } from "./styles";
import { HoverInfo } from "../../components/inputs/HubButton/HoverInfo";

export const BarcodesCarousel = () => {
  const navigate = useNavigate();

  const { data, isLoading } = useGetAllBarcodes();

  const barcodes = getCarouselLabels(data);

  const handleClick = (id) => {
    navigate(`/barcodes/${id}`);
  };

  return (
    <Stack gap="5px">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="boldCaption">Barcode Templates</Typography>
        <HubButton
          label="Create New Barcode"
          variant="contained"
          size="xsmall"
          page="/barcodes/new"
        />
      </Stack>
      <HubCarousel length={barcodes?.length}>
        {barcodes?.map(({ id, name, user, time, ...props }) => (
          <StyledCard>
            <StyledCardStack onClick={() => handleClick(id)}>
              <Stack
                direction="row"
                alignItems="center"
                gap="5px"
                padding="0px 1px"
              >
                <HubIcon icon="Barcode" size={14} />
                <Typography
                  variant="caption"
                  fontWeight={500}
                  textAlign="center"
                >
                  {name}
                </Typography>
              </Stack>
              <BarcodeStickers {...props} />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                padding="0px 1px"
              >
                <Stack direction="row" alignItems="center" padding="0px 1px">
                  <HoverInfo hoverTitle={user.name} hoverSubtitle={user.email}>
                    <UserDetailIcon
                      user={user}
                      includeEmail={false}
                      includeName={false}
                      size={14}
                    />
                  </HoverInfo>
                  <Typography variant="caption">Edited - {time}</Typography>
                </Stack>
                <HubIcon icon="MoreVertical" size={12} />
              </Stack>
            </StyledCardStack>
          </StyledCard>
        ))}
      </HubCarousel>
    </Stack>
  );
};

export default BarcodesCarousel;
