import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";

import {
  HubCancelSubmitGroup,
  HubDialogContent,
  HubHookFormWrapper,
} from "../../components";
import { usePostCreateStation, usePutStation } from "../../hooks";
import { useDialogContext } from "../HubDialogWrapper";

import CreateStationForm from "./CreateStationForm";

const CreateStationContent = ({ details, isUpdating = false }) => {
  const {
    mutate: postCreateStation,
    isLoading: isPostLoading,
    isSuccess: isPostSuccess,
  } = usePostCreateStation();
  const {
    mutate: putStation,
    isLoading: isPutLoading,
    isSuccess: isPutSuccess,
  } = usePutStation();

  const { onClose } = useDialogContext();

  const isLoading = isPostLoading || isPutLoading;
  const isSuccess = isPostSuccess || isPutSuccess;

  useEffect(() => {
    isSuccess && onClose();
  }, [isSuccess]);

  const onSubmit = (formData) => {
    const payload = {
      ...(isUpdating
        ? {
            id: details.id,
            archived: formData.archived,
          }
        : {}),
      departmentId: Number(formData.departmentId),
      label: formData.label,
      details: formData?.details || "",
    };
    (isUpdating ? putStation : postCreateStation)(payload);
  };

  return (
    <HubDialogContent largePadding>
      <HubHookFormWrapper onSubmit={onSubmit}>
        <Stack gap="35px" alignItems="center">
          <Stack alignItems="center">
            <Typography variant="h6">
              {isUpdating ? "Update Station" : "Create a New Station"}
            </Typography>
            <Typography variant="caption">
              Use the fields below to create or update a station for any part
              routing
            </Typography>
          </Stack>
          <CreateStationForm details={details} />
          <HubCancelSubmitGroup
            disabled={isLoading}
            isLoading={isLoading}
            label={isUpdating ? "Update Station" : "Create Station"}
          />
        </Stack>
      </HubHookFormWrapper>
    </HubDialogContent>
  );
};

export default CreateStationContent;
