import React, { createContext, useContext, useEffect, useState } from "react";
import { ROLES } from "../../constants";

const DisplaysContext = createContext(null);

export const DisplaysProvider = ({ role, defaultLocation, children }) => {
  const defaultNav = ROLES.Generic !== role;
  const [nav, setNav] = useState(defaultNav);
  const [openEdit, setOpenEdit] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [locationId, setLocationId] = useState(defaultLocation);
  const [layout, setLayout] = useState([]);

  useEffect(() => {
    setLocationId(defaultLocation);
  }, [defaultLocation]);

  return (
    <DisplaysContext.Provider
      value={{
        defaultNav,
        role,
        nav,
        setNav,
        locationId,
        setLocationId,
        openEdit,
        setOpenEdit,
        deletingId,
        setDeletingId,
        layout,
        setLayout,
      }}
    >
      {children}
    </DisplaysContext.Provider>
  );
};

export const useDisplaysContext = () => {
  const context = useContext(DisplaysContext);
  if (!context) {
    throw new Error("useDisplays must be used within a DisplaysProvider");
  }
  const {
    defaultNav,
    role,
    nav,
    setNav,
    locationId,
    setLocationId,
    openEdit,
    setOpenEdit,
    deletingId,
    setDeletingId,
    layout,
    setLayout,
  } = context;

  return {
    defaultNav,
    role,
    nav,
    setNav,
    locationId,
    setLocationId,
    openEdit,
    setOpenEdit,
    deletingId,
    setDeletingId,
    layout,
    setLayout,
  };
};
