import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../utils/api";

import { useHubSnackBar } from "../useSnackBar";

const BARCODE = "/barcodes";

const getAllBarcodes = () => api.get(BARCODE);
export const useGetAllBarcodes = () => {
  return useQuery([BARCODE], getAllBarcodes);
};

const getBarcode = ({ queryKey }) => {
  const [_, id] = queryKey;
  return api.get(`${BARCODE}/${id}`);
};
export const useGetBarcode = (id) => {
  return useQuery([BARCODE, id], getBarcode, {
    enabled: !!id,
  });
};

const postCreateBarcode = (payload) => api.post(BARCODE, payload);
export const usePostCreateBarcode = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(postCreateBarcode, {
    onSuccess: () => {
      queryClient.invalidateQueries(BARCODE);
      addSnackbarSuccess({ message: "Successfully Created Barcode" });
    },
  });
};

const putBarcodeData = (data) => {
  const id = data.id;
  delete data.id;
  return api.put(`${BARCODE}/${id}`, data);
};
export const usePutBarcodeData = (id) => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(putBarcodeData, {
    onSuccess: () => {
      queryClient.invalidateQueries([BARCODE, id]);
      addSnackbarSuccess({ message: "Successfully Updated Display" });
    },
  });
};

const deleteBarcode = (id) => api.delete(`${BARCODE}/${id}`);
export const useDeleteBarcode = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(deleteBarcode, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === BARCODE,
      });
      addSnackbarSuccess({ message: "Successfully Deleted Display" });
    },
  });
};
