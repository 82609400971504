import React from "react";
import { TableBody } from "@mui/material";
import { connect } from "react-redux";

import RoutingTableRow from "./RoutingTableRow";
import { getPartRoutings } from "../../redux/routing";
import { ROUTINGS as OPTIONS } from "../../constants";
import RoutingHiddenRows from "./RoutingHiddenRows";

const RoutingTableBody = ({ routings = [], expandAll = false }) => {
  const shown = OPTIONS?.filter((route) => routings?.includes(route.id));
  const hasHidden = shown?.length !== OPTIONS?.length;

  return (
    <TableBody>
      {shown?.map((route) => (
        <RoutingTableRow
          key={route.id + "shown"}
          route={route}
          expandAll={expandAll}
        />
      ))}
      {hasHidden && <RoutingHiddenRows />}
    </TableBody>
  );
};

const mapStateToProps = (state) => ({
  routings: getPartRoutings(state),
});
export default connect(mapStateToProps)(RoutingTableBody);
