import { addDays, isWeekend } from "date-fns";
import dayjs from "dayjs";

/**
 * Adds business days (Monday-Friday), skipping weekends.
 * @param {Date} date - The start date
 * @param {number} days - Number of business days to add/subtract
 * @returns {Date} - The resulting business day
 */
export const addBusinessDays = (date, days) => {
  let result = new Date(date);
  let addedDays = 0;
  const increments = Math.abs(Math.floor(days / 1440));
  const i = days > 0 ? 1 : -1;

  while (addedDays < increments) {
    result = addDays(result, i);
    if (!isWeekend(result)) {
      addedDays++;
    }
  }

  return new Date(result).toISOString();
};

/**
 * Updates the planned start and end dates for all rows after a move.
 * @param {Array} fields - The array of work order routing rows
 * @param {number} movedIndex - The index of the moved row
 * @returns {Array} - Updated fields with recalculated dates
 */
export const updatePlannedDates = (fields, movedIndex) => {
  if (fields.length === 0) return fields;

  const updatedFields = fields.slice(movedIndex);

  if (movedIndex > 0) {
    // Determine the new plannedStartDate for the moved row
    const previousRow = fields[movedIndex - 1];
    updatedFields[0].plannedStartDate = previousRow.plannedEndDate;

    // Calculate plannedEndDate based on leadTime (excluding weekends)
    updatedFields[0].plannedEndDate = addBusinessDays(
      updatedFields[0].plannedStartDate,
      updatedFields[0].leadTime || 0
    );
  }

  // Update all subsequent rows
  for (let i = 1; i < updatedFields.length; i++) {
    updatedFields[i].plannedStartDate = updatedFields[i - 1].plannedEndDate;
    updatedFields[i].plannedEndDate = addBusinessDays(
      updatedFields[i].plannedStartDate,
      updatedFields[i].leadTime || 0
    );
  }

  return updatedFields;
};

export const handleReschedule = (
  idx,
  getValues = () => {},
  setValue = () => {}
) => {
  const routes = getValues("routes");
  const rows = Object.values(routes);

  // Update planned dates after moving
  const updatedFields = updatePlannedDates(rows, idx);

  const getIso = (date) => dayjs(date).toISOString();

  // Apply updated values to the form
  updatedFields.forEach((field, index) => {
    const prefix = `routes.${index + idx}`;
    setValue(`${prefix}.plannedStartDate`, getIso(field.plannedStartDate));
    setValue(`${prefix}.plannedEndDate`, getIso(field.plannedEndDate));
    setValue(`${prefix}.order`, index + idx);
  });
};
