import React from "react";

import { HubTableHeader, InputLabel } from "../../components";
import { Stack } from "@mui/material";
import { StyledStack } from "./styles";

const required = true;

const headCells = [
  {
    id: "pi-outside",
    label: "Outside",
  },
  {
    id: "pi-service",
    label: "Service",
    required,
  },
  {
    id: "pi-fixture",
    label: "Fixture",
  },
  {
    id: "pi-partner",
    label: "Partner",
  },
  {
    id: "pi-lead",
    label: "Lead Time",
    required,
  },
  {
    id: "pi-cycle",
    label: "Cycle Time",
    required,
  },
  {
    id: "pi-notes",
    label: "Notes",
  },
];

export const PostFoundryTableHeader = () => {
  const ITEMS = headCells.map((cell) => ({
    id: cell.id,
    label: (
      <InputLabel
        label={cell.label}
        required={cell.required}
        marginLeft="5px"
        marginBottom="0px"
      />
    ),
  }));

  return (
    <StyledStack
      sx={{ backgroundColor: "hubColors.greyLighter", padding: "8px 0" }}
    >
      {ITEMS?.map((i) => i.label)}
    </StyledStack>
  );
};

export default PostFoundryTableHeader;
