import {
  EmployeesContainer,
  OrganizationStations,
  TreesContainer,
} from "../../containers";

export const tabs = [
  {
    id: 1,
    label: "Stations",
    icon: "Archive",
    TabElement: <OrganizationStations />,
  },
  {
    id: 2,
    label: "Materials",
    icon: "Codesandbox",
    TabElement: <TreesContainer />,
  },
  {
    id: 3,
    label: "Employees",
    icon: "User",
    TabElement: <EmployeesContainer />,
    // defaultSearchParams: { status: "A" },
    // includeLocationParam,
  },
  {
    id: 4,
    label: "Users",
    icon: "User",
    // TabElement: WorkOrderProd,
  },
  {
    id: 5,
    label: "Settings",
    icon: "Settings",
    // TabElement: WorkOrderProd,
  },
];
