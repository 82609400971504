export const items = [
  {
    id: "part",
    label: "Part",
  },
  {
    id: "workOrder",
    label: "Work Order",
  },
  {
    id: "lineNumber",
    label: "Line Item",
  },
  {
    id: "customer",
    label: "Customer",
  },
  {
    id: "status",
    label: "Status",
  },
];

export const keys = [
  {
    variant: "blueBold",
    value: "partName",
    navigate: true,
    route: "parts",
    routeId: "partId",
  },
  {
    variant: "blueBold",
    value: "workOrder",
    navigate: true,
    route: "works",
    routeId: "workOrder",
  },
  {
    variant: "blueBold",
    value: "lineItem",
    navigate: true,
    route: "orders",
    routeId: "orderId",
  },
  {
    variant: "blueBold",
    value: "customerName",
    navigate: true,
    route: "customers",
    routeId: "customerId",
  },
  {
    value: "status"
  }
];
