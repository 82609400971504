import React from "react";
import { Typography } from "@mui/material";

import { HubCardStack, HubTableTemplate } from "../../components";
import { useGetStationWorkOrders } from "../../hooks";

import { StyledSubStack } from "../CustomerOrdersTable/styles";
import { items, keys } from "./helper";

export const StationWorkOrdersTable = ({ item, open }) => {
  const { data } = useGetStationWorkOrders(item.id, open);

  const headerProps = { items };
  const bodyProps = { items: data?.workOrders || [], keys };
  const props = { headerProps, bodyProps, meta: data?.meta || {} };

  return (
    <StyledSubStack>
      <HubCardStack>
        <Typography variant="boldCaption">Work Order</Typography>
        <HubTableTemplate id="StationsTable" {...props} />
      </HubCardStack>
    </StyledSubStack>
  );
};
export default StationWorkOrdersTable;
