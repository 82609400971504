export const WorkOrder = {
  customerName: "Victaulic",
  partName: `OC 15" Disk`,
  partNumber: "P132182",
  quantity: 250,
  trees: 250,
  lotNumber: "793",
  workOrder: "LN-000123-1",
  poNumber: "110352",
  locationName: "IPC - Utah",
  lineItem: "LN-000123",
  startDate: "01/01/2025",
  dueDate: "01/01/2025",
  createdAt: "01/01/2025",
  address: "1156 N 450 W, Lindon UT, 84057",
  freight: "X",
  shipVia: "XPO-COLLECT",
  state: "Wax (In Progress)",
};

export const DataObjects = [
  { id: 1, label: "Work Orders", data: WorkOrder },
  { id: 2, label: "Purchase Orders", data: WorkOrder },
  { id: 3, label: "Customers", data: WorkOrder },
  { id: 4, label: "Employees", data: WorkOrder },
];

export const getDataObject = (id) => {
  const data = DataObjects?.find((d) => id === d.id);
  if (!data) return DataObjects[0].data;
  return data?.data;
};

export const getLabels = (id = 1) => {
  const data = DataObjects?.find((d) => id === d.id);

  if (!data) return DataObjects[0].data;

  return Object.keys(data?.data).map((key) => {
    // Split camelCase words
    const words = key.match(/([A-Z]?[a-z]+)/g) || [];

    // Capitalize each word
    const capitalized = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return capitalized;
  });
};

export const Formats = ["Any", "Numbers Only", "Letters Only"]?.map(
  (label, id) => ({ id: id + 1, label })
);

export const renderTemplate = ({ template, id = 1, index = null }) => {
  const data = getDataObject(id);

  return template.replace(/{{\s*(\w+)\s*}}/g, (match, key) => {
    if (key === "index" && index !== null) {
      return index;
    }
    return data[key] !== undefined ? data[key] : "";
  });
};
