import React, { useEffect } from "react";
import { Chip, Stack, Typography } from "@mui/material";

import { FOUNDRY_NAMES } from "../../constants";
import { useGetAllCustomers } from "../../hooks";
import { StyledHubHookFormSelect } from "./styles";
import { HubHookFormTagInput } from "../HubHookFormTagInput";
import { getCachedItem, setCachedItem } from "../../helpers";

const sxChip = {
  fontSize: "10px !important",
  fontWeight: 400,
  height: 20,
  span: { padding: 0 },
  padding: "5px",
};

export const HubHookCustomersSelect = ({
  name,
  required = true,
  disabled,
  isTag = false,
  isInput = true,
  label = "Customer",
  defaultValue,
  ...rest
}) => {
  const cachedData = getCachedItem("customers");
  const { data, isLoading, isSuccess } = useGetAllCustomers(!cachedData);
  const options = cachedData || data || [];

  useEffect(() => {
    if (data && isSuccess) setCachedItem("customers", data);
  }, [data]);

  const RenderComponent = ({ label, locations = [] }) => (
    <Stack direction="row" gap="10px" alignItems="center">
      <Typography
        sx={{
          fontSize: "12px !important",
          fontWeight: 500,
        }}
      >
        {label}
      </Typography>
      <Stack direction="row" gap="3px">
        {locations?.map((name) => (
          <Chip sx={sxChip} label={name} />
        ))}
      </Stack>
    </Stack>
  );

  const sharedProps = {
    RenderComponent,
    options,
    disableHelperText: true,
    required,
    isLoading,
    disabled: disabled || isLoading,
    label,
    name: name || "customerIds",
    defaultValue,
    ...rest,
  };

  if (!isInput)
    return (
      <Typography variant="caption">
        {options?.find((c) => c.id === defaultValue)?.label || "No Customer"}
      </Typography>
    );

  return isTag ? (
    <HubHookFormTagInput fullWidth {...sharedProps} />
  ) : (
    <StyledHubHookFormSelect useDefault={false} {...sharedProps} />
  );
};

export default HubHookCustomersSelect;
