import React from "react";

import { HubDialogWrapper } from "../HubDialogWrapper";
import CreateMaterialContent from "./CreateMaterialContent";

export const CreateMaterialDialog = () => {
  return (
    <HubDialogWrapper maxWidth="sm" name="create-material">
      <CreateMaterialContent />
    </HubDialogWrapper>
  );
};

export default CreateMaterialDialog;
