import React, { useEffect, useState } from "react";
import { TableCell, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";

import { getDollarValue } from "../../helpers/currency";
import { getDateData } from "../../helpers/dateHelper";
import { HubHookFormInput, HubHookFormSelect, HubIcon } from "../../components";

import LineItemContextMenu from "./LineItemContextMenu";
import OrderLineItemChip from "./OrderLineItemChip";

import { StyledHubHookFormDatePicker, StyledTableRow } from "./styles";
import LineItemsTable from "../CustomerOrdersTable/CustomerOrderLineWorksTable";
import OrderLineItemWorkRow from "./OrderLineItemWorkRow";
import { FOUNDRIES } from "../../constants";
import OrderPartSelect from "./OrderPartSelect";

const OrderLineItemRowV2 = ({
  idx,
  item = {},
  parts = [],
  disabled = false,
  deletedValues = [],
  updatedItems = [],
  setLine = () => {},
}) => {
  const { setValue, getValues } = useFormContext();

  const [deleted, setDeleted] = useState(deletedValues.includes(item.id));
  const [open, setOpen] = useState(false);

  const partItems = parts?.map((p) => ({
    id: p.id,
    label: p.name,
    status: p?.status,
  }));
  const prefix = `lineItems[${idx}].`;
  const existing = updatedItems.find((u) => u.id === item.id) || {};
  const rowItem = { ...item, ...existing };
  const part = parts?.find((p) => p?.id === rowItem.partId);

  useEffect(() => {
    if (deleted) {
      const addedDeleted = [...new Set([...deletedValues, item.id])];
      setValue("deletedLineItems", addedDeleted);
    }
  }, [deleted]);

  const onFieldUpdate = () => {
    const preItem = getValues(`lineItems[${idx}]`);
    let updatedItem = { ...item, ...preItem };

    const updatedIndex = updatedItems.findIndex((u) => u.id === item.id);
    updatedIndex > -1
      ? (updatedItems[updatedIndex] = updatedItem)
      : updatedItems.push(updatedItem);

    setValue("updatedLineItems", updatedItems);
  };

  const onUpdatePart = (partId) => {
    const newPart = parts?.find((p) => p?.id === partId);
    setValue(prefix + "price", newPart?.lastSentPrice);
    onFieldUpdate();
  };

  const onUpdatePrice = (price) => {
    let margin = 0.33;
    if (part?.lastSentPrice) {
      margin = (price * part.margin) / part.lastSentPrice;
      margin = Math.round(margin * 100) / 100;
    }

    setValue(prefix + "margin", margin);
    onFieldUpdate();
  };

  if (deleted) return <></>;
  return (
    <>
      <StyledTableRow key={rowItem.id}>
        <TableCell variant="small" onClick={() => setOpen((prev) => !prev)}>
          <HubIcon icon={open ? "ChevronDown" : "ChevronRight"} size="12px" />
        </TableCell>
        <TableCell sx={{ width: 100 }}>
          <OrderLineItemChip state={rowItem?.state} />
        </TableCell>
        <TableCell sx={{ width: 150 }}>
          <Typography variant="caption11">
            {rowItem?.lineNumber || "Will Be Assigned"}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: 100, minWidth: 100 }} disabled={disabled}>
          <HubHookFormInput
            name={prefix + "customerLine"}
            placeholder="Line"
            defaultValue={rowItem?.customerLine || "1"}
            onBlur={onFieldUpdate}
            disableHelperText
            required
          />
        </TableCell>
        <TableCell sx={{ minWidth: 250 }} disabled={disabled}>
          {disabled ? (
            <Typography variant="caption11">
              {partItems.find((p) => p.id === rowItem?.partId)?.label}
            </Typography>
          ) : (
            <OrderPartSelect
              name={prefix + "partId"}
              onChange={(e) => onUpdatePart(e?.target?.value)}
              defaultValue={rowItem?.partId}
              options={partItems}
              disableHelperText
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ width: 100, minWidth: 100 }}>
          <HubHookFormInput
            name={prefix + "quantity"}
            placeholder="1000"
            defaultValue={rowItem?.quantity}
            onBlur={onFieldUpdate}
            disableHelperText
            isNumber
            required
          />
        </TableCell>
        <TableCell sx={{ width: 125, minWidth: 125 }}>
          <HubHookFormInput
            name={prefix + "price"}
            placeholder="10.00"
            defaultValue={rowItem?.price}
            onBlur={(e, value) => onUpdatePrice(value)}
            disableHelperText
            isCurrency
            required
          />
        </TableCell>
        <TableCell sx={{ minWidth: 125 }} disabled={disabled}>
          <StyledHubHookFormDatePicker
            name={prefix + "dueDate"}
            placeholder="MM/DD/YYYY"
            defaultValue={rowItem?.dueDate}
            onChange={onFieldUpdate}
            disabled={!rowItem?.partId}
            required
          />
        </TableCell>
        <TableCell sx={{ width: 150, minWidth: 150 }} disabled={disabled}>
          {disabled ? (
            <Typography variant="caption11">
              {FOUNDRIES.find((f) => rowItem?.locationId === f.id).label}
            </Typography>
          ) : (
            <HubHookFormSelect
              name={prefix + "locationId"}
              onChange={onFieldUpdate}
              options={FOUNDRIES}
              defaultValue={rowItem?.locationId}
              disableHelperText
              required
            />
          )}
        </TableCell>
        <TableCell sx={{ maxWidth: "35px" }}>
          <HubHookFormInput
            name={prefix + "id"}
            defaultValue={rowItem?.id}
            disableHelperText
            hidden
          />
          <HubHookFormInput
            name={prefix + "margin"}
            defaultValue={rowItem.margin}
            required
            hidden
          />
          <LineItemContextMenu
            id={idx}
            disabled={disabled}
            removeItem={() => setDeleted(true)}
            lineId={rowItem?.id}
            setLine={setLine}
          />
        </TableCell>
      </StyledTableRow>
      <OrderLineItemWorkRow item={{ ...rowItem, part }} open={open} />
    </>
  );
};

export default React.memo(OrderLineItemRowV2);
