import React from "react";

import { HubAccordian, HubCardStack, HubIcon } from "../../components";
import { Divider, Stack, Typography } from "@mui/material";
import { StationsTable } from "../../tables";

export const OrganizationDepartment = ({
  label,
  details,
  icon,
  stations = [],
  setUpdatingStation = () => {},
}) => {
  return (
    <HubCardStack padding="15px" borderRadius="5px">
      <HubAccordian
        label={
          <Stack gap="15px" direction="row" alignItems="center">
            {icon && (
              <HubIcon
                icon={icon}
                size="16px"
                sx={{ color: "hubColors.mainFocus" }}
              />
            )}
            <Stack>
              <Typography variant="boldCaption">{label}</Typography>
              <Typography variant="caption">{details}</Typography>
            </Stack>
          </Stack>
        }
        labelProps={{ variant: "boldCaptionMain" }}
        accordianDetailsProps={{ sx: { padding: 0 } }}
        defaultOpen
        borderLess
      >
        <Stack>
          <Divider orientation="horizontal" flexItem />
          <StationsTable
            data={stations}
            setUpdatingStation={setUpdatingStation}
          />
        </Stack>
      </HubAccordian>
    </HubCardStack>
  );
};

export default OrganizationDepartment;
