import React from "react";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { PageContent, HubTableTemplate } from "../../components";
import { useGetWorkOrders } from "../../hooks";
import WorkOrderStatusChip from "./WorkOrderStatusChip";
import WorkOrdersBar from "./WorkOrdersBar";

const sortable = true;
const items = [
  {
    id: "startDate",
    label: "Start Date",
    sortable,
  },
  {
    id: "part",
    label: "Part",
  },
  {
    id: "workOrder",
    label: "WO #",
    sortable,
  },
  {
    id: "stationId",
    label: "Station",
  },
  {
    id: "departmentId",
    label: "Department",
  },
  // {
  //   id: "status",
  //   label: "Status",
  //   sortable,
  // },
];

const keys = [
  {
    value: "startDate",
    isDate: true,
  },
  {
    variant: "blueBold",
    value: "partName",
    navigate: true,
    route: "parts",
    id: "partId",
  },
  {
    value: "workOrder",
    variant: "blueBold",
    navigate: true,
    route: "works",
    id: "workOrder",
  },
  {
    value: "station",
  },
  {
    value: "department",
  },
  // {
  //   value: "status",
  //   CustomComponent: ({ item }) => (
  //     <WorkOrderStatusChip status={item?.status} />
  //   ),
  // },
];

export const WorkOrdersPage = () => {
  const { data } = useGetWorkOrders();

  const navigate = useNavigate();

  const onRowClick = (e, item) => {
    if (item?.workOrder) {
      e.preventDefault();
      e.stopPropagation();
      navigate(`/works/${item.workOrder}`);
    }
  };
  const headerProps = { items };
  const bodyProps = { items: data?.rows, keys, onRowClick };
  const props = { headerProps, bodyProps, meta: data?.meta };

  return (
    <PageContent gap="15px" white smallPadding>
      <WorkOrdersBar />
      <HubTableTemplate id="WorkOrdersTable" {...props} showPagination />
    </PageContent>
  );
};

export default WorkOrdersPage;
