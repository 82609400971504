import React from "react";
import { Chip, TableCell } from "@mui/material";

import { EmployeeHookFormSelect, HubHookFormInput } from "../../components";
import { StyledHubHookFormDatePicker, StyledTableRow } from "./styles";
import LaborContextMenu from "./LaborContextMenu";

const LaborStatus = ({ reworked, endDate }) => {
  let flow = reworked ? "grey" : endDate ? "blue" : "green";
  let label = reworked ? "Reworked" : endDate ? "Complete" : "Started";
  return <Chip variant="colorTag" label={label} flow={flow} />;
};

const LaborTableRow = ({
  prefix = "",
  labor = {},
  disabled = false,
  onFieldUpdate = () => {},
  setDeletingId = () => {},
}) => {
  const handleOnFieldUpdate = () => onFieldUpdate(prefix);
  const finalDisabled = disabled || labor?.deleted;

  return (
    <StyledTableRow key={prefix}>
      <TableCell variant="small">
        <LaborStatus {...labor} />
      </TableCell>
      <TableCell sx={{ width: 200 }}>
        <EmployeeHookFormSelect
          name={prefix + ".employeeId"}
          defaultValue={labor?.employeeId}
          location={labor?.locationId}
          onChange={handleOnFieldUpdate}
          disabled={finalDisabled}
          hideLabel
        />
      </TableCell>
      <TableCell sx={{ width: 100, minWidth: 100 }} disabled={disabled}>
        <HubHookFormInput
          name={prefix + ".completed"}
          placeholder="Completed"
          defaultValue={labor.completed || "0"}
          onBlur={handleOnFieldUpdate}
          disabled={finalDisabled}
          required
        />
      </TableCell>
      <TableCell sx={{ width: 100, minWidth: 100 }} disabled={disabled}>
        <HubHookFormInput
          name={prefix + ".scrapped"}
          placeholder="Scrapped"
          defaultValue={labor.scrapped || "0"}
          onBlur={handleOnFieldUpdate}
          disabled={finalDisabled}
          required
        />
      </TableCell>
      <TableCell sx={{ width: 100, minWidth: 100 }} disabled={disabled}>
        <StyledHubHookFormDatePicker
          name={prefix + ".startDate"}
          placeholder="MM/DD/YYYY"
          defaultValue={labor?.startDate}
          onChange={handleOnFieldUpdate}
          disabled={finalDisabled}
          required
        />
      </TableCell>
      <TableCell sx={{ width: 100, minWidth: 100 }} disabled={disabled}>
        <StyledHubHookFormDatePicker
          name={prefix + ".endDate"}
          placeholder="MM/DD/YYYY"
          defaultValue={labor?.endDate}
          onChange={handleOnFieldUpdate}
          disabled={finalDisabled}
          required
        />
      </TableCell>
      <TableCell disabled={disabled}>
        <HubHookFormInput
          name={prefix + ".notes"}
          placeholder="Notes"
          defaultValue={labor.notes}
          onBlur={handleOnFieldUpdate}
          disabled={finalDisabled}
          disableHelperText
        />
        <HubHookFormInput
          name={prefix + ".laborId"}
          defaultValue={labor.laborId}
          hidden
        />
      </TableCell>
      <TableCell variant="small">
        <LaborContextMenu
          id={labor.laborId}
          setDeletingId={setDeletingId}
          disabled={finalDisabled}
        />
      </TableCell>
    </StyledTableRow>
  );
};

export default LaborTableRow;
