import { styled } from "@mui/material/styles";
import { HubCardStack } from "../../HubCardStack";
import { Stack, Typography } from "@mui/material";
import { BarcodeDimensions } from "../../../constants/barcodes";

const fontSize = 16;
const scale = 3.5;

const getBarcodeSize = (id = 1) => {
  const size =
    BarcodeDimensions.find((b) => b.id === id) || BarcodeDimensions[0];
  const label = size?.label;
  const [width, height] = label.replace(/[^\d.x]/g, "").split("x");

  return {
    height: `${height}in`,
    width: `${width}in`,
    maxHeight: `${height}in`,
    maxWidth: `${width}in`,
  };
};
export const BarcodeSticker = styled(HubCardStack, {
  shouldForwardProp: (prop) => !["size"].includes(prop),
})(({ size = 1 }) => ({
  ...getBarcodeSize(size),
  gap: "5px",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
  padding: "5px",
  flexGrow: "0px",
  borderRadius: "5px",
  // boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.2)"
}));

export const BarcodeTypography = styled(Typography)(({ theme }) => ({
  fontSize,
  lineHeight: `${fontSize}px`,
  transform: `scaleY(${scale})`,
  textWrap: "nowrap",
}));
export const BarcodeBox = styled(Stack)({
  alignItems: "center",
  justifyContent: "center",
  height: `${fontSize * scale - 10}px`,
});

export const BarcodeDetails = styled(Stack)({
  display: "grid",
  gridTemplateColumns: "auto auto auto",
  alignContent: "center",
  width: "100%",
  padding: "0 5px",
});
