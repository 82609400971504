import qs from "qs-lite";
import { useQuery, useQueryClient } from "react-query";
import api from "../../utils/api";
import { useEffect } from "react";

const JOBS = "/jobs";

const getJobDashboard = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${JOBS}/dashboard?${queryString}`);
};

export const useGetJobDashboard = ({ per, start, end, defaultLocation }) => {
  const query = new URLSearchParams(window.location.search);

  const queryLocation = query.get("location");
  const location =
    queryLocation === "All" ? "" : queryLocation || defaultLocation;
  const period = query.get("period") || per;
  const startDate = query.get("startDate") || start;
  const endDate = query.get("endDate") || end;

  const queryProps = {
    ...(location ? { location } : {}),
    period,
    startDate,
    endDate,
  };

  return useQuery([JOBS, { ...queryProps }], getJobDashboard, {
    enabled: !!defaultLocation,
    refetchInterval: 60000,
  });
};

const getAllJobData = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${JOBS}?${queryString}`);
};
export const useGetAllJobData = ({ per, start, end, defaultLocation }) => {
  const query = new URLSearchParams(window.location.search);

  const queryLocation = query.get("location");
  const locationDef =
    queryLocation === "All" ? "" : queryLocation || defaultLocation;
  const statusDef = query.get("status") || "Not Started-In Progress";

  const location = `${locationDef}`;
  const status = `${statusDef}`;

  const orderBy = query.get("orderBy") || "workOrder";
  const sortOrder = query.get("sortOrder") || "desc";
  const limit = query.get("limit") || 100;
  const offset = query.get("offset") || 0;

  const period = query.get("period") || per;
  const startDate = query.get("startDate") || start;
  const endDate = query.get("endDate") || end;

  const queryProps = {
    ...(location ? { location } : {}),
    ...(status != "All" && status ? { status } : {}),
    limit,
    offset,
    orderBy,
    sortOrder,
    period,
    startDate,
    endDate,
  };

  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.cancelQueries({
      predicate: (query) => query.queryKey[0] === JOBS,
      exact: false,
    });
  }, [location, status, limit, offset, orderBy, sortOrder, queryClient]);

  return useQuery([JOBS, { ...queryProps }], getAllJobData, {
    enabled: !!defaultLocation,
  });
};

export const getOrderJobs = (id) => api.get(`${JOBS}/order/${id}`);
export const useGetOrderJobs = (id, isOpen) => {
  return useQuery([`${JOBS}/order/${id}`], () => getOrderJobs(id), {
    enabled: !isNaN(id) && isOpen,
  });
};
