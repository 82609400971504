import React from "react";
import { Navigate } from "react-router-dom";

import {
  PartDetailsPage,
  ContactPage,
  WorkOrderDetails,
  DisplaysPage,
  LaborPage,
  LaborDetailsPage,
  TicketsPage,
  OrganizationSettings,
  ModelsPage,
  LineItemDetails,
  ShippingPage,
  BarcodeBuilder,
} from "./pages";
import SignIn from "./pages/SignIn/SignIn";
import OrderPage from "./pages/Order/OrderPage";

const authenticate = true;

export const routes = [
  {
    path: "/",
    element: <Navigate to="/signin" />,
  },
  {
    path: "/signin",
    title: "Foundry Sign In",
    element: <SignIn />,
  },
  {
    path: "/signup",
    title: "Foundry Sign Up",
    // element: <SignIn signUp />,
    element: <Navigate to="/signin" />,
  },
  {
    path: "/customers",
    title: "Foundry Customers",
    // element: <CompaniesPage />,
    element: <Navigate to="/models?view=1" />,
    authenticate,
  },
  {
    path: "/customers/:id",
    title: "Customer Contact",
    element: <ContactPage />,
    authenticate,
  },
  {
    path: "/parts",
    title: "Foundry Group Parts",
    // element: <PartsPage />,
    element: <Navigate to="/models?view=2" />,
    authenticate,
  },
  {
    path: "/parts/:id",
    title: "Part Details",
    element: <PartDetailsPage />,
    authenticate,
  },

  {
    path: "/works",
    title: "Foundry Work Orders",
    // element: <WorkOrdersPage />,
    element: <Navigate to="/models?view=4" />,
    authenticate,
  },
  {
    path: "/works/:id",
    title: "Work Order Details",
    element: <WorkOrderDetails />,
    useTitleId: true,
    authenticate,
  },
  {
    path: "/orders",
    title: "Purchase Orders",
    // element: <OrdersPage />,
    element: <Navigate to="/models?view=3" />,
    authenticate,
  },
  {
    path: "/orders/:id",
    title: "View Order",
    element: <OrderPage />,
    authenticate,
  },

  {
    path: "/displays",
    title: "Displays",
    element: <DisplaysPage />,
    authenticate,
  },
  {
    path: "/models",
    title: "Models",
    element: <ModelsPage />,
    authenticate,
  },
  {
    path: "/organization",
    title: "Organization Settings",
    element: <OrganizationSettings />,
    authenticate,
  },
  {
    path: "/settings",
    title: "User Settings",
    element: <></>,
    authenticate,
  },
  {
    path: "/labor",
    title: "Work Order Labor",
    element: <LaborPage />,
    authenticate,
  },
  {
    path: "/tickets",
    title: "Labor Tickets",
    element: <TicketsPage />,
    authenticate,
  },
  {
    path: "/labor/:id",
    title: "Work Order Labor",
    element: <LaborDetailsPage />,
    authenticate,
  },
  {
    path: "/shipping",
    title: "Shipping",
    element: <ShippingPage />,
    authenticate,
  },
  // {
  //   path: "/barcodes/:id",
  //   title: "Barcodes",
  //   element: <BarcodeBuilder />,
  //   authenticate,
  // },
  // {
  //   path: "/lineitems/:id",
  //   title: "Line Item Details",
  //   element: <LineItemDetails />,
  //   authenticate,
  // },
  {
    path: "/not-found",
    element: <Navigate to="/customers" />,
  },
  {
    path: "*",
    element: <Navigate to="/not-found" />,
  },
];
export default routes;
