import React, { useEffect } from "react";
import {
  Stack,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import { useController, useFormContext } from "react-hook-form";
import { StyledRadioCard } from "./styles";
import { InputLabel } from "../InputLabel";

const CustomCard = ({ option, selected }) => {
  const SkeletedCard = option?.icon;
  const size = option?.size || "150px";

  const sx = { ...option?.styles };

  return (
    <StyledRadioCard selected={selected} sx={sx}>
      {option?.icon && (
        <SkeletedCard width={size} height="auto" selected={selected} />
      )}
      {(option?.label || option?.subtitle) && (
        <Stack textAlign="center">
          {option?.label && (
            <Typography variant="boldCaption">{option?.label}</Typography>
          )}
          {option?.subtitle && (
            <Typography variant="greyDark12">{option?.subtitle}</Typography>
          )}
        </Stack>
      )}
    </StyledRadioCard>
  );
};

export const CustomRadioGroup = ({
  name = "",
  options = [],
  sx = {},
  defaultValue = "",
  label,
  required,
  onChange = () => {},
  CustomComponent = CustomCard,
}) => {
  const defaultInputValue = defaultValue || options[0].id || false;
  const { setValue } = useFormContext();

  const {
    field: { onChange: onControllerChange, value, name: inputName, ref },
  } = useController({
    name,
    rules: {
      validate: {
        required: (value) =>
          required ? value === true || "This field is required." : true,
      },
    },
    defaultValue: defaultInputValue,
    shouldUnregister: true,
  });

  useEffect(() => {
    setValue(inputName, defaultInputValue);
  }, [defaultInputValue]);

  const handleChange = (e) => {
    const newValue = e?.target?.value;
    onControllerChange(e);
    onChange(newValue);
    setValue(inputName, newValue);
  };

  return (
    <Stack>
      {label && <InputLabel label={label} required={required} />}
      <RadioGroup
        inputRef={ref}
        onChange={handleChange}
        value={value}
        name={inputName}
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          gap: "15px",
          alignItems: "center",
          justifyContent: "center",
          ".MuiFormControlLabel-root": {
            gap: 0,
            margin: 0,
          },
          ...sx,
        }}
      >
        {options?.map((option, key) => (
          <FormControlLabel
            value={option?.id}
            control={
              <CustomRadio option={option} CustomComponent={CustomComponent} />
            }
            label=""
            key={key}
            sx={{ width: "100%", height: "100%" }}
          />
        ))}
      </RadioGroup>
    </Stack>
  );
};

const CustomRadio = ({ option, CustomComponent, ...rest }) => {
  return (
    <Radio
      sx={{
        cursor: "pointer",
        transition: "transform 0.3s ease-in-out",
        width: "100%",
        height: "100%",
        padding: 0,
        "&:hover": {
          bgcolor: "transparent",
          transform: "scale(1.015)",
        },
      }}
      disableRipple
      checkedIcon={<CustomComponent option={option} selected />}
      icon={<CustomComponent option={option} />}
      {...rest}
    />
  );
};
export default CustomRadioGroup;
