export const formatBarcodeValue = (value, format) => {
    const raw = value ?? "";
    const digitsOnly = raw.replace(/\D/g, "");
  
    const pad = (val, length) => val.padStart(length, "0");
  
    switch (format) {
      case "EAN13": {
        // EAN-13 requires exactly 12 digits + 1 auto check digit
        const padded = pad(digitsOnly.slice(0, 12), 12);
        return padded;
      }
  
      case "EAN8": {
        // EAN-8 requires 7 digits + 1 auto check digit
        const padded = pad(digitsOnly.slice(0, 7), 7);
        return padded;
      }
  
      case "UPC": {
        // UPC requires 11 digits + check digit
        const padded = pad(digitsOnly.slice(0, 11), 11);
        return padded;
      }
  
      case "ITF14": {
        // ITF-14 requires 13 digits + 1 check digit
        const padded = pad(digitsOnly.slice(0, 13), 13);
        return padded;
      }
  
      case "MSI":
      case "MSI10":
      case "MSI11":
      case "MSI1010":
      case "MSI1110": {
        // MSI formats only support digits; optionally pad to min length if needed
        const padded = pad(digitsOnly, 4); // optional: pad to at least 4 digits
        return padded;
      }
  
      case "pharmacode": {
        // Pharmacode supports numeric values between 3 and 131070
        const num = parseInt(digitsOnly || "3", 10);
        return num >= 3 && num <= 131070 ? num.toString() : "3";
      }
  
      case "codabar": {
        // Codabar supports digits, A-D, and symbols
        return raw
          .toUpperCase()
          .replace(/[^0-9\-\$:\/\.\+ABCD]/g, "");
      }
  
      case "CODE39": {
        // CODE39: A-Z, 0-9, space, - . $ / + %
        return raw
          .toUpperCase()
          .replace(/[^A-Z0-9 \-.\$/+%]/g, ""); // <-- don't wrap with *
      }
  
      case "CODE128":
      case "CODE128A":
      case "CODE128B":
      case "CODE128C":
      default:
        // CODE128 supports full ASCII — no cleanup needed
        return raw;
    }
  }
  