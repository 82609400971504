import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { PageContent } from "../../components";
import ModelsNav from "./ModelsNav";
import { tabs } from "./helper";

export const ModelsPage = ({ defaultLocation = 1 }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState();
  const property = searchParams.get("view");

  const onSetValue = (id) => {
    const tab = tabs?.find((tab) => tab.id === id);
    if (!tab) return;

    const defaultParams = tab?.defaultSearchParams || {};
    const params = {
      view: id,
      ...defaultParams,
      ...(tab?.includeLocationParam ? { location: defaultLocation } : {}),
    };

    localStorage && localStorage.setItem("Model-View", id);
    setSearchParams(params);
    setValue(id);
  };

  useEffect(() => {
    const defaultId = localStorage
      ? localStorage.getItem("Model-View")
      : property;
    onSetValue(defaultId || tabs[0].id);
  }, [property, tabs]);

  const TAB = tabs?.find(({ id }) => id === value);
  const Element = TAB?.TabElement || Stack;

  return (
    <PageContent disablePadding>
      <Stack direction="row" height="100%" overflow="hidden">
        <ModelsNav {...{ value, setValue: onSetValue }} />
        <Element />
      </Stack>
    </PageContent>
  );
};

export default ModelsPage;
