import React, { useEffect, useMemo } from "react";

import { HubHookFormSelect } from "../HubHookFormSelect";
import { getCachedItem, setCachedItem } from "../../helpers";
import { useGetMaterialData } from "../../hooks";

export const HubMaterialHookSelect = ({
  type = "materials",
  name = "material",
  label = "Material",
  defaultValue,
  hideLabel = false,
  dispatch = () => {},
  disabled = false,
  ...rest
}) => {
  const cachedData = getCachedItem("materials");
  const { data, isLoading, isSuccess } = useGetMaterialData(!cachedData);
  const options = cachedData || data || [];

  useEffect(() => {
    if (data && isSuccess) setCachedItem("materials", data);
  }, [data]);

  const list = useMemo(() => options[type] || options?.materials || [], [type]);
  const defaultInput = defaultValue || list[0]?.id;
  const key = type === "materials" ? "category" : "location.name";

  return (
    <HubHookFormSelect
      name={name}
      defaultValue={defaultInput}
      disabled={isLoading || disabled}
      options={list}
      disableHelperText
      required
      groupByKey={key}
      {...(!hideLabel ? { label } : {})}
      {...rest}
    />
  );
};
