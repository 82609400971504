import { styled } from "@mui/material/styles";
import { HubCardStack } from "../../components";
import { Stack } from "@mui/material";

export const StyledCard = styled(Stack)({
  padding: "4px",
  cursor: "pointer",
  position: "relative",
  boxShadow: "none",
});

export const StyledCardStack = styled(HubCardStack)(({ theme }) => ({
  gap: "10px",
  padding: "10px",
  backgroundColor: theme.palette.hubColors.greyLightest,
  borderRadius: "5px",
  transition: "all 0.1s ease-in-out",
  "&:hover": {
    backgroundColor: theme.palette.hubColors.greyLighter,
  },
}));
