import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { useTheme } from "@mui/material";

import { HubIcon } from "../HubIcon";

import { StyledCarousel, StyledIconButton } from "./styles";

export const HubCarousel = ({ parent = window, length, children, sx = {} }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [infinite, setInfinite] = useState();
  const [parentSize, setParentSize] = useState(parent?.innerWidth);

  const theme = useTheme();

  const next = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const prev = () => {
    setCurrentSlide(currentSlide - 1);
  };

  const getMinNum = (num) => Math.min(length, num);

  const handleResize = () => {
    setParentSize(parent?.innerWidth);
  };

  useEffect(() => {
    parent?.addEventListener("resize", handleResize);
    return () => parent?.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let num;

    if (parentSize >= theme.breakpoints.values["xl"]) {
      num = 5;
    } else if (parentSize >= theme.breakpoints.values["lg"]) {
      num = 4;
    } else if (parentSize >= theme.breakpoints.values["md"]) {
      num = 3;
    } else if (parentSize >= theme.breakpoints.values["sm"]) {
      num = 2;
    } else {
      num = 1;
    }

    const showInfinite = length > num;
    setInfinite(showInfinite);
  }, [length, parentSize]);

  const settings = {
    infinite: false,
    slidesToShow: getMinNum(5),
    slidesToScroll: getMinNum(5),
    vertical: false,
    arrows: infinite,
    prevArrow: (
      <StyledIconButton onClick={prev}>
        <HubIcon icon="ChevronLeft" />
      </StyledIconButton>
    ),
    nextArrow: (
      <StyledIconButton onClick={next}>
        <HubIcon icon="ChevronRight" />
      </StyledIconButton>
    ),
    responsive: [
      {
        breakpoint: theme.breakpoints.values["xl"],
        settings: {
          slidesToShow: getMinNum(4),
          slidesToScroll: getMinNum(4),
        },
      },
      {
        breakpoint: theme.breakpoints.values["lg"],
        settings: {
          slidesToShow: getMinNum(3),
          slidesToScroll: getMinNum(3),
        },
      },
      {
        breakpoint: theme.breakpoints.values["md"],
        settings: {
          slidesToShow: getMinNum(2),
          slidesToScroll: getMinNum(2),
        },
      },
      {
        breakpoint: theme.breakpoints.values["sm"],
        settings: {
          slidesToShow: getMinNum(1),
          slidesToScroll: getMinNum(1),
        },
      },
    ],
  };

  return (
    <StyledCarousel infinite={infinite} length={length} sx={sx}>
      <Slider {...settings}>{children}</Slider>
    </StyledCarousel>
  );
};

HubCarousel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
};

export default HubCarousel;
