import { styled } from "@mui/material/styles";
import {
  Stack,
  IconButton,
  Typography,
  Card,
  CardMedia,
  Chip,
  Box,
} from "@mui/material";

const undoAttr = {
  width: "30px",
  "&:hover": {
    backgroundColor: "unset",
    color: "unset",
  },
  "&:active": {
    backgroundColor: "unset",
    color: "unset",
  },
  "&:focus": {
    backgroundColor: "unset",
    color: "unset",
  },
  "&:before": {
    content: '""',
  },
};

export const StyledCarousel = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "infinite" && prop !== "length",
})(({ theme, infinite, length }) => ({
  // padding: "0 50px",
  gap: "25px",
  "& .slick-slider": {
    display: "grid",
    gridTemplateColumns: infinite ? "30px 1fr 30px" : "1fr",
    "& .slick-list": {
      overflow: "hidden",
    },
  },
  "& .slick-prev": {
    ...undoAttr,
    left: 0,
  },
  "& .slick-next": {
    ...undoAttr,
    right: 0,
  },
  "& .slick-track": {
    display: "flex",
    "& .slick-slide": {
      // maxWidth: "200px",
      width: "fit-content !important",
      [theme.breakpoints.up("lg")]: {
        ...(length >= 4 ? { maxWidth: "25%" } : {}),
      },
      [theme.breakpoints.down("lg")]: {
        ...(length >= 3 ? { maxWidth: "33%" } : {}),
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "50%",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
  },
}));

export const StyledIconButton = styled(IconButton)({
  padding: "0",
});

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "base",
})(({ theme, base }) => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  color: base ? theme.palette.hubColors.greyDark : theme.palette.hubColors.grey,
}));

export const StyledCard = styled(Card)({
  margin: "4px",
  cursor: "pointer",
  position: "relative",
  boxShadow: "none",
  backgroundColor: "transparent",
});

export const StyledCardMedia = styled(CardMedia)({
  borderRadius: "12px",
});

export const StyledMediaCardDetails = styled(Stack)({
  position: "absolute",
  margin: 0,
  bottom: 0,
  padding: "8px 15px",
  width: "100%",
  height: "100%",
  flexDirection: "row",
  justifyContent: "end",
  alignItems: "end",
  background: "linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.65))",
});

export const StyledChip = styled(Chip)(({ theme }) => ({
  margin: 0,
  fontWeight: "600",
  zIndex: 1,
  backgroundColor: "rgba(0,0,0,0.5)",
  color: theme.palette.hubColors.fontWhite,
}));

export const StyledBox = styled(Box)({
  position: "relative",
  overflow: "hidden",
  borderRadius: "12px",
});
