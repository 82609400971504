import qs from "qs-lite";
import { useMutation, useQuery, useQueryClient } from "react-query";

import api from "../../utils/api";
import { useHubSnackBar } from "../useSnackBar";
import { clearCachedItem } from "../../helpers";

const STATIONS = "/station";

const getStationList = () => api.get(`${STATIONS}/all`);
export const useGetStationList = (enabled) => {
  return useQuery([STATIONS], getStationList, {
    enabled,
  });
};

const getStationDepartments = () => api.get(`${STATIONS}/departments`);
export const useGetStationDepartments = () => {
  return useQuery([`${STATIONS}/departments`], getStationDepartments);
};

const getStationWorkOrders = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[2]);
  return api.get(`${STATIONS}/workOrders/${queryKey[1]}?${queryString}`);
};
export const useGetStationWorkOrders = (id, enabled) => {
  const query = new URLSearchParams(window.location.search);

  const orderBy = query.get("orderBy") || "plannedStartDate";
  const sortOrder = query.get("sortOrder") || "asc";
  const limit = query.get("limit") || 25;
  const offset = query.get("offset") || 0;

  const queryProps = {
    limit,
    offset,
    orderBy,
    sortOrder,
  };

  return useQuery(
    [`${STATIONS}/workOrders`, id, { ...queryProps }],
    getStationWorkOrders,
    {
      enabled,
    }
  );
};

const postCreateStation = (payload) => api.post(STATIONS, payload);
export const usePostCreateStation = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(postCreateStation, {
    onSuccess: () => {
      queryClient.invalidateQueries(`${STATIONS}/departments`);
      clearCachedItem("stations");
      addSnackbarSuccess({ message: "Successfully Created Station" });
    },
  });
};

const putStation = (payload) => {
  const id = payload.id;
  delete payload.id;
  return api.put(`${STATIONS}/${id}`, payload);
};

export const usePutStation = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(putStation, {
    onSuccess: () => {
      queryClient.invalidateQueries(`${STATIONS}/departments`);
      clearCachedItem("stations");
      addSnackbarSuccess({ message: "Successfully Updated Station" });
    },
  });
};
