import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { generateItems } from "./helper";
import { StyledDisabledDatePicker } from "./styles";

const AddWorkOrderEndDate = ({ totalDays }) => {
  const { watch } = useFormContext();

  const data = watch();
  const endDate = useMemo(() => {
    const isCustom = data?.repeatOption === 4;
    const hasWeekdays = !data?.repeatWeekdays?.length;
    if (!(isCustom && hasWeekdays) && data.batches) {
      const dueDate = generateItems(data, totalDays);
      return dueDate;
    }
  }, [data]);

  console.log({ endDate, data });

  return (
    <StyledDisabledDatePicker
      name="dueDate"
      label="Projected End Date"
      defaultValue={endDate}
      required
      disabled
    />
  );
};

export default AddWorkOrderEndDate;
