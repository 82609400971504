import React from "react";
import { Stack, Typography } from "@mui/material";
import dayjs from "dayjs";

import { EMPLOYEE_TYPES, EMPLOYEE_TYPES_DICT } from "../../constants/employees";
import { DEPARTMENTS, FOUNDRIES } from "../../constants";
import {
  CustomRadioGroup,
  HubHookFormDatePicker,
  HubHookFormSelect,
} from "../../components";
import EmployeeRadioInput from "./EmployeeRadioInput";

const CreateEmployeeHire = () => {
  return (
    <Stack gap="15px" width="100%">
      <Typography variant="boldCaptionMain">Hire Details</Typography>
      <Stack gap="15px">
        <CustomRadioGroup
          name="type"
          label="Worker Type"
          options={EMPLOYEE_TYPES}
          defaultValue={EMPLOYEE_TYPES_DICT.FULLTIME}
          CustomComponent={EmployeeRadioInput}
        />
        <Stack direction="row" gap="15px">
          <HubHookFormSelect
            name="locationId"
            label="Location"
            options={FOUNDRIES}
            required
          />
          <HubHookFormDatePicker
            name="doh"
            label="Date of Hire"
            placeholder="MM/DD/YYYY"
            defaultValue={dayjs().toISOString()}
            required
          />
        </Stack>
        <HubHookFormSelect
          name="departmentId"
          label="Department"
          options={DEPARTMENTS}
          required
        />
      </Stack>
    </Stack>
  );
};

export default CreateEmployeeHire;
