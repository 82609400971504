import React from "react";
import { Radio, Typography } from "@mui/material";

import { StyledStack } from "./styles";

const EmployeeRadioInput = ({ option, selected }) => (
  <StyledStack selected={selected}>
    <Typography variant="caption">{option.label}</Typography>
    <Radio checked={selected} />
  </StyledStack>
);

export default EmployeeRadioInput;
