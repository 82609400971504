import React from "react";
import { TableContainer, Table } from "@mui/material";
import { useParams } from "react-router-dom";

import { HubPagination } from "../../components";
import { useGetCustomerOrderData } from "../../hooks";

import { CustomerOrdersBody } from "./CustomerOrdersBody";
import CustomerOrdersHeader from "./CustomerOrdersHeader";

export const CustomerOrdersTable = () => {
  const { id } = useParams();
  const { data } = useGetCustomerOrderData(id);
  const meta = data?.meta;

  return (
    <>
      <TableContainer>
        <Table id="CustomerOrdersTable">
          <CustomerOrdersHeader />
          <CustomerOrdersBody orders={data?.rows} />
        </Table>
      </TableContainer>
      {meta?.count >= 10 && <HubPagination {...meta} />}
    </>
  );
};

export default CustomerOrdersTable;
