import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledBackgroundStack = styled(Stack, {
  shouldForwardProp: (prop) => !["serialize"].includes(prop),
})(({ theme, serialize }) => ({
  width: "100%",
  height: "100%",
  justifyContent: serialize ? "start" : "center",
  alignItems: "center",
  overflow: "auto",
  padding: "50px",
  backgroundColor: theme.palette.hubColors.mainFocusLightest,
  backgroundImage: "radial-gradient(#999 1px, transparent 0)",
  backgroundSize: "20px 20px",
  backgroundRepeat: "repeat",
  "::-webkit-scrollbar": {
    display: "none",
  },
}));

export const StyledLabelStack = styled(Stack)(({ theme }) => ({
  gap: "5px",
  boxShadow: "0px 0px 50px rgba(63, 37, 0, 0.3)",
  border: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.greyLightest,
  padding: "5px",
  borderRadius: "5px",
}));
