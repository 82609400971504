import React, { useEffect } from "react";

import { WarningDeleteDialog } from "../../dialogs";
import { useDeleteStationDisplay } from "../../hooks";

import { useDisplaysContext } from "./DisplayContextProvider";

const DisplayDeleteConfirm = () => {
  const { deletingId, setDeletingId } = useDisplaysContext();

  const { mutate: deleteStationDisplay, isSuccess } = useDeleteStationDisplay();
  const onSubmit = () => deleteStationDisplay(deletingId);

  useEffect(() => {
    if (isSuccess) {
      setDeletingId(null);
      window.location.reload();
    }
  }, [isSuccess]);

  if (!deletingId) return <></>;

  return (
    <WarningDeleteDialog
      isOpen={deletingId}
      onClose={() => setDeletingId(null)}
      onSubmit={onSubmit}
    />
  );
};

export default DisplayDeleteConfirm;
