import React from "react";
import { Stack, Typography } from "@mui/material";
// import { Barcode } from "react-barcode-qrcode";

import {
  BarcodeSticker,
  BarcodeTypography,
  BarcodeBox,
  BarcodeDetails,
} from "./styles";
import { Barcode } from "../../Barcode";

const align = ["left", "center", "right"];

export const BarcodeStickers = ({
  barcode = "",
  barcodeProps = {},
  top = [],
  bottom = [],
  size = 1,
  ...rest
}) => {
  return (
    <BarcodeSticker size={size} {...rest}>
      <BarcodeDetails>
        {top?.map((value, index) =>
          value ? (
            <Typography
              variant="caption"
              sx={{ lineHeight: "12px", textAlign: align[index] }}
            >
              {value}
            </Typography>
          ) : (
            <div />
          )
        )}
      </BarcodeDetails>
      {/* <Stack justifyContent="center" alignItems="center">
        <BarcodeBox>
          <BarcodeTypography variant="barcode">*{barcode}*</BarcodeTypography>
        </BarcodeBox>
        <Typography
          variant="caption"
          sx={{ lineHeight: "12px", textAlign: "center" }}
        >
          {barcode}
        </Typography>
      </Stack> */}
      <Barcode value={barcode} {...barcodeProps} />
      <BarcodeDetails>
        {bottom?.map((value, index) =>
          value ? (
            <Typography
              variant="caption"
              sx={{ lineHeight: "12px", textAlign: align[index] }}
            >
              {value}
            </Typography>
          ) : (
            <div />
          )
        )}
      </BarcodeDetails>
    </BarcodeSticker>
  );
};

export default BarcodeStickers;
