import React, { useEffect, useState } from "react";
import { Table, TableContainer } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { useGetPartData, useGetPartInstructions } from "../../hooks";
import { loadPartInstructions } from "../../redux/routing";

import RoutingTableSkeleton from "./RoutingTableSkeleton";
import RoutingTableHeader from "./RoutingTableHeader";
import RoutingTableBody from "./RoutingTableBody";
import { HubHookFormInput } from "../../components";

const RoutingTableData = ({ locationId, _loadPartInstructions = () => {} }) => {
  const { id } = useParams();
  const { data: partDetails } = useGetPartData({ id });
  const { data, isLoading, isSuccess } = useGetPartInstructions({
    id,
    locationId,
  });

  const { reset } = useFormContext();
  const [expandAll, setExpandAll] = useState(false);

  useEffect(() => {
    if (isSuccess && data) {
      _loadPartInstructions(data);
      reset({ locationId, ...partDetails, ...data });
    }
  }, [isSuccess, data]);

  if (isLoading) return <RoutingTableSkeleton />;
  return (
    <TableContainer
      sx={{ ".MuiTableCell-root": { borderRight: "0 !important" } }}
    >
      <HubHookFormInput name="locationId" defaultValue={locationId} hidden />
      <Table id="RoutingTable">
        <RoutingTableHeader setExpandAll={setExpandAll} expandAll={expandAll} />
        <RoutingTableBody expandAll={expandAll} />
      </Table>
    </TableContainer>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  _loadPartInstructions: loadPartInstructions,
};

export const RoutingTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutingTableData);

export default RoutingTable;
