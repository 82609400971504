import { useMutation, useQuery, useQueryClient } from "react-query";
import qs from "qs-lite";
import api from "../../utils/api";
import { clearCachedItem, getQueryParams } from "../../helpers";
import { useHubSnackBar } from "../useSnackBar";

const MATERIALS = "/materials";

const TREES = "/trees";

const getMaterialData = () => api.get(`${MATERIALS}/all`);
export const useGetMaterialData = (enabled) => {
  return useQuery([MATERIALS], getMaterialData, {
    keepPreviousData: true,
    enabled,
  });
};

const getAllMaterialData = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${MATERIALS}?${queryString}`);
};
export const useGetAllMaterialData = () => {
  const queryProps = getQueryParams({ prefix: "material" });
  return useQuery([MATERIALS, queryProps], getAllMaterialData, {
    keepPreviousData: true,
  });
};

const getAllTreeData = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${TREES}?${queryString}`);
};

export const useGetAllTreeData = () => {
  const queryProps = getQueryParams({ prefix: "tree" });
  return useQuery([TREES, queryProps], getAllTreeData, {
    retry: false,
    keepPreviousData: true,
  });
};

const postCreateMaterial = (payload) => api.post(MATERIALS, payload);
export const usePostCreateMaterial = () => {
  const { addSnackbarSuccess } = useHubSnackBar();
  const queryClient = useQueryClient();

  return useMutation(postCreateMaterial, {
    onSuccess: () => {
      queryClient.invalidateQueries(MATERIALS);
      queryClient.invalidateQueries(TREES);
      clearCachedItem("materials");
      addSnackbarSuccess({ message: "Successfully Created Material" });
    },
  });
};
