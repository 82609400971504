import React from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";
import { useFormContext } from "react-hook-form";

import {
  getPurchasseOrderAverageMargin,
  getTotalPurchaseOrderCost,
  updateOrderData,
} from "../../redux/editPurchaseOrder";

import { FOUNDRIES } from "../../constants/industry";
import { getDollarValue } from "../../helpers/currency";
import {
  HubHookFormSelect,
  HubHookFormInput,
  HubButton,
  HubHookFormSwitch,
} from "../../components";

import { SHIPPING } from "../../constants";
import { StyledHubCardStack } from "./styles";

const OrderDetails = ({
  data = {},
  isLoading = false,
  isUpdateLoading = false,
}) => {
  const { formState, watch } = useFormContext();
  const { isValid } = formState;

  const items = watch("lineItems") || [];
  const newItems = watch("newLineItems") || [];
  const allItems = [...items, ...newItems];

  const total = allItems
    ?.filter((i) => i?.quantity && i?.price)
    ?.reduce(
      (tol, curr) => tol + Number(curr.quantity) * Number(curr.price),
      0
    );
  const margin = allItems?.length
    ? allItems
        ?.filter((i) => i?.margin)
        ?.reduce((tol, curr) => tol + Number(curr.margin), 0) / allItems?.length
    : 0;

  return (
    <StyledHubCardStack>
      <Typography sx={{ fontWeight: 600 }}>Order Details</Typography>
      <HubHookFormInput
        name="company"
        label="Company"
        placeholder="ex. Winco"
        defaultValue={data?.customer.customer}
        disableHelperText
        disabled
        required
      />
      <Stack
        gap="10px"
        sx={{ flexDirection: { sm: "column", md: "row" } }}
        alignItems="center"
      >
        <HubHookFormInput
          name="poNumber"
          label="Customer PO#"
          placeholder="Order #"
          defaultValue={data?.poNumber}
          disableHelperText
          required
        />
        <HubHookFormSelect
          name="location"
          label="Foundry"
          defaultValue={data?.location}
          options={FOUNDRIES}
          disableHelperText
          required
        />
      </Stack>
      <HubHookFormSwitch
        name="overShipping"
        label="Over Shipping"
        defaultValue={data?.overShipping}
        disableHelperText
        isCheckbox
      />
      <Divider
        orientation="horizontal"
        flexItem
        sx={{ border: "1px dashed", borderColor: "hubColors.greyLight" }}
      />
      <Stack gap="15px">
        <Typography variant="body2">Shipping</Typography>
        <Stack gap="10px" sx={{ flexDirection: { sm: "column", md: "row" } }}>
          <HubHookFormInput
            name="freight"
            label="Freight"
            placeholder="X"
            defaultValue={data?.freight || "FOB Lindon"}
            disableHelperText
            required
          />
          <HubHookFormSelect
            name="shipVia"
            label="Ship Via"
            defaultValue={data?.shipVia}
            options={SHIPPING}
            disableHelperText
            required
          />
        </Stack>
        <HubHookFormSelect
          name="shipTo"
          label="Ship To"
          defaultValue={data?.shipTo}
          options={data?.addresses}
          disableHelperText
          required
        />
      </Stack>
      <Divider
        orientation="horizontal"
        flexItem
        sx={{ border: "1px dashed", borderColor: "hubColors.greyLight" }}
      />
      <Stack gap="15px">
        <Typography variant="body2">Subtotals</Typography>
        <Stack gap="5px">
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="caption">Average Margin</Typography>
            <Typography variant="boldCaption">{Math.round(margin * 10000) / 100} %</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="caption">Part Pricing</Typography>
            <Typography variant="boldCaption">
              {getDollarValue(total - total * margin)}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="caption">Margin</Typography>
            <Typography variant="boldCaption">
              {getDollarValue(total * margin)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Divider
        orientation="horizontal"
        flexItem
        sx={{ border: "1px dashed", borderColor: "hubColors.greyLight" }}
      />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography sx={{ fontWeight: 600, fontSize: 20 }}>Total</Typography>
        <Typography sx={{ fontWeight: 600, fontSize: 20 }}>
          {getDollarValue(total)}
        </Typography>
      </Stack>
      <HubButton
        variant="contained"
        size="small"
        label="Update Purchase Order"
        disabled={isLoading || isUpdateLoading || !isValid}
        type="submit"
      />
    </StyledHubCardStack>
  );
};

const mapStateToProps = (state) => ({
  total: getTotalPurchaseOrderCost(state),
  margin: getPurchasseOrderAverageMargin(state),
});

const mapDispatchToProps = {
  _updateOrderData: updateOrderData,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
