import React from "react";
import { Stack, Typography } from "@mui/material";

import {
  HubHookAddressInput,
  HubHookFormDatePicker,
  HubHookFormInput,
} from "../../components";

const CreateEmployeePersonal = () => {
  return (
    <Stack gap="15px" width="100%">
      <Typography variant="boldCaptionMain">Personal Details</Typography>
      <Stack gap="15px">
        <Stack gap="15px">
          <HubHookFormInput
            name="name"
            label="Full Name"
            placeholder="Full Name..."
            required
          />
          <HubHookFormInput
            name="email"
            label="Email"
            placeholder="christopher@ipcfoundry.com"
            required
          />
        </Stack>
        <Stack direction="row" gap="15px">
          <HubHookFormInput
            name="phone"
            label="Phone Number"
            placeholder="(800) 000-0000"
            required
          />
          <HubHookFormDatePicker
            name="dob"
            label="Date of Birth"
            placeholder="MM/DD/YYYY"
            required
          />
        </Stack>
        {/* <HubHookAddressInput label="Employee Address" required /> */}
      </Stack>
    </Stack>
  );
};

export default CreateEmployeePersonal;
