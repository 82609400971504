import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import { getUserLocation } from "../../redux";
import { useGetAllEmployeeData } from "../../hooks";
import { EmployeesTable } from "../../tables";
import { PageContent } from "../../components";
import { CreateEmployeeDialog } from "../../dialogs";

import EmployeesBar from "./EmployeesBar";
import EmployeeSheet from "./EmployeeSheet";

export const Employees = ({ defaultLocation = 1 }) => {
  const { search } = useLocation();
  const { isLoading, refetch } = useGetAllEmployeeData({ defaultLocation });

  useEffect(() => {
    refetch();
  }, [search?.toString()]);

  const props = { defaultLocation };

  return (
    <PageContent gap="10px" isLoading={isLoading} smallPadding hideOverflow>
      <EmployeesBar {...props} />
      <EmployeesTable {...props} />
      <EmployeeSheet {...props} />
      <CreateEmployeeDialog />
    </PageContent>
  );
};

const mapStateToProps = (state) => ({
  defaultLocation: getUserLocation(state),
});
export const EmployeesContainer = connect(mapStateToProps)(Employees);
export default EmployeesContainer;
