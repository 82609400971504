import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

export const StyledStack = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "50px 1fr 1fr 1fr 0.5fr 0.75fr 1fr 25px",
  alignItems: "center",
  borderBottom: "1px solid",
  borderRight: "1px solid",
  borderLeft: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
  ":hover": {
    backgroundColor: theme.palette.hubColors.greyLightest,
  },
  ".MuiInputBase-root, input": {
    borderRadius: 0,
    backgroundColor: "transparent !important",
  },
  fieldset: {
    borderWidth: 0.5,
  },
}));

export const StyledCellStack = styled(Stack)(({ theme }) => ({
  height: "100%",
  width: "100%",
  border: "0.5px solid",
  borderColor: theme.palette.hubColors.greyLight,
  alignItems: "center",
  justifyContent: "center",
  alignItems: "start",
  padding: "9px 15px",
  span: {
    fontSize: 11,
  },
}));