import { ROLES } from "./roles";

const hidden = true;
const protectedRoutes = [
  { label: "Models", path: "/models" },
  { label: "Customers", path: "/customers", hidden },
  { label: "Parts", path: "/parts", hidden },
  { label: "Orders", path: "/orders", hidden },
  { label: "Work Orders", path: "/works", hidden },
  // { label: "Line Items", path: "/lineitems", hidden },
  // { label: "Barcodes", path: "/barcodes", hidden },
].map((r, id) => ({ id, ...r }));
const unprotectedRoutes = [
  { label: "Displays", path: "/displays" },
  { label: "Labor", path: "/labor", hidden },
  { label: "Tickets", path: "/tickets" },
  { label: "Organization", path: "/organization", hidden },
].map((r, id) => ({ id: id + protectedRoutes.length, ...r }));

export const MENU_ITEMS = [...protectedRoutes, ...unprotectedRoutes];

export const getUserMenuItems = (role, includeHidden = true) => {
  const ITEMS = role === ROLES.Generic ? unprotectedRoutes : MENU_ITEMS;
  const FINAL = includeHidden ? ITEMS : ITEMS?.filter((i) => !i.hidden);
  return FINAL;
};
