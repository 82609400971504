import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => !["selected"].includes(prop),
})(({ theme, selected }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "10px",
  borderRadius: "5px",
  width: "100%",
  padding: "12px",

  "& .MuiRadio-root, div, svg": {
    padding: 0,
    height: 22,
    width: 22,
  },
  div: {
    transform: "none",
  },

  ...(selected
    ? { outline: `2px solid ${theme.palette.hubColors.mainFocus}` }
    : { outline: `1px solid ${theme.palette.hubColors.greyLight}` }),
}));

export const StyledBox = styled(Stack, {
  shouldForwardProp: (prop) => !["active", "m"].includes(prop),
})(({ active, m = -1 }) => ({
  position: "absolute",
  left: active ? "0" : `calc((100% + 100px) * ${m})`,
  transition: "left 0.6s cubic-bezier(0, 0, 0.2, 1) 0ms",
  width: "100%",
  boxSizing: "border-box",
  padding: "0px 2px"
}));
