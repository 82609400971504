import React from "react";
import { HubDialogWrapper } from "../HubDialogWrapper";
import CreateEmployeeContent from "./CreateEmployeeContent";

export const CreateEmployeeDialog = () => {
  return (
    <HubDialogWrapper maxWidth="sm" name="create-employee">
      <CreateEmployeeContent />
    </HubDialogWrapper>
  );
};

export default CreateEmployeeDialog;
