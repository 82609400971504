import React from 'react'
import { PageContent } from '../../components'

export const ShippingPage = () => {
  return (
    <PageContent>

    </PageContent>
  )
}

export default ShippingPage