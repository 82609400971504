import React, { useRef } from "react";
import { Stack, Typography } from "@mui/material";
import { StyledStack } from "./styles";

const DisplayTableContainer = ({ title, count, children, ...rest }) => {
  const divRef = useRef(null);

  return (
    <StyledStack
      ref={divRef}
      overflow="hidden"
      {...rest}
    >
      <Stack direction="row">
        <Stack direction="row" alignItems="center">
          <Typography
            variant="caption"
            sx={{ fontWeight: 800, color: "hubColors.mainFocus" }}
          >
            {count || 0}
          </Typography>
          <Typography variant="caption" padding="5px">
            {title}
          </Typography>
        </Stack>
        {/* <HubButton
          icon="Maximize"
          onClick={() => handleFullscreen(divRef)}
          variant="text"
          color="neutral"
          iconProps={{ size: 10 }}
        /> */}
      </Stack>
      {children}
    </StyledStack>
  );
};

export default DisplayTableContainer;
