import React from "react";
import { HubStepper } from "../../components";

const CreateEmployeeSteps = ({ value = 0 }) => {
  const steps = [
    { id: 1, label: "Hire Details" },
    { id: 2, label: "Personal Details" },
  ];
  return <HubStepper steps={steps} activeStep={value} />;
};

export default CreateEmployeeSteps;
