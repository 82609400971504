import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import dayjs from "dayjs";

import { HubDialogContent, HubHookFormWrapper } from "../../components";
import { EMPLOYEE_TYPES_DICT } from "../../constants/employees";
import { usePostCreateEmployee } from "../../hooks";
import { useDialogContext } from "../HubDialogWrapper";

import CreateEmployeeFormSteps from "./CreateEmployeeFormSteps";

const CreateEmployeeContent = () => {
  const {
    mutate: postCreateEmployee,
    isLoading,
    isSuccess,
  } = usePostCreateEmployee();

  const { onClose } = useDialogContext();

  useEffect(() => {
    isSuccess && onClose();
  }, [isSuccess]);

  const onSubmit = (formData) => {
    const payload = {
      name: formData.name,
      email: formData.email,
      dob: dayjs(formData.dob).toISOString(),
      doh: dayjs(formData.doh).toISOString(),
      phone: formData.phone,
      // address: formData.address,
      type: Number(formData.type || EMPLOYEE_TYPES_DICT.FULLTIME),
      locationId: Number(formData.locationId),
      departmentId: Number(formData.departmentId),
    };
    postCreateEmployee(payload);
  };

  return (
    <HubDialogContent largePadding sx={{ overflow: "hidden" }}>
      <HubHookFormWrapper onSubmit={onSubmit}>
        <Stack gap="35px" alignItems="center">
          <Stack alignItems="center">
            <Typography variant="h6">Create a New Employee</Typography>
            <Typography variant="caption">
              Use the fields below to add a new employee
            </Typography>
          </Stack>
          <CreateEmployeeFormSteps isLoading={isLoading} />
        </Stack>
      </HubHookFormWrapper>
    </HubDialogContent>
  );
};

export default CreateEmployeeContent;
