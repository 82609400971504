import React, { useRef } from "react";
import { connect } from "react-redux";

import { getUserLocation, getUserRole } from "../../redux";
import { useGetAllStationDisplays } from "../../hooks";

import { PageContent } from "../../components";
import DisplaysMenu from "./DisplaysMenu";
import DisplayPageContent from "./DisplayPageContent";
import { CreateDisplayDialog } from "../../dialogs";
import EditDisplayDialog from "../../dialogs/EditDisplayDialog/EditDisplayDialog";

import { StyledContentStack, StyledDisplayStack } from "./styles";
import { DisplaysProvider, useDisplaysContext } from "./DisplayContextProvider";
import DisplayDeleteConfirm from "./DisplayDeleteConfirm";
import { DisplayPageBar } from "../../containers";

const mapStateToProps = (state) => ({
  role: getUserRole(state),
  defaultLocation: getUserLocation(state),
});

const DisplaysPageContent = () => {
  const { defaultNav, locationId } = useDisplaysContext();
  const gridRef = useRef(null);

  const { isLoading } = useGetAllStationDisplays(locationId);

  const WithoutNav = (
    <>
      <StyledContentStack barHidden={!defaultNav}>
        <DisplaysMenu />
        <StyledDisplayStack barHidden={!defaultNav} ref={gridRef}>
          <DisplayPageContent gridRef={gridRef} />
        </StyledDisplayStack>
      </StyledContentStack>
      <CreateDisplayDialog />
    </>
  );

  const WithNav = (
    <>
      <DisplayPageBar />
      {WithoutNav}
      <EditDisplayDialog />
      <DisplayDeleteConfirm />
    </>
  );

  return (
    <PageContent isLoading={isLoading} disablePadding white id="display-page">
      {defaultNav ? WithNav : WithoutNav}
    </PageContent>
  );
};

export const DisplaysPage = connect(mapStateToProps)(
  ({ role, defaultLocation }) => (
    <DisplaysProvider role={role} defaultLocation={defaultLocation}>
      <DisplaysPageContent />
    </DisplaysProvider>
  )
);

export default DisplaysPage;
