import React from "react";
import { Stack, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { HubIcon } from "../../HubIcon";
import { HoverInfo, TooltipTemplates } from "./HoverInfo";

const LinkedButton = ({ children, page }) => {
  return page ? (
    <Link to={page} style={{ textDecoration: "none" }}>
      {children}
    </Link>
  ) : (
    children
  );
};

export const HubButton = ({
  loading = false,
  disabled = false,
  page = "",
  children,
  icon,
  iconProps,
  label,
  onClick = () => {},
  removePreventDefault = true,
  hoverContents,
  hoverTitle,
  hoverSubtitle,
  roleRestricted,
  ...rest
}) => {
  const handleoOnClick = (e) => {
    if (disabled) return;
    !removePreventDefault && e.preventDefault();
    !removePreventDefault && e.stopPropagation();
    onClick(e);
  };

  const buttonDisabled = roleRestricted || disabled || loading;

  const mainButton = (
    <Button disabled={buttonDisabled} onClick={handleoOnClick} {...rest}>
      {loading ? (
        <CircularProgress size="1em" sx={{ color: "hubColors.fontWhite" }} />
      ) : (
        children
      )}
      {!loading && (
        <Stack direction="row" gap="12px" alignItems="center">
          {icon && <HubIcon icon={icon} {...iconProps} />}
          {label && <Typography variant="span">{label}</Typography>}
        </Stack>
      )}
    </Button>
  );

  const template = roleRestricted ? "restricted" : "";

  return (
    <LinkedButton {...(disabled ? {} : { page })}>
      <HoverInfo
        hoverTitle={hoverTitle}
        hoverSubtitle={hoverSubtitle}
        hoverContents={hoverContents}
        template={TooltipTemplates[template]}
      >
        {mainButton}
      </HoverInfo>
    </LinkedButton>
  );
};

HubButton.propTypes = {
  loading: PropTypes.bool,
  page: PropTypes.string,
  children: PropTypes.node,
};

export default HubButton;
