import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { SideNavBar } from "../../components";
import { useGetAllStationDisplays } from "../../hooks";
import { useDisplaysContext } from "./DisplayContextProvider";

export const DisplaysMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { locationId } = useDisplaysContext();

  const { data } = useGetAllStationDisplays(locationId);

  const options = useMemo(
    () => data?.map((d) => ({ id: d.id, label: d.title })) || [],
    [data]
  );

  const query = new URLSearchParams(location.search);
  const property = query.get("display");

  const setValue = (id) => {
    if (!id) return;

    localStorage.setItem("Display-ID", id);
    query.set("display", id);

    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  useEffect(() => {
    if (!options.length) return; // Ensure options exist before setting value

    const defaultId =
      localStorage.getItem("Display-ID") || property || options[0]?.id;

    if (defaultId !== property) setValue(defaultId);
  }, [property, options]);

  const props = {
    tabs: options,
    value: Number(property) || options[0]?.id,
    setValue,
  };

  return <SideNavBar {...props} />;
};

export default DisplaysMenu;
