import React from "react";
import { TableBody } from "@mui/material";
import PartShippingRow from "./PartShippingRow";

const PartShippingBody = ({ fields }) => {
  return (
    <TableBody>
      {fields?.map((cs, idx) => (
        <PartShippingRow key={cs.id} details={cs} idx={idx} />
      ))}
    </TableBody>
  );
};

export default PartShippingBody;
