import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export const condition = ({
  isCurrent = false,
  plannedStartDate = "",
  startDate = "",
  plannedEndDate = "",
  endDate = "",
}) => {
  const startLogic =
    dayjs(plannedStartDate) <
    (startDate ? dayjs(startDate) : dayjs());

  const endLogic =
    dayjs(plannedEndDate) < (endDate ? dayjs(endDate) : dayjs());

  const color = "rgba(239, 55, 55,";
  const isActive = (startDate && !endDate) || isCurrent;
  const addRed = (startLogic && !endDate) || (startLogic && endLogic);

  return {
    sx: {
      "&.MuiTableRow-root": {
        padding: "0px",
        ">.MuiTypography-root": {
          // marginLeft: "13px",
        },
        ".MuiInputBase-root, input": {
          borderRadius: 0,
          backgroundColor: "transparent",
        },
        fieldset: {
          borderWidth: "0px",
        },
        backgroundColor: "hubColors.white",
        ...(isActive ? { backgroundColor: "hubColors.mainFocusLighter2" } : {}),
        ...(addRed
          ? {
              borderColor: `${color} 0.2) !important`,
              "& .MuiTableCell-root": {
                padding: "0px !important",
                backgroundColor: `${color} ${isActive ? "0.15" : "0.075"})`,
                borderColor: `${color} 0.2) !important`,
              },
            }
          : {
              "& .MuiTableCell-root": {
                padding: "0px",
              },
            }),
      },
    },
  };
};

export const items = [
  {
    id: "open",
    label: "",
  },
  {
    id: "complete",
    label: "",
  },
  {
    id: "station",
    label: "Station",
  },
  {
    id: "material",
    label: "Material",
  },
  {
    id: "completed",
    label: "Completed",
  },
  {
    id: "scrapped",
    label: "Scrapped",
  },
  {
    id: "plannedStartDate",
    label: "Planned Start",
  },
  {
    id: "plannedEndDate",
    label: "Planned End",
  },
  {
    id: "startDate",
    label: "Actual Start",
  },
  {
    id: "endDate",
    label: "Actual End",
  },
];

const getInputKey = (name) => ({
  inputProps: {
    name,
  },
});

export const keys = [
  {
    value: "isDone",
    defaultValue: false,
    isCheckbox: true,
    sx: {
      padding: "0px 4px !important",
    },
  },
  {
    value: "station.label",
    isInput: true,
    ...getInputKey("station"),
  },
  {
    value: "material.name",
    isInput: true,
    ...getInputKey("material"),
  },
  {
    value: "completed",
    defaultValue: 0,
    isInput: true,
    ...getInputKey("completed"),
  },
  {
    value: "scrapped",
    defaultValue: 0,
    isInput: true,
    ...getInputKey("scrapped"),
  },
  {
    value: "plannedStartDate",
    isDateInput: true,
    ...getInputKey("plannedStartDate"),
    // isDate: true,
  },
  {
    value: "plannedEndDate",
    isDateInput: true,
    ...getInputKey("plannedEndDate"),
    // isDate: true,
  },
  {
    value: "startDate",
    isDateInput: true,
    ...getInputKey("startDate"),
    // isDate: true,
  },
  {
    value: "endDate",
    isDateInput: true,
    ...getInputKey("endDate"),
    // isDate: true,
  },
];
