import React from "react";

import {
  Collapse,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import { StyledStack, StyledSubStack } from "./styles";
import CustomerOrderLineItemsTable from "./CustomerOrderLineItemsTable";

const CustomerOrdersJobs = ({ id = "", isOpen = false }) => {
  const sx = isOpen
    ? { ":hover": { backgroundColor: "transparent !important" } }
    : { border: "none" };

  return (
    <TableRow sx={sx}>
      <TableCell colSpan={9} sx={{ padding: "0px !important" }}>
        <Collapse in={isOpen}>
          <StyledSubStack>
            <StyledStack>
              <Typography variant="boldCaption" margin="5px">
                Line Items
              </Typography>
              {isOpen && <CustomerOrderLineItemsTable id={id} />}
            </StyledStack>
          </StyledSubStack>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default CustomerOrdersJobs;
